import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Dialog, DialogActions, DialogContent, DialogTitle} from 'views/components';
import {Button, IconButton, TextField, Grid} from 'views/components';

import {translate, Translate} from 'views/module';

import {ThumbUpAlt as ThumbUpAltIcon, ThumbDownAlt as ThumbDownAltIcon, Close as CloseIcon} from 'views/icons';

const FeedbackDialog = (props) => {
  const [likeService, setLikeService] = React.useState(null);
  const [recommendService, setRecommendService] = React.useState(null);
  const [feedbackText, setFeedbackText] = React.useState('');

  const open = props.open;
  const onClose = props.onClose;
  const giveFeedback = props.giveFeedback;

  const handleSubmit = () => {
    if (feedbackText) {
      giveFeedback('FEEDBACK_TEXT', `${feedbackText}`);
    }
    onClose(); // Close dialog upon submission
  };

  React.useEffect(() => {
    if (likeService !== null) {
      giveFeedback('LIKE_SERVICE', `${likeService}`);
    }
  }, [likeService]);
  React.useEffect(() => {
    if (recommendService !== null) {
      giveFeedback('RECOMMEND_SERVICE', `${recommendService}`);
    }
  }, [recommendService]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Translate expression='market.Feedback.Title' />
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{position: 'absolute', right: 8, top: 8}}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Translate expression='market.Feedback.HowDidYouLike' />
            <IconButton color={likeService === true ? 'primary' : 'default'} onClick={() => setLikeService(true)}><ThumbUpAltIcon /></IconButton>
            <IconButton color={likeService === false ? 'primary' : 'default'} onClick={() => setLikeService(false)}><ThumbDownAltIcon /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <Translate expression='market.Feedback.WouldYouRecommend' />
            <IconButton color={recommendService === true ? 'primary' : 'default'} onClick={() => setRecommendService(true)}><ThumbUpAltIcon /></IconButton>
            <IconButton color={recommendService === false ? 'primary' : 'default'} onClick={() => setRecommendService(false)}><ThumbDownAltIcon /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={translate('market.Feedback.GiveUsFeedback')}
              multiline
              fullWidth
              rows={4}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  giveFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, dispatch)(React.memo(FeedbackDialog));
