/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

const RedditPixel = (props) => {
  React.useEffect(() => {
    if (process.env.REACT_APP_REDDIT_PIXEL_ADS) {
      !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_erozbnatk02x');rdt('track', 'PageVisit');
    }
  }, []);

  return null;
};

RedditPixel.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(RedditPixel));
