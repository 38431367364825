import backend, {FEEDBACK_API} from 'utils/backend/server/api_library';

const giveFeedback = (subject, question, answer) => {
  const body = {
    subject, question, answer,
  };
  return backend.post(`${FEEDBACK_API}/questionnaire`, body);
};

export default {
  giveFeedback,
};
