import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {useNavigate} from 'views/module';

import {signOut} from 'utils/auth/sign_out';

const SignOut = (props) => {
  const user = props.user;
  const userSignout = props.userSignout;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.userRn) {
      signOut();
      userSignout();
      navigate('/');
    }
  }, [user]);

  return null;
};

SignOut.propTypes = {
  userSignout: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(SignOut));
