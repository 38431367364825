import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import ShowOtherMessage from './OtherMessage';
import ShowOwnMessage from './OwnMessage';

const ChatBubble = (props) => {
  const message = props.message;
  const user = props.user;

  const requesterRn = message.requesterRn;

  const isOwnMessage = requesterRn === user.userRn;

  if (isOwnMessage) {
    return (
      <ShowOwnMessage
        {...props}
      />
    );
  } else {
    return (
      <ShowOtherMessage
        {...props}
      />
    );
  }
};

ChatBubble.propTypes = {
  showTime: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatBubble));


