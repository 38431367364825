import React from 'react';
import PropTypes from 'prop-types';

import CheckboxMUI from '@mui/material/Checkbox';

const Switch = (props) => {
  const onChange = props.onChange;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <CheckboxMUI
      {...props}
      onChange={handleChange}
    />
  );
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Switch;
