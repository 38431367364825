import React from 'react';
// import PropTypes from 'prop-types';

import {Box, Stack} from 'views/components';
import {ButtonBase, Typography} from 'views/components';

import {AssignedMode, ContestMode, OneOnOneMode} from 'views/icons';

import {Translate} from 'views/module';

const Explain = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: '#F2FAFF',
        width: '100%',
        borderRadius: '16px',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          fontWeight: '600',
          textAlign: 'center',
          pt: '40px',
        }}
      >
        <Translate expression='home.Explain.Title' />
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          pt: '8px',
        }}
      >
        <Translate expression='home.Explain.Body' />
      </Typography>
      <Stack direction={{md: 'row', xs: 'column'}}
        sx={{
          pt: '40px',
          px: '32px',
        }}
        spacing={5}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            p: '40px',
          }}
        >
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <AssignedMode
              sx={{
                width: '40px',
                height: '40px',
              }}
            />
            <Typography
              variant={'h5'}
              sx={{
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              <Translate expression='home.Explain.ASSIGNED.Title' />
            </Typography>
          </Stack>
          <Typography
            sx={{
              pt: '16px',
            }}
          >
            <Translate expression='home.Explain.ASSIGNED.Body' />
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            p: '40px',
          }}
        >
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <OneOnOneMode
              sx={{
                width: '40px',
                height: '40px',
              }}
            />
            <Typography
              variant={'h5'}
              sx={{
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              <Translate expression='home.Explain.DIRECT_HIRE.Title' />
            </Typography>
          </Stack>
          <Typography
            sx={{
              pt: '16px',
            }}
          >
            <Translate expression='home.Explain.DIRECT_HIRE.Body' />
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            p: '40px',
          }}
        >
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <ContestMode
              sx={{
                width: '40px',
                height: '40px',
              }}
            />
            <Typography
              variant={'h5'}
              sx={{
                textAlign: 'center',
                fontWeight: 600,
              }}
            >
              <Translate expression='home.Explain.CONTEST.Title' />
            </Typography>
          </Stack>
          <Typography
            sx={{
              pt: '16px',
            }}
          >
            <Translate expression='home.Explain.CONTEST.Body' />
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          py: '60px',
          px: '32px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px dashed #000', // Customize the color and size as needed
            borderRadius: '4px', // Optional: for rounded corners
            borderColor: '#32A6FF',
            background: 'white',
          }}
        >
          <ButtonBase
            sx={{
              width: '100%',
              height: '100%',
            }}
            href={'/offer/new'}
          >
            <Typography
              sx={{
                fontWeight: '400',
                textAlign: 'center',
                color: '#0392FF',
              }}
            >
              <Translate expression='home.Explain.Action' />
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

Explain.propTypes = {
};

export default Explain;
