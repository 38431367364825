import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Box, Paper, Stack, ButtonBase} from 'views/components';

import {Translate} from 'views/module';

import {Edit} from 'views/icons';

import EditSubmit from './EditSubmit';

const Submit = (props) => {
  const goTo = props.goTo;

  const ComponentToRender = EditSubmit;

  return (<Paper
    sx={{
      p: {md: 2, xs: 1},
      borderRadius: '16px',
      width: '100%',
    }}
  >
    <Stack spacing={2}>
      <Box>
        <ButtonBase
          sx={{
            width: '100%',

            justifyContent: 'flex-start',
          }}
          onClick={goTo}
          disabled={!goTo}
        >
          <Stack
            direction={'row'}
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="h5"
              sx={{
                textAlign: 'left',
                fontWeight: 500,
              }}
            >
              <Translate expression="market.Order.Submit.Title" />
            </Typography>
            {
              goTo &&
              <Edit
              />
            }
          </Stack>
        </ButtonBase>
      </Box>
      <Box>
        <ComponentToRender {...props} />
      </Box>
    </Stack>
  </Paper>
  );
};

Submit.propTypes = {
  view: PropTypes.string.isRequired,
  goTo: PropTypes.func,
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Submit));
