import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import WaitingForContract from './no_open_contract/WaitingForContract';
import StartContract from './no_open_contract/StartContract';
import Correspondence from './correspondence/Correspondence';

const Feed = (props) => {
  const offerContracts = props.offerContracts;

  const artist = props.artist;
  if (offerContracts.length) {
    return (
      <Correspondence
        {...props}
        contract={offerContracts[0]}
      />
    );
  } else if (artist?.status === 'ACTIVE') {
    return (
      <StartContract
        {...props}
      />
    );
  } else {
    return (
      <WaitingForContract
        {...props}
      />
    );
  }
};
Feed.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  offerStatus: PropTypes.object.isRequired,
  offerContracts: PropTypes.array.isRequired,
  offerMessages: PropTypes.array.isRequired,
  artist: PropTypes.object,
};

const mapStateToProps = (state) => ({
  artist: state.artist,
});

export default connect(mapStateToProps, dispatch)(React.memo(Feed));
