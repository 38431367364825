import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Box, Paper, ButtonBase} from '@mui/material';

const LargeButtons = (props) => {
  const onClick = props.onClick;
  const title = props.title;
  const description = props.description;
  const selected = props.selected;
  const disabled = props.disabled;

  let backgroundColor = 'grey.500';
  if (disabled) {
    backgroundColor = 'grey.400';
  } else {
    if (selected) {
      backgroundColor ='primary.main';
    } else {
      backgroundColor ='primary.light';
    }
  }

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        height: '100%',
        width: '100%',
      }}
      disabled={disabled}
    >
      <Paper elevation={3}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: backgroundColor,
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 1,
            color: selected ? 'color.white' : 'color.black',
          }}
        >
          <Typography
            variant="h6"
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
          >
            {description}
          </Typography>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

LargeButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
export default LargeButtons;
