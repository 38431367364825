import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Typography} from 'views/components';

import {Translate} from 'views/module';

const DesignCategory = (props) => {
  const offerOrder = props.offerOrder;

  const category = offerOrder.category || {};
  const type = category.type;

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
        }}
      >
        <Translate expression={`market.Types.${type}.Title`}/>
      </Typography>
      <Typography>
        <Translate expression={`market.Types.${type}.Description`}/>
      </Typography>
    </Box>
  );
};

DesignCategory.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  updateOrderCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(DesignCategory));
