import React from 'react';
import PropTypes from 'prop-types';

import {Box, IconButton, List, ListItemButton} from '@mui/material';

import {MoreVert} from '@mui/icons-material';

import FilterMenuPopover from './FilterMenuPopover';

import {translate} from 'views/module';

const FilterSettings = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    onClearFilter,
    copyFilterLink,
    customButton,
  } = props;

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert style={{color: 'black'}} />
      </IconButton>
      {
        anchorEl &&
        <FilterMenuPopover
          anchorEl={anchorEl}
          anchorPositionTop={anchorEl.getBoundingClientRect().bottom + 12}
          anchorPositionLeft={anchorEl.getBoundingClientRect().left}
          onClose={() => setAnchorEl(null)}
          display={
            <Box sx={{pointerEvents: 'auto'}}>
              <List sx={{p: 0}}>
                <ListItemButton onClick={() => onClearFilter() & setAnchorEl(null)}>
                  {translate('generic.ClearAllFilters')}
                </ListItemButton>
                {
                  copyFilterLink &&
                  <ListItemButton onClick={() => copyFilterLink() & setAnchorEl(null)}>
                    {translate('generic.CopyFilterLink')}
                  </ListItemButton>
                }
                {
                  customButton &&
                  <ListItemButton onClick={() => customButton.action() & setAnchorEl(null)}>
                    {customButton.title}
                  </ListItemButton>
                }
              </List>
            </Box>
          }
        />
      }
    </>
  );
};

FilterSettings.propTypes = {
  onClearFilter: PropTypes.func.isRequired,
  copyFilterLink: PropTypes.func,
  customButton: PropTypes.object,
};

export default React.memo(FilterSettings);
