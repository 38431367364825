/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {SvgIcon} from '@mui/material';

const AssignedMode = (props) => {
  const propsSx = props.sx || {};

  return (
    <SvgIcon
      sx={{
        textAlign: 'center',
        verticalAlign: 'middle',
        ...propsSx,
      }}
      viewBox="0 0 28 28"
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path xmlns="http://www.w3.org/2000/svg" d="M 6.071 40 C 2.916 40 0.357 40 0.357 40 L 0.357 0 C 0.357 0 2.916 0 6.071 0 L 33.929 0 C 37.084 0 39.643 0 39.643 0 L 39.643 40 C 39.643 40 37.084 40 33.929 40 Z" fill="rgb(112, 90, 140)"/>
        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3m-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3m0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5m8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5" fill="rgb(255, 255, 255)" />
      </svg>
    </SvgIcon>
  );
};
AssignedMode.propTypes = {
  sx: PropTypes.object,
};

export default React.memo(AssignedMode);
