import React from 'react';
import PropTypes from 'prop-types';

import AccordionMUI from '@mui/material/Accordion';
import AccordionSummaryMUI from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {ArrowForwardIosSharp} from 'views/icons';

const Accordion = (props) => {
  return (
    <AccordionMUI
      disableGutters
      {...props}
    >
      {props.children}
    </AccordionMUI>
  );
};
Accordion.propTypes = {
  children: PropTypes.node.isRequired,
};

const AccordionSummary = (props) => {
  const defaultExpandIcon = <ArrowForwardIosSharp sx={{fontSize: '1rem'}}/>;
  const defaultSx = {
    'flexDirection': 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      ml: 1,
    },
    ...props.sx,
  };

  return (
    <AccordionSummaryMUI
      className={props.className}
      expandIcon={props.expandIcon || defaultExpandIcon}
      sx={defaultSx}
    >
      {props.children}
    </AccordionSummaryMUI>
  );
};
AccordionSummary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
  expandIcon: PropTypes.node,
  sx: PropTypes.object,
};

export {
  Accordion,
  AccordionSummary,
  AccordionDetails,
};
