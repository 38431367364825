import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Grid, TextField, Button, Link} from 'views/components';
import {Translate, translate} from 'views/module';

import {resetForgotPassword} from 'utils/auth/forgot_password';
import {login} from 'utils/auth/login';

const ThirdStage = (props) => {
  const [password, setPassword] = React.useState(''); ;
  const [rePassword, setRePassword] = React.useState('');

  const [isResetting, setIsResetting] = React.useState(false);

  const [numValid, setNumValid] = React.useState(0);

  const progressWrap = props.statusProgressWrap;
  const otp = props.otp;
  const email = props.email;

  React.useEffect(() => {
    const passwordValid = password.length >= 6 ? 1 : 0;
    const rePasswordValid = rePassword.length >= 6 ? 1 : 0;
    const passwordIsEqual = password === rePassword ? 1 : 0;
    setNumValid(passwordValid + rePasswordValid + passwordIsEqual);
  }, [password, rePassword]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (numValid !== 3) {
      return;
    }

    progressWrap(async () => {
      try {
        setIsResetting(true);
        await resetForgotPassword(email, otp, password);
        await login(email, password);
      } finally {
        setIsResetting(false);
      }
    }, {showProgress: false});
  };

  return (
    <Grid container
      justifyContent="center"
      sx={{
        pt: {xl: '124px', md: '200px', xs: '16px'},
        width: {lg: '640px'},
        px: {md: '8px', xs: '16px'},
        margin: 'auto',
      }}
    >
      <Grid item xs={12} >
        <Typography
          variant='h3'
          sx={{
            mb: '4px',
          }}
        >
          <Translate expression='auth.Signup.UserforgotPassword.ThirdStage.Title' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: {md: '24px', xs: '16px'}}}>
        <Typography
          variant='body1'
        >
          <Translate expression='auth.Signup.UserforgotPassword.ThirdStage.Body' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <hr style={{border: '1px solid #E5E5E5'}}/>
      </Grid>
      <form onSubmit={onSubmit} noValidate style={{width: '100%'}}>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <TextField
            type="password"
            value={password}
            onChange={setPassword}
            label={translate('auth.Signup.UserforgotPassword.ThirdStage.Password')}
            fullWidth
            InputLabelProps={{shrink: true}}
          />
        </Grid>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <TextField
            type="password"
            value={rePassword}
            onChange={setRePassword}
            label={translate('auth.Signup.UserforgotPassword.ThirdStage.RePassword')}
            fullWidth
            InputLabelProps={{shrink: true}}
          />
        </Grid>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <Button
            variant='contained'
            fullWidth
            color='secondary'
            loading={isResetting}
            sx={{
              'minHeight': '48px',
              '&:disabled': {
                backgroundColor: '#FB8488',
              },
            }}
            disabled={!(numValid === 3)}
            onClick={onSubmit}
            type="submit"
          >
            <Typography
              variant='h5'
              sx={{
                color: 'color.white',
                fontWeight: 500,
                my: '4px',
              }}
            >
              <Translate expression='auth.Signup.UserforgotPassword.ThirdStage.Action' />
            </Typography>
          </Button>
        </Grid>
      </form>
      <Grid item xs={12} sx={{pb: {md: '16px', xs: '16px'}}}>
        <Typography
          variant='body2'
          sx={{
            textAlign: 'center',
          }}
        >
          <Link href={'/auth'}>
            <Translate expression='auth.Signup.UserforgotPassword.GoBack.GoBack' />
          </Link>
          {' '}
          <Translate expression='auth.Signup.UserforgotPassword.GoBack.LoginScreen' />
        </Typography>
      </Grid>
    </Grid>
  );
};
ThirdStage.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  otp: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ThirdStage));
