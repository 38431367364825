import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Snackbar, Slide} from '@mui/material';
import Message from './MessageToast';

import {screenSizeSmaller} from 'views/components';

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" timeout={500}/>;
};

const Toaster = (props) => {
  const states = props.status?.states || {};
  const suppressMessages = Boolean(props.status.suppressMessages);
  const statusCloseMessage = props.statusCloseMessage;

  const smallScreen = screenSizeSmaller('md');

  if (suppressMessages) {
    return null;
  }

  const toastMessages = Object.values(states)
      .filter((e) => e.show)
      .filter((e) => e.status === 'SUCCESS' || e.status === 'FAILURE')
      .sort((v1, v2) => v2.time - v1.time);

  if (!toastMessages.length) {
    return null;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    for (const messgae of toastMessages) {
      statusCloseMessage(messgae.progressUuid);
    }
  };


  return (
    <Snackbar
      // autoHideDuration={10000}
      open={true}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: smallScreen ? 'center' : 'right'}}
      TransitionComponent={SlideTransition}
      message={
        <Message
          toastMessages={toastMessages}
          handleClose={handleClose}
        />
      }
      ContentProps={{
        sx: {
          'm': 0,
          'p': 0,
          'flex': {sm: 1, xs: 0},
          '.MuiSnackbarContent-message': {
            m: 0,
            p: 0,
          },
        },
      }}
    >
    </Snackbar>
  );
};
Toaster.propTypes = {
  status: PropTypes.object,
  statusCloseMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  status: state.status,
});

export default connect(mapStateToProps, dispatch)(React.memo(Toaster));
