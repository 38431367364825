import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, ImageMagnifier, Stack, IconButton} from 'views/components';

import {Edit, Delete} from 'views/icons';

import DeletePhoto from './DeletePhoto';
import EditPhoto from './EditPhoto';

const PhotoBox = (props) => {
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);

  const imageUrl = props.imageUrl;
  const fileName = props.fileName;
  const fileRn = props.fileRn;
  const deleteOrderFile = props.deleteOrderFile;
  const editOrderFile = props.editOrderFile;

  const onDelete = async () => {
    try {
      await deleteOrderFile(fileRn);
      setDeleteDialog(false);
    } catch (e) {
      console.error(e);
    }
  };
  const onEdit = async (newTitle) => {
    await editOrderFile(fileRn, newTitle);
    setEditDialog(false);
  };
  return (
    <Grid item xs={12}>
      {
        deleteDialog &&
        <DeletePhoto
          closeDialog={() => setDeleteDialog(false)}
          onAction={onDelete}
        />
      }
      {
        editDialog &&
        <EditPhoto
          fileName={fileName}
          closeDialog={() => setEditDialog(false)}
          onAction={onEdit}
        />
      }
      <Grid container
        sx={{
          mx: 1,
        }}
      >
        <Grid item lg={3} xs={12}>
          <ImageMagnifier
            src={imageUrl}
          />
        </Grid>
        <Grid item xs={7}>
          {fileName}
        </Grid>
        <Grid item xs={2}>
          <Stack direction={'row'} >
            <IconButton
              onClick={() => setEditDialog(true)}
              aria-label="delete"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => setDeleteDialog(true)}
              aria-label="delete"
            >
              <Delete />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

PhotoBox.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileRn: PropTypes.string.isRequired,
  deleteOrderFile: PropTypes.func.isRequired,
  editOrderFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(PhotoBox));
