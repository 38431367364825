import * as React from 'react';
// import PropTypes from 'prop-types';

import {Box, Button} from 'views/components';

import {Translate} from 'views/module';

const Authentication = (props) => {
  return (
    <Box sx={{display: 'flex', mx: 2}}>
      <Button
        sx={{
          color: 'white',
          display: 'block',
          mx: 0.5,
        }}
        variant='text'
        href={'/signup'}
      >
        <Translate expression={'navbar.Authentication.NewAccount'} />
      </Button>
      <Button
        sx={{
          color: 'white',
          display: 'block',
          mx: 0.5,
        }}
        variant='text'
        href={'/auth'}
      >
        <Translate expression={'navbar.Authentication.Login'} />
      </Button>
    </Box>
  );
};
Authentication.propTypes = {
};

export default React.memo(Authentication);
