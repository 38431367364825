import backend, {MARKET_API} from 'utils/backend/server/api_library';

const updateCategory = (offerRn, category) => {
  const body = {
    category,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/order/category`, body);
};
const updateDescription = (offerRn, description) => {
  const body = {
    description,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/order/description`, body);
};
const updateEngagementProcess = (offerRn, engagementProcess) => {
  const body = {
    engagementProcess,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/order/engagement_process`, body);
};
const updatePayment = (offerRn, payment) => {
  const body = {
    payment,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/order/payment`, body);
};
const addFileWithSignedUrl = (offerRn, fileName, fileType) => {
  const body = {
    fileName,
    fileType,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/order/file/add_file_with_signed_url`, body);
};

const editFile = (offerRn, fileRn, fileName) => {
  const body = {
    fileName,
  };
  return backend.put(`${MARKET_API}/offer/${offerRn}/order/file/${fileRn}`, body);
};

const deleteFile = (offerRn, fileRn) => {
  return backend.delete(`${MARKET_API}/offer/${offerRn}/order/file/${fileRn}`);
};

export default {
  updateCategory,
  updateDescription,
  updateEngagementProcess,
  updatePayment,
  files: {
    addFileWithSignedUrl,
    editFile,
    deleteFile,
  },
};
