import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Dialog, IconButton, DialogContent} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

function ImageMagnifier(props) {
  const {src, width, height} = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        style={{position: 'relative', width: width, height: height, cursor: 'pointer'}}
        onClick={handleOpen}
      >
        <img src={src} alt="Magnifiable" style={{width: '100%', height: '100%'}} />
        <IconButton
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // semi-transparent white background
            borderRadius: '50%',
          }}
        >
          <ZoomInIcon />
        </IconButton>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={src} alt="Magnified" style={{maxWidth: '100%', maxHeight: '100%'}} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

// Default props
ImageMagnifier.defaultProps = {
  width: 100,
  height: 100,
};

// PropTypes validation
ImageMagnifier.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ImageMagnifier;
