
import React from 'react';
import PropTypes from 'prop-types';

import {Tooltip} from '@mui/material';

function StickyTooltip(props) {
  const title = props.title;
  const children = props.children;

  return (
    <Tooltip
      arrow
      placement={props.placement || 'top'}
      enterTouchDelay={1}
      title={
        <div >
          {title}
        </div>
      }
    >
      {children}
    </Tooltip >
  );
}

StickyTooltip.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  disableHover: PropTypes.bool,
};

export {
  Tooltip, StickyTooltip,
};
