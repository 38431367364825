import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {RequireAuthentication} from 'views/module';
import {Helmet} from 'views/module';

import NewOfferScene from 'views/scenes/market/offer/new/NewOffer';

const OfferNew = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: New offer</title>
      </Helmet>
      <RequireAuthentication>
        <NewOfferScene
        />
      </RequireAuthentication>
    </>
  );
};

OfferNew.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(OfferNew));

