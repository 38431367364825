import React from 'react';

import {Typography, Link, Stack, Box, Container} from 'views/components';

import {Translate} from 'views/module';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.500',
        py: {md: '80px', xs: '32px'},
        px: {md: '80px', xs: '16px'},
      }}
    >
      <Container maxWidth="xl" >
        <Stack direction={{md: 'row', xs: 'column'}} justifyContent="space-between" spacing={{md: 4, xs: 2}}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Stack>
              <Box>
                <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                  <Box>
                    <Link href={'/privacy_policy/'} underline="none">
                      <Typography
                        variant='footer'
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'grey.700',
                        }}
                      >
                        <Translate expression='footer.Final.Policy' />
                      </Typography>
                    </Link>
                  </Box>
                  <Box>
                    <Link href={'/terms_and_conditions/'} underline="none">
                      <Typography
                        variant='footer'
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'grey.700',
                        }}
                      >
                        <Translate expression='footer.Final.Term' />
                      </Typography>
                    </Link>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Stack>
              <Box>
                <Typography
                  variant='footer'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'grey.700',
                  }}
                >
                  <Translate expression='footer.Final.Copyright' />
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
