import React from 'react';

import {useLocation} from 'react-router-dom';

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => {
    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search);

    // Convert the URLSearchParams object to a plain object
    const paramsObj = Object.fromEntries(searchParams.entries());

    return paramsObj;
  }, [search]);
}

export default useQuery;
