import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import ProgressBar from './progress_bar/ProgressBar';
import Toaster from './toast/Toaster';

const Status = (props) => {
  const isFirstRun = React.useRef(true);

  const statusClear = props.statusClear;
  const showProgressBar = props.showProgressBar;
  const location = props.location;

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    statusClear();
  }, [location]);

  return (
    <>
      {
        showProgressBar &&
        <ProgressBar />
      }
      <Toaster />
    </>
  );
};

Status.propTypes = {
  status: PropTypes.object,
  statusClear: PropTypes.func,
  statusCloseMessage: PropTypes.func,
  history: PropTypes.object,
  showProgressBar: PropTypes.bool,
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  status: state.status,
});

export default connect(mapStateToProps, dispatch)(React.memo(Status));
