import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, Typography, Stack} from 'views/components';

import {ImageHorizontalList} from 'views/components';

import {UserNameDisplay, TimeDisplay, TextLimit} from 'views/module';

const Body = (props) => {
  const offerOrder = props.offerOrder;

  const requester = offerOrder.requester;
  const createdAt = offerOrder.createdAt;

  const description = offerOrder.description?.blob || '';
  const referenceFiles = offerOrder.referenceFiles || {};

  return (
    <Grid container>
      <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
        <Stack
          spacing={{md: 1, xs: 0}}
          direction="row"
          justifyContent="center"
          alignItems="baseline"
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            <UserNameDisplay user={requester} />
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '12px',
              color: 'grey.600',
            }}
          >
            <TimeDisplay time={createdAt} />
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
        <TextLimit
          text={description}
          maxLength={500}
        />
      </Grid>
      <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', height: '160px'}}>
        <ImageHorizontalList
          images={Object.values(referenceFiles)}
          imageWidth='160px'
        />
      </Grid>
    </Grid>
  );
};
Body.propTypes = {
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Body));
