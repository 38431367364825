import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {getCurrentUser} from 'utils/auth/getSession';

const UserSettings = (props) => {
  const user = props.user;
  const userUpdate = props.userUpdate;

  React.useEffect(() => {
    const checkUser = async () => {
      if (!user?.userRn) {
        let currentUser = null;
        try {
          currentUser = await getCurrentUser();
        } catch (error) {
          console.error('Error fetching current user:', error);
        }
        userUpdate(currentUser);
      }
    };

    if (!user.lastLoadTime) {
      checkUser();
    }
  }, [user]);

  return null;
};

UserSettings.propTypes = {
  user: PropTypes.object,
  userUpdate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(UserSettings));
