import backend, {MARKET_API} from 'utils/backend/server/api_library';

const get = (userRn) => {
  return backend.get(`${MARKET_API}/artist/${userRn}`);
};

const register = (userRn) => {
  return backend.post(`${MARKET_API}/artist/${userRn}`);
};

export default {
  get,
  register,
};
