import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Grid, Stack, Divider, screenSizeSmaller} from 'views/components';

import {translate, RefreshWidget} from 'views/module';

import Filter from './scene/filter/Filter';
import Header from './scene/Header';
import FilterTabs from './scene/FilterTabs';
import List from './scene/list/List';

import constants from './constants';

const defaultFilter = () => {
  return {
    order: {orderBy: 'creationTime', orderDirc: 'DESC'},
    table: {
      value: constants.TableOptions.DRAFT,
      label: translate('market.Status.DRAFT'),
    },
  };
};

const MyRequest = (props) => {
  const [filter, setFilter] = React.useState(defaultFilter());

  const smallerScreen = screenSizeSmaller('md');

  const tabOptions = {
    [constants.TableOptions.DRAFT]: {
      value: constants.TableOptions.DRAFT,
      defaultStatus: constants.TableOptions.DRAFT,
      label: translate('market.Status.DRAFT'),
    },
    [constants.TableOptions.PUBLISHED]: {
      value: constants.TableOptions.PUBLISHED,
      defaultStatus: constants.TableOptions.PUBLISHED,
      label: translate('market.Status.PUBLISHED'),
    },
    [constants.TableOptions.COMPLETED]: {
      value: constants.TableOptions.COMPLETED,
      defaultStatus: constants.TableOptions.COMPLETED,
      label: translate('market.Status.COMPLETED'),
    },
  };

  const resetFilter = () => {
    setFilter(defaultFilter());
  };

  const updateFilterTable = (newTableValue) => {
    setFilter({
      ...defaultFilter(),
      filter: {
        status: [tabOptions[newTableValue].defaultStatus],
        forMe: null,
      },
      table: tabOptions[newTableValue],
    });
  };

  const updateFilter = (value) => {
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent='space-between' alignItems={{md: 'center'}}>
          <Grid item xs={12}>
            <Stack direction='row' spacing={2}>
              {
                !smallerScreen &&
                <>
                  <FilterTabs
                    tabValue={filter.table}
                    tabOptions={tabOptions}
                    onTabChange={updateFilterTable}
                  />
                  <Box>
                    <Divider orientation='vertical'/>
                  </Box>
                </>
              }
              <Stack spacing={2} width={1} pb={{xs: 0, md: 2}} pr={{xs: 0, md: 2}}>
                <Stack spacing={2} direction={{md: 'row', xs: 'column-reverse'}}>
                  <Filter
                    filter={filter}
                    updateFilter={updateFilter}
                    onClearFilter={resetFilter}
                    onTabChange={updateFilterTable}
                    smallerScreen={smallerScreen}
                  />
                  <RefreshWidget
                    numRefresh={props.reload}
                    setNumRefresh={props.setReload}
                    justifyContent={smallerScreen ? 'start' : 'end'}
                  />
                </Stack>
                <Stack>
                  <List
                    filter={filter}
                    reload={props.reload}
                    setReload={props.setReload}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
MyRequest.propTypes = {
  reload: PropTypes.number.isRequired,
  setReload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(MyRequest));
