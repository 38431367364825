import React from 'react';
import PropTypes from 'prop-types';

import {Dialog, DialogTitle, DialogContent, DialogActions} from 'views/components';
import {Button, Typography} from 'views/components';

import {Translate} from 'views/module';

const DeletePhoto = (props) => {
  const {
    closeDialog,
    onAction,
  } = props;

  return (
    <Dialog onClose={closeDialog}>
      <DialogTitle onClose={closeDialog}>
        <Translate expression='market.Order.ProjectDetail.UploadFiles.DeletePhoto.Title' />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Translate expression='market.Order.ProjectDetail.UploadFiles.DeletePhoto.Explain' />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={closeDialog}>
          <Translate expression='generic.Return' />
        </Button>
        <Button
          onClick={onAction}
          color='error'
        >
          <Translate expression='generic.Delete' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DeletePhoto.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default React.memo(DeletePhoto);
