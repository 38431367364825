import React from 'react';
import PropTypes from 'prop-types';
import {dispatch, connect} from 'utils/state_manager';
import api from 'utils/backend/';
import Bowser from 'bowser';

const MAX_QUEUE_SIZE = 10;
const TIME_TO_SEND = 5000; // in miliseconds
const BROWSER = Bowser.getParser(window.navigator.userAgent);

const StatusAnalyticsListener = (props) => {
  if (process.env.REACT_APP_METRICS === 'false') {
    return null;
  }

  // state to hold the queue of events
  const [queue, setQueue] = React.useState([]);
  // state to hold the timestamp of the last event added to the queue
  const [stateLastEvaluatedTime, setstateLastEvaluatedTime] = React.useState(Date.now());
  // state to hold the timestamp of the last time we pushed the queue
  const [lastPushTime, setLastPushTime] = React.useState(null);
  // state to hold timer ID to clear it when needed
  const [timer, setTimer] = React.useState(null);
  // Push messages
  const [push, setPush] = React.useState(0);

  const states = props.status.states || {};
  const user = props.user;

  const userRn = user?.userRn;

  React.useEffect(() => {
    // Only process if the user is logged in
    if (!userRn) {
      return;
    }

    setstateLastEvaluatedTime(Date.now());

    const newActions = Object.values(states).filter((e) => e.time > stateLastEvaluatedTime);
    const newActionsToPush = newActions.filter((e) => (e.status === 'SUCCESS' || e.status === 'FAILURE'));

    setQueue((e) => [...e, ...newActionsToPush]);
  }, [states, userRn]);

  React.useEffect(() => {
    if (queue.length) {
      if (!lastPushTime) {
        setLastPushTime(Date.now());
        return;
      }

      if (
        ((Date.now() - lastPushTime) > TIME_TO_SEND) ||
          (queue.length >= MAX_QUEUE_SIZE)
      ) { // check if the queue is the right size or if it's been 5 seconds since the last event
        setLastPushTime(null);
        const tempQueue = queue;
        setQueue([]);
        submitQueueToBackend(tempQueue)
            .catch((e) => {
              console.error(e);
            });
      } else { // Otherwise, we are resetting the timer to trigger again
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }

        setLastPushTime(Date.now());
        const timerId = setTimeout(() => {
          setPush((e) => e + 1);
        }, TIME_TO_SEND);
        setTimer(timerId);
      }
    }
  }, [queue, push]);

  const submitQueueToBackend = async (states) => {
    try {
      states = states.map((e) => {
        const obj = {
          status: e.status,
          startTime: e.startTime,
          uuid: e.uuid,
          debugString: e.debugString,
          currentPage: e.currentPage,
          endTime: e.endTime,
        };
        return obj;
      });

      const system = {
        browser: `${BROWSER.getBrowserName(true)} ${BROWSER.getBrowserVersion()}`,
        platform: BROWSER.getPlatformType(true),
        operatingSystem: `${BROWSER.getOSName(true)} ${BROWSER.getOSVersion()}`,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
      };

      const data = {
        system: system,
        states: states,
      };

      // call API
      await api.analytics.sendWebsiteAnalytics(data);
    } catch (e) {
      console.error(e);
    }
  };

  return null;
};

StatusAnalyticsListener.propTypes = {
  status: PropTypes.object,
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(StatusAnalyticsListener));
