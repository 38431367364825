import React from 'react';
import PropTypes from 'prop-types';

import {
  Chip, Menu, MenuItem, Stack, Typography, Box,
} from 'views/components';

import {translate, FilterMenuPopover, FilterOptions} from 'views/module';

import {FilterList, ChevronRight} from 'views/icons';

const FilterOption = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const updateFilter = props.updateFilter;
  const onTabChange = props.onTabChange;
  const filterOptionData = props.filterOptionData;

  const clearPopover = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleClick = (filterKey, item) => {
    if (filterKey === 'table') {
      onTabChange(item.value);
    } else {
      updateFilter(filterKey, item.value);
    }

    clearPopover();
  };

  const sortedFilter = Object.entries(filterOptionData).sort((a, b) => {
    // If both items have a rank, compare them by rank
    return a[1].rank - b[1].rank;
  });

  return (
    <>
      <Box sx={{p: 0.5}} >
        <Chip
          icon={<FilterList />}
          color="primary"
          label={`${translate('generic.AddFilter')}`}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />
      </Box>
      {
        anchorEl &&
        <Menu
          anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: anchorEl.getBoundingClientRect().bottom + 12,
            left: anchorEl.getBoundingClientRect().left,
          }}
          open={Boolean(anchorEl)}
          onClose={(e) => clearPopover()}
          PaperProps={{
            sx: {minWidth: '150px'},
          }}
        >
          {
            sortedFilter.map(([filterKey, filterData]) => {
              return (
                <MenuItem
                  key={filterKey}
                  sx={{pr: 1}}
                  onMouseOver={() => setOpen(filterKey)}
                >
                  <Stack direction={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Typography>
                      {filterData.title}
                    </Typography>
                    <ChevronRight sx={{color: 'grey.500'}}/>
                  </Stack>
                </MenuItem>
              );
            })
          }
        </Menu>
      }
      {
        open &&
        <FilterMenuPopover
          anchorEl={anchorEl}
          anchorPositionTop={anchorEl.getBoundingClientRect().bottom + 12}
          anchorPositionLeft={anchorEl.getBoundingClientRect().right + 55}
          data={filterOptionData[open].options}
          sx={{pointerEvents: 'none'}}
          display={
            <FilterOptions
              data={filterOptionData[open].options}
              onClick={handleClick}
              filterKey={open}
            />
          }
        />
      }
    </>
  );
};

FilterOption.propTypes = {
  updateFilter: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  filterOptionData: PropTypes.object.isRequired,
};


export default React.memo(FilterOption);
