import React from 'react';

import Home from 'views/scenes/home/Home';
import {Helmet} from 'views/module';

const App = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Home</title>
      </Helmet>
      <Home />
    </>
  );
};

export default App;
