import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import LoggedInNavbar from './logged_in/Navbar';
import LoggedOutNavbar from './logged_out/Navbar';

const NavBar = (props) => {
  const user = props.user;

  if (user?.userRn) {
    return (
      <LoggedInNavbar
      />
    );
  } else {
    return (
      <LoggedOutNavbar
      />
    );
  }
};


NavBar.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(NavBar));
