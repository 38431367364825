import React from 'react';

import {Typography, Paper} from 'views/components';

import {translate} from 'views/module';
import {Helmet} from 'views/module';

const PageNotFound = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Page not found</title>
      </Helmet>
      <Paper
        sx={{
          mt: {xs: '20px', sm: '40px'},
          mx: {xs: '16px', sm: 0},
          py: '24px',
          px: {xs: '16px', sm: 0},
        }}
        elevation={0}
      >
        <div style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Typography sx={{
            fontSize: '64px',
            fontWeight: 900,
            color: 'primary.main',
          }}>
            {translate('error.PageDoesNotExist')}
          </Typography>
          <br/>
          <Typography sx={{fontSize: '32px'}}>
            {translate('error.CouldNotFindPage')}
          </Typography>
          <br/>
          <Typography>
            <a href='/'>
              {translate('error.ReturnToHomePage')}
            </a>
          </Typography>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(PageNotFound);
