import Email from './Email';
import EmailCode from './EmailCode';
import Name from './Name';
import Password from './Password';
import Loggin from './Loggin';

const ACTIONS = [
  Email,
  EmailCode,
  Name,
  Password,
  Loggin,
];

export {ACTIONS};
