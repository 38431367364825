/* eslint-disable max-len */

export default {
  Title: `Join Our Creative Odyssey 🎨✨`,
  TitleSub: `Unleash Your Artistry, Ignite the World!`,
  Body: `
<p>Hey there, Creative Soul!</p>
<p>Are you ready to sketch, paint, and design your way into the hearts and homes across the globe? We're searching for talents that can turn a blank canvas into a portal to new worlds, one brushstroke at a time. If your creativity knows no bounds and you're itching to showcase your masterpieces, you've just found your new favorite playground.</p>
<h2>Why Join Us?</h2>
<ul>
    <li><strong>Showcase Galore:</strong> Your art deserves the spotlight. Get your own digital stage where fans can discover, love, and purchase your work. No more shouting into the void!</li>
    <li><strong>Freedom to Create:</strong> We don't box you in. Paint, sculpt, digitalize—whatever your medium, it's welcome here. Your imagination is the only limit.</li>
    <li><strong>Be Part of a Community:</strong> Join a tribe of artists who live to create and create to live. Swap stories, tips, and maybe even collaborate on the next big thing.</li>
    <li><strong>Earn Your Worth:</strong> Say goodbye to undervalued work. Set your prices and get paid for your passion. Because art isn't just beautiful; it's valuable.</li>
</ul>
  `,
  Action: `Sign up to be an artist`,
};
