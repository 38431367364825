export default {
  SomethingWrong: 'Something went wrong, try again',
  Close: 'Close',
  Submit: 'Submit',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Complete: 'Complete',
  Back: 'Back',
  Next: 'Next',
  Finish: 'Finish',
  Reset: 'Reset',
  Save: 'Save',
  Update: 'Update',
  Delete: 'Delete',
  Reactive: 'Reactive',
  SuccessfullySaved: 'Succesfully saved!',
  More: 'More',
  Edit: 'Edit',
  Add: 'Add',
  Name: 'Name',
  View: 'View',
  ViewEdit: 'View/Edit',
  Loading: 'Loading...',
  NoOption: 'No Options',
  Clear: 'Clear',
  NoResourceFound: 'No items found, consider expanding your search',
  True: 'True',
  False: 'False',
  NoDataFound: 'No data found',
  Return: 'Return',
  Continue: 'Continue',
  ClearSelection: 'Clear Selection',
  LoadMore: 'Load More',
  NoResult: 'No results found. Please adjust your filter criteria.',
  NoData: 'No data available',
  Refresh: 'Refresh',
  Filters: 'Filters',
  AddFilter: 'Filter',
  ClearAllFilters: 'Clear all filters',
  ShowMore: 'Show more',
  ShowLess: 'Show less',
  SignOut: 'Sign out',
};
