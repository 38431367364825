import React from 'react';

import {Typography, Grid, TopStepper} from 'views/components';
import {Translate, useQuery} from 'views/module';

const {ACTIONS} = require('./steps');

const OnboardSignup = () => {
  const [action, setAction] = React.useState(0);
  const [request, setRequest] = React.useState({user: {}});

  const query = useQuery();

  const email = query?.email;

  React.useEffect(() => {
    if (email) {
      setRequest((e) => {
        return {
          ...e,
          email: email,
        };
      });
      setAction(1);
    }
  }, []);


  const Action = ACTIONS[action];

  return (
    <Grid container
      justifyContent="center"
    >
      <Grid item xs={12} >
        <Typography
          variant='h3'
          sx={{
            mb: '4px',
          }}
        >
          <Translate expression='onboarding.Signup.GetStarted' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: {md: '24px', xs: '16px'}}}>
        <Typography
          variant='body1'
        >
          <Translate expression='onboarding.Signup.Register' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <hr style={{border: '1px solid #E5E5E5'}}/>
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <TopStepper
          steps={ACTIONS.length}
          activeStep={action}
        />
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <Action
          request={request}
          setRequest={setRequest}
          nextStep={() => setAction((e) => e + 1)}
        />
      </Grid>
    </Grid>
  );
};

export default OnboardSignup;
