import React from 'react';
// import PropTypes from 'prop-types';

import {Routes, Route} from 'react-router';

import Home from './home';

import Authentication from './auth/Authentication';
import ForgotPassword from './auth/ForgotPassword';
import SignUp from './auth/SignUp';
import SignOut from './auth/SignOut';
import OAuth from './auth/OAuth';

import ArtistSignup from './artist/ArtistSignup';

import MyRequest from './market/list/MyRequest';
import MyGig from './market/list/MyGig';
import OpenRequest from './market/list/OpenRequest';

import GuestOffer from './market/offer/GuestOffer';
import OfferNew from './market/offer/OfferNew';
import Offer from './market/offer/Offer';

import PrivacyPolicy from './policy/PrivacyPolicy';
import TermsAndConditions from './policy/TermsAndConditions';

import PageNotFound from './error/PageNotFound';

const Router = () => {
  return (
    <Routes>
      <Route path="/" Component={Home} />

      <Route path="/auth" Component={Authentication} />
      <Route path="/forgot_password" Component={ForgotPassword} />
      <Route path="/signup" Component={SignUp} />
      <Route path="/signout" Component={SignOut} />
      <Route path="/oauth" Component={OAuth} />

      <Route path="/artist/signup" Component={ArtistSignup} />

      <Route path="/offer/list/my_requests" Component={MyRequest} />
      <Route path="/offer/list/my_gigs" Component={MyGig} />
      <Route path="/offer/list/open_requests" Component={OpenRequest} />

      <Route path="/offer/new" Component={OfferNew} />
      <Route path="/offer/new/guest" Component={GuestOffer} />
      <Route path="/offer/:offerRn" Component={Offer} />

      <Route path="/privacy_policy" Component={PrivacyPolicy} />
      <Route path="/terms_and_conditions" Component={TermsAndConditions} />

      <Route path="*" Component={PageNotFound} />
    </Routes>
  );
};

Router.propTypes = {
};

export default React.memo(Router);

