import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Stack, TextField, Box, Button, Checkbox} from 'views/components';
import {Translate, translate} from 'views/module';

import Backend from 'utils/backend';

const Password = (props) => {
  const [password, setPassword] = React.useState(''); ;
  const [rePassword, setRePassword] = React.useState('');
  const [policyApproved, setPolicyApproved] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [numValid, setNumValid] = React.useState(0);

  const request = props.request;
  const setRequest = props.setRequest;
  const nextStep = props.nextStep;
  const progressWrap = props.statusProgressWrap;

  const email = request.email;
  const code = request.code;
  const user = request.user;

  React.useEffect(() => {
    const passwordValid = password.length >= 6 ? 1 : 0;
    const rePasswordValid = rePassword.length >= 6 ? 1 : 0;
    const passwordIsEqual = password === rePassword ? 1 : 0;
    setNumValid(passwordValid + rePasswordValid + passwordIsEqual + policyApproved);
  }, [password, rePassword, policyApproved]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (numValid !== 4) {
      return;
    }

    progressWrap(async () => {
      setLoading(true);
      try {
        setRequest((e) => {
          return {
            ...e,
            password,
          };
        });
        await Backend.identity.onboarding.registerComplete(email, code, user, password);
        nextStep();
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setLoading(false);
      }
    }, {showProgress: false});
  };

  return (
    <form onSubmit={onSubmit} noValidate style={{width: '100%'}}>
      <Stack spacing={2}>
        <TextField
          type="password"
          value={password}
          onChange={setPassword}
          label={translate('onboarding.Signup.Form.4.Password')}
          fullWidth
          InputLabelProps={{shrink: true}}
        />
        <TextField
          type="password"
          value={rePassword}
          onChange={setRePassword}
          label={translate('onboarding.Signup.Form.4.RePassword')}
          fullWidth
          InputLabelProps={{shrink: true}}
        />
        <Box>
          <Checkbox
            value={policyApproved}
            onChange={setPolicyApproved}
          />
          <Typography
            variant='body2'
            sx={{
              display: 'inline',
              ml: {md: '8x', xs: '0px'},
              verticalAlign: 'middle',
            }}
          >
            <Translate expression='onboarding.Signup.Form.4.Agreement' />
          </Typography>
        </Box>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          sx={{
            'minHeight': '48px',
            '&:disabled': {
              backgroundColor: '#FB8488',
            },
          }}
          disabled={!(numValid === 4)}
          onClick={onSubmit}
          type="submit"
          loading={loading}
        >
          <Typography
            variant='h5'
            sx={{
              color: 'color.white',
              fontWeight: 500,
              my: '4px',
            }}
          >
            <Translate expression='onboarding.Signup.Form.4.Action' />
          </Typography>
        </Button>
      </Stack>
    </form>
  );
};
Password.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  statusProgressWrap: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Password));
