import axios from 'axios';
import {getJwtToken} from 'utils/auth/getSession';

import NodeCache from 'node-cache';
const myCache = new NodeCache();

export class Backend {
  async get(path, cache = false) {
    let value = cache ? myCache.get(`${path}`) : undefined;
    if ( value == undefined ) {
      const output = await callBackEnd(path, 'GET', null);
      myCache.set(`${path}`, output, 300 );
      value = output;
    }
    return value;
  }

  post(path, body) {
    return callBackEnd(path, 'POST', body);
  }

  put(path, body) {
    return callBackEnd(path, 'PUT', body);
  }

  delete(path, body) {
    return callBackEnd(path, 'DELETE', body);
  }
}

export const IDENTITY_API = process.env.REACT_APP_IDENTITY_API;
export const MARKET_API = process.env.REACT_APP_MARKET_API;
export const ANALYTICS_API = process.env.REACT_APP_ANALYTICS_SERVER_API;
export const FEEDBACK_API = process.env.REACT_APP_FEEDBACK_SERVER_API;

export default new Backend();

const callBackEnd = async (url, requestMethods, body) => {
  try {
    const token = await getJwtToken();
    const result = await axios({
      method: requestMethods,
      url: url,
      data: body,
      headers: {'X-Auth': token},
    });
    return result.data;
  } catch (error) {
    if (error?.response?.data) {
      // Request made and server responded
      console.error(`Error calling ${url}. Code returned ${error.response.status} and message ${error.response.data.message}`);
      throw error.response.data;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(error.request, error.message);
    }
    throw new Error(`Error calling ${url}.`, error);
  }
};
