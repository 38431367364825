/* eslint-disable max-len */

export default {
  Feed: {
    NoOpenContract: {
      ASSIGNED: {
        Title: `Hang tight, Your artist is on the way!`,
        Body: `We're busy matchmaking you with the perfect artist. We will email you soon!`,
      },
    },
    AcceptContract: {
      ASSIGNED: {
        Title: '🚀 Ready to make some art and some waves? 🌊',
        Body: 'Claim This Gig',
      },
    },
    Correspondence: {
      Title: 'Correspondence',
      Message: {
        MessagePlaceholder: `Type a message...`,
        AttachedImages: 'Attached images',
      },
      ContractComplete: {
        Message: '#{user} has marked contract as complete',
        FinishContract: 'Finish the contract by marking it as complete',
        Review: 'Give us feedback',
      },
    },
  },
  Settings: {
    Contract: {
      Title: 'Contract',
      Artist: 'Artist',
      Status: {
        Title: 'Status',
        OPEN: 'Open',
        CLOSED: 'Closed',
        COMPLETED: 'Completed',
        Action: {
          Complete: 'Mark the contract as complete',
        },
      },
    },
    Drawer: {
      Title: 'Contract & Detail...',
    },
  },
};
