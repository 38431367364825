import React from 'react';
import PropTypes from 'prop-types';

import {Stack, Typography, Drawer, Button} from 'views/components';

import {Translate} from 'views/module';

import {ChevronRight} from 'views/icons';

function ContractSettingsDrawer(props) {
  const {
    settingsDrawerOpen,
    setSettingsDrawerOpen,
    children,
  } = props;

  return (
    <>
      <Stack direction={'row'} justifyContent='space-between' alignItems='center' >
        <Typography sx={{textTransform: 'uppercase', fontWeight: 500, color: 'black'}}>
          <Translate expression={'market.Contract.Settings.Drawer.Title'} />
        </Typography>
        <Button startIcon={<ChevronRight />} variant='outlined' onClick={() => setSettingsDrawerOpen(true)}>
          <Typography sx={{fontSize: '16px', textTransform: 'uppercase', fontWeight: 500, color: 'black'}}>
            <Translate expression={'generic.View'} />
          </Typography>
        </Button>
      </Stack>
      <Drawer
        open={settingsDrawerOpen}
        onOpen={() => setSettingsDrawerOpen(true)}
        onClose={() => setSettingsDrawerOpen(false)}
        PaperProps={{
          sx: {
            paddingX: 2,
            width: {
              xs: '80vw',
              sm: '456px',
            },
          },
        }}
      >
        <Typography sx={{textTransform: 'uppercase', fontWeight: 500, color: 'black'}} paddingTop='24px' paddingBottom='16px'>
          <Translate expression={'market.Contract.Settings.Drawer.Title'} />
        </Typography>
        {children}
      </Drawer>
    </>
  );
}
ContractSettingsDrawer.propTypes = {
  settingsDrawerOpen: PropTypes.bool.isRequired,
  setSettingsDrawerOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default React.memo(ContractSettingsDrawer);
