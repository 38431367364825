import {
  getUserPool,
} from 'utils/auth/cognito';

const getCurrentUser = () => {
  const userPool = getUserPool();
  const currentUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!currentUser) {
      resolve(null);
    } else {
      currentUser.getSession((err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          const idPayload = session.idToken.payload;
          const userInfo = {
            firstName: idPayload.given_name,
            lastName: idPayload.family_name,
            email: idPayload.email,
            userRn: idPayload['custom:userRn'],
          };
          resolve(userInfo);
        }
      });
    }
  });
};

const getJwtToken = () => {
  const userPool = getUserPool();
  const currentUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!currentUser) {
      resolve(null);
    } else {
      currentUser.getSession((err, session) => {
        if (err) {
          console.error(err);
          resolve(null);
        } else {
          if (session.isValid()) {
            const jwtToken = session?.idToken?.jwtToken;
            resolve(jwtToken);
          } else {
            resolve(null);
          }
        }
      });
    }
  });
};


export {
  getCurrentUser,
  getJwtToken,
};
