import React from 'react';
import PropTypes from 'prop-types';

import {SvgIcon} from '@mui/material';

import {ReactComponent as BlackLogo} from './black.svg';
import {ReactComponent as WhiteLogo} from './white.svg';

const logo = {
  'BLACK': BlackLogo,
  'WHITE': WhiteLogo,
};

const Logo = (props) => {
  const propsSx = props.sx || {};
  const variant = props.variant;

  const Logo = logo[variant] || logo['WHITE'];

  return (
    <SvgIcon
      sx={{
        textAlign: 'center',
        verticalAlign: 'middle',
        ...propsSx,
      }}
      viewBox="0 0 512 512"
    >
      <Logo />
    </SvgIcon>
  );
};
Logo.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['BLACK', 'WHITE']),
};

export default React.memo(Logo);
