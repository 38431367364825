import {
  getUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'utils/auth/cognito';

const onSuccess = (result, cognitoUser, resolve, error) => {
  // The user is successfully authenticated
  // You can access the user's tokens and attributes here
  const accessToken = result.getAccessToken().getJwtToken();
  const idToken = result.getIdToken().getJwtToken();
  const refreshToken = result.getRefreshToken().getToken();

  cognitoUser.getUserAttributes((err, attributes) => {
    if (err) {
      // Handle error
      console.error(err);
      error(err);
    } else {
      // Display user attributes
      const userAttributes = attributes.reduce((accumulator, attribute) => {
        accumulator[attribute.getName()] = attribute.getValue();
        return accumulator;
      }, {});

      resolve({
        status: 'SUCCESS',
        accessToken,
        idToken,
        refreshToken,
        userAttributes,
      });
    }
  });
};

const onFailure = (err, error) => {
  // The user failed to authenticate
  // Handle error
  console.error(err);
  error(err);
};


const login = async (username, password) => {
  const userPool = getUserPool();

  // Create an authentication data object
  const authenticationData = {
    Username: username,
    Password: password,
  };

  // Create an authentication details object
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  // Create a cognito user object
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, error) => {
    const newPasswordRequired = (userAttributes, requiredAttributes) => {
      resolve({
        status: 'NEW_PASSWORD_REQUIRED',
        userAttributes,
        requiredAttributes,
        userData,
        cognitoUser,
      });
    };

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => onSuccess(result, cognitoUser, resolve, error),
      newPasswordRequired,
      onFailure: (err) => onFailure(err, error),
    });
  });
};

const completeNewPassword = async (userData, newPassword) => {
  return new Promise((resolve, error) => {
    const cognitoUser = userData.cognitoUser;

    // Complete the new password challenge with the new password and the user attributes
    cognitoUser.completeNewPasswordChallenge(
        newPassword,
        userData.requiredAttributes,
        {
          onSuccess: (result) => onSuccess(result, cognitoUser, resolve, error),
          onFailure: (err) => onFailure(err, error),
        },
    );
  });
};

export {
  login,
  completeNewPassword,
};
