import backend, {MARKET_API} from 'utils/backend/server/api_library';

const sendMessage = (offerRn, contractRn, messageBody, requesterRn, fileUrls) => {
  const body = {
    body: messageBody,
    requesterRn,
    fileUrls,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/contract/${contractRn}/message`, body);
};
const addFile = (offerRn, contractRn, fileType) => {
  const body = {
    fileType,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/contract/${contractRn}/message/file`, body);
};

export default {
  sendMessage,
  addFile,
};
