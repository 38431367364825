import React from 'react';
import PropTypes from 'prop-types';

import {Chip, Divider, Stack} from '@mui/material';

import {ExpandMore} from '@mui/icons-material';

import FilterMenuPopover from './FilterMenuPopover';
import FilterOptions from './FilterOptions';

const FilterChip = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    label,
    filterKey,
    data,
    onClick,
    onDelete,
    isDeletable,
  } = props;

  const handleClick = (filterKey, item) => {
    onClick(filterKey, item);
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        label={
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {label.title}
            <ExpandMore />
            {
              !isDeletable &&
              <Divider orientation="vertical" variant="middle" flexItem sx={{pl: 1, my: 0.5}} />
            }
          </Stack>
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
        onDelete={isDeletable ? null : onDelete}
      />
      {
        anchorEl &&
        <FilterMenuPopover
          anchorEl={anchorEl}
          anchorPositionTop={anchorEl.getBoundingClientRect().bottom + 12}
          anchorPositionLeft={anchorEl.getBoundingClientRect().left}
          onClose={() => setAnchorEl(null)}
          display={<FilterOptions data={data} filterKey={filterKey} onClick={handleClick} />}
        />
      }
    </>
  );
};

FilterChip.propTypes = {
  label: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeletable: PropTypes.bool.isRequired,
};


export default React.memo(FilterChip);
