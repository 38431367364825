import React from 'react';
// import PropTypes from 'prop-types';

import {Box, Stack, Skeleton, Divider} from 'views/components';

const SkeletonComponent = () => {
  return (
    <Box height={'100%'}>
      <Stack direction={'row'} spacing={1} sx={{mb: 1}}>
        <Skeleton variant="circular" height={40} width={40}/>
        <Skeleton variant="text" height={40} width={'40%'}/>
      </Stack>
      <Divider />
      <Stack height={'350px'} sx={{py: 1}}>
        <Stack spacing={2} height={'100%'}>
          <Stack direction={'row'} spacing={4} height={'20%'}>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
          </Stack>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
        </Stack>
      </Stack>
      <Stack height={'350px'} sx={{py: 1}}>
        <Stack spacing={2} height={'100%'}>
          <Stack direction={'row'} spacing={4} height={'20%'}>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
            <Skeleton variant="text" height={'100%'} width={'25%'}/>
          </Stack>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
          <Skeleton variant="rounded" height={'20%'}/>
        </Stack>
      </Stack>
      <Skeleton variant="rounded" height={40} width={'100%%'}/>
    </Box>
  );
};
SkeletonComponent.propTypes = {
};
export default SkeletonComponent;
