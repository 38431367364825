import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Grid, TextField, Button, Link} from 'views/components';
import {Translate, translate} from 'views/module';

import {sendOTPPasswordForgot} from 'utils/auth/forgot_password';

const FirstStage = (props) => {
  const [isResetting, setIsResetting] = React.useState(false);

  const [numValid, setNumValid] = React.useState(0);

  const progressWrap = props.statusProgressWrap;
  const email = props.email;
  const setEmail = props.setEmail;
  const nextStage = props.nextStage;

  React.useEffect(() => {
    const usernameValid = email.length >= 3 ? 1 : 0;
    setNumValid(usernameValid );
  }, [email]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (numValid !== 1) {
      return;
    }

    progressWrap(async () => {
      try {
        setIsResetting(true);
        await sendOTPPasswordForgot(email);
        nextStage();
      } finally {
        setIsResetting(false);
      }
    }, {showProgress: false});
  };

  return (
    <Grid container
      justifyContent="center"
      sx={{
        pt: {xl: '124px', md: '200px', xs: '16px'},
        width: {lg: '640px'},
        px: {md: '8px', xs: '16px'},
        margin: 'auto',
      }}
    >
      <Grid item xs={12} >
        <Typography
          variant='h3'
          sx={{
            mb: '4px',
          }}
        >
          <Translate expression='auth.Signup.UserforgotPassword.FirstStage.Title' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: {md: '24px', xs: '16px'}}}>
        <Typography
          variant='body1'
        >
          <Translate expression='auth.Signup.UserforgotPassword.FirstStage.Body' />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <hr style={{border: '1px solid #E5E5E5'}}/>
      </Grid>
      <form onSubmit={onSubmit} noValidate style={{width: '100%'}}>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <TextField
            id={'email'}
            type={'email'}
            value={email}
            onChange={setEmail}
            label={translate('auth.Signup.UserforgotPassword.FirstStage.Email')}
            fullWidth
            InputLabelProps={{shrink: true}}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <Button
            variant='contained'
            fullWidth
            color='secondary'
            loading={isResetting}
            sx={{
              'minHeight': '48px',
              '&:disabled': {
                backgroundColor: '#FB8488',
              },
            }}
            disabled={!(numValid === 1)}
            onClick={onSubmit}
            type="submit"
          >
            <Typography
              variant='h5'
              sx={{
                color: 'color.white',
                fontWeight: 500,
                my: '4px',
              }}
            >
              <Translate expression='auth.Signup.UserforgotPassword.FirstStage.Action' />
            </Typography>
          </Button>
        </Grid>
      </form>
      <Grid item xs={12} sx={{pb: {md: '16px', xs: '16px'}}}>
        <Typography
          variant='body2'
          sx={{
            textAlign: 'center',
          }}
        >
          <Link href={'/auth'}>
            <Translate expression='auth.Signup.UserforgotPassword.GoBack.GoBack' />
          </Link>
          {' '}
          <Translate expression='auth.Signup.UserforgotPassword.GoBack.LoginScreen' />
        </Typography>
      </Grid>
    </Grid>
  );
};
FirstStage.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(FirstStage));
