import _ from 'lodash';
import {combineReducers} from 'redux';
import {connect, Provider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import artist from './actions/artist';
import environment from './actions/environment';
import status from './actions/status';
import user from './actions/user';

const actions = {
  artist,
  environment,
  status,
  user,
};

const dispatch = {};
const reducerList = {};
const initialState = {};

for (const [groupKey, groupValue] of Object.entries(actions)) {
  reducerList[groupKey] = groupValue.reducer;
  for (const [actionKey, actionValue] of Object.entries(groupValue.actions)) {
    const key = _.camelCase(`${groupKey} ${actionKey}`);
    dispatch[key] = actionValue;
  }
  if (groupValue.defaultState) {
    initialState[groupKey] = groupValue.defaultState;
  }
}

const reducer = combineReducers(reducerList);

// Configure the store
const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production', // Automatically sets up Redux DevTools
});

export {dispatch, reducer, connect, store, Provider};
