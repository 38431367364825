import React from 'react';
import PropTypes from 'prop-types';

const TimeDisplay = (props) => {
  const time = props.time;

  let displayTitle = null;
  if (time) {
    displayTitle = time;
  }

  return displayTitle;
};
TimeDisplay.propTypes = {
  time: PropTypes.string.isRequired,
};

export default React.memo(TimeDisplay);
