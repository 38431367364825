import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, Box, Typography, Button} from 'views/components';

import {Translate, Avatar, UserNameDisplay} from 'views/module';

function Contract(props) {
  const offerContract = props.offerContract;
  const completeContract = props.completeContract;
  const user = props.user;

  const contractRn = offerContract.contractRn;
  const contractUser = offerContract.user;
  const status = offerContract.status;
  const contractCompletionStatus = offerContract.contractCompletionStatus;

  const onCompleteTheContract = () => {
    completeContract(contractRn, user.userRn);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        my: 1,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          <Translate expression='market.Contract.Settings.Contract.Artist' />
        </Typography>
        <Stack direction={'row'} >
          <Avatar
            detail={contractUser}
            userRn={contractUser.userRn}
            sx={{width: 24, height: 24, fontSize: 12, mr: 1}}
            iconsx={{fontSize: '20px'}}
          />
          <UserNameDisplay user={contractUser} />
        </Stack>
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          <Translate expression='market.Contract.Settings.Contract.Status.Title' />
        </Typography>
        <Typography>
          <Translate expression={`market.Contract.Settings.Contract.Status.${status}`} />
        </Typography>
        {
          (user.userRn === contractUser.userRn && !contractCompletionStatus?.length) &&
          <Button
            variant='outlined'
            onClick={onCompleteTheContract}
            sx={{
              mt: 1,
            }}
          >
            <Translate expression='market.Contract.Settings.Contract.Status.Action.Complete' />
          </Button>
        }
      </Box>
    </Stack>
  );
}
Contract.propTypes = {
  offerContract: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  completeContract: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(Contract));

