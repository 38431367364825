export default {
  RESTORATION: {
    Key: 'RESTORATION',
    Title: 'Restoration and preservation',
    Description: 'Repairing damaged, old photos to preserve family history.',
  },
  ENHANCEMENT: {
    Key: 'ENHANCEMENT',
    Title: 'Appearance enhancement',
    Description: 'Adjusting for a more desired appearance.',
  },
  SPECIAL_MOMENTS: {
    Key: 'SPECIAL_MOMENTS',
    Title: 'Special moments',
    Description: 'Enhancing photos to capture the beauty of these significant events.',
  },
  BUSINESS: {
    Key: 'BUSINESS',
    Title: 'Business needs',
    Description: 'Improving the appearance of products for e-commerce or catalogs.',
  },
  PROFESSIONAL: {
    Key: 'PROFESSIONAL',
    Title: 'Professional needs',
    Description: 'Enhancing headshots for professional profiles, LinkedIn, company websites.',
  },
  CREATIVE: {
    Key: 'CREATIVE',
    Title: 'Creative expression',
    Description: 'For artists and hobbyists who want to create unique, imaginative works.',
  },
  OTHER: {
    Key: 'OTHER',
    Title: `Custom work`,
    Description: `Ideal for one-of-a-kind projects where a highly customized approach is required.`,
  },
};
