import {useNavigate} from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();

  const customNavigate = (to, options) => {
    navigate(to, options);
  };

  return customNavigate;
};

export default useCustomNavigate;
