import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {TextLimit} from 'views/module';

const ChatText = (props) => {
  const message = props.message;

  const text = message.body.blob;

  return (
    <TextLimit
      text={text}
      maxLength={500}
    />
  );
};

ChatText.propTypes = {
  message: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatText));


