import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Helmet} from 'views/module';

import GuestOffer from 'views/scenes/market/guest_offer/GuestOffer';

const OfferNew = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: New offer (Guest)</title>
      </Helmet>
      <GuestOffer
      />
    </>
  );
};

OfferNew.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(OfferNew));

