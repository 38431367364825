import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Helmet} from 'views/module';

import {Box, CircularProgress} from 'views/components';
import {Translate, useNavigate} from 'views/module';

const OAuth = (props) => {
  const user = props.user;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.userRn) {
      navigate('/');
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>StudioGrams: OAuth</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress sx={{mx: 1}}/>
        <Translate expression='auth.OAuth.Title'/>
      </Box>
    </>
  );
};

OAuth.propTypes = {
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(OAuth));
