import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, Typography, Box} from 'views/components';

import {Translate} from 'views/module';

import DisplayPhoto from './photo/DisplayPhoto';

const ProjectDetail = (props) => {
  const offerOrder = props.offerOrder;

  const description = offerOrder.description?.blob || '';
  const referenceFiles = offerOrder.referenceFiles || {};

  return (
    <Stack
      spacing={2}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
          }}
        >
          <Translate expression={`market.Order.ProjectDetail.Describe`} />
        </Typography>
        <Typography>
          {description}
        </Typography>
        <DisplayPhoto
          referenceFiles={referenceFiles}
        />
      </Box>
    </Stack>
  );
};

ProjectDetail.propTypes = {
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ProjectDetail));
