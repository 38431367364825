import React from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

const screenSizeBigger = (size) => {
  return useMediaQuery(useTheme().breakpoints.up(size), {noSsr: true});
};

const screenSizeSmaller = (size) => {
  return useMediaQuery(useTheme().breakpoints.down(size), {noSsr: true});
};


const getPageHeight = () => {
  const [pageHeight, setPageHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => setPageHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return pageHeight;
};

export {screenSizeBigger, screenSizeSmaller, getPageHeight};
