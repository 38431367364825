import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Box, Paper, Stack, ButtonBase} from 'views/components';

import {Translate} from 'views/module';

import {Edit} from 'views/icons';

import EditProjectDetail from './EditProjectDetail';
import ViewProjectDetail from './ViewProjectDetail';

const ProjectDetail = (props) => {
  const view = props.view;
  const goTo = props.goTo;
  const offerOrder = props.offerOrder;

  let ComponentToRender = (() => <React.Fragment/>);
  if (view === 'EDIT') {
    ComponentToRender = EditProjectDetail;
  } else if (view === 'VIEW') {
    const description = offerOrder.description?.blob;
    const fileSize = Object.keys(offerOrder.referenceFiles || {}).length;
    if (description || fileSize) {
      ComponentToRender = ViewProjectDetail;
    }
  }

  return (
    <Paper
      sx={{
        p: {md: 2, xs: 1},
        borderRadius: '16px',
        width: '100%',
      }}
    >
      <Stack spacing={2}>
        <Box>
          <ButtonBase
            sx={{
              width: '100%',

              justifyContent: 'flex-start',
            }}
            onClick={goTo}
            disabled={!goTo}
          >
            <Stack
              direction={'row'}
              justifyContent="space-between"
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="h5"
                sx={{
                  textAlign: 'left',
                  fontWeight: 500,
                }}
              >
                <Translate expression="market.Order.ProjectDetail.Title" />
              </Typography>
              {
                goTo &&
                <Edit
                />
              }
            </Stack>
          </ButtonBase>
        </Box>
        <Box>
          <ComponentToRender {...props} />
        </Box>
      </Stack>
    </Paper>
  );
};

ProjectDetail.propTypes = {
  view: PropTypes.string.isRequired,
  goTo: PropTypes.func,
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ProjectDetail));
