import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, TextField, Button} from 'views/components';

import {Translate, translate} from 'views/module';

import UploadPhotos from './photo/UploadPhotos';

const ProjectDetail = (props) => {
  const [localDescription, setLocalDescription] = React.useState('');

  const offerOrder = props.offerOrder;
  const updateOrderDescription = props.updateOrderDescription;
  const addOrderFileWithSignedUrl = props.addOrderFileWithSignedUrl;
  const editOrderFile = props.editOrderFile;
  const deleteOrderFile = props.deleteOrderFile;
  const nextStep = props.nextStep;

  const referenceFiles = offerOrder.referenceFiles;
  const description = offerOrder.description;

  React.useEffect(() => {
    setLocalDescription(description?.blob || '');
  }, [description]);

  const updateDescription = (newDescription) => {
    if (newDescription !== description) {
      updateOrderDescription(
          {
            blob: localDescription,
            editorType: 'TEXT',
          },
      );
    }
  };

  const next = () => {
    nextStep();
  };

  return (
    <Stack
      spacing={2}
    >
      <TextField
        label={translate(`market.Order.ProjectDetail.Describe`)}
        fullWidth
        onChange={setLocalDescription}
        onBlur={() => updateDescription(localDescription)}
        value={localDescription}
        multiline
        sx={{
          backgroundColor: 'color.white',
        }}
        InputProps={{
          style: {resize: 'vertical'},
        }}
      />
      <UploadPhotos
        referenceFiles={referenceFiles}
        addOrderFileWithSignedUrl={addOrderFileWithSignedUrl}
        editOrderFile={editOrderFile}
        deleteOrderFile={deleteOrderFile}
      />
      <Button
        onClick={next}
      >
        <Translate expression='generic.Continue' />
      </Button>
    </Stack>
  );
};

ProjectDetail.propTypes = {
  offerOrder: PropTypes.object,
  updateOrderDescription: PropTypes.func.isRequired,
  referenceFiles: PropTypes.object,
  addOrderFileWithSignedUrl: PropTypes.func.isRequired,
  editOrderFile: PropTypes.func.isRequired,
  deleteOrderFile: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ProjectDetail));
