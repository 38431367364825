import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import FirstStage from './UserPasswordReset1st';
import SecondStage from './UserPasswordReset2nd';
import ThirdStage from './UserPasswordReset3rd';

const UserForgotPassword = (props) => {
  const [stage, setStage] = React.useState(1);
  const [email, setEmail] = React.useState('');
  const [otp, setOtp] = React.useState('');


  if (stage === 1) {
    return (
      <FirstStage
        email={email}
        setEmail={setEmail}
        nextStage={() => setStage(2)}
      />
    );
  } else if (stage === 2) {
    return (
      <SecondStage
        otp={otp}
        setOtp={setOtp}
        nextStage={() => setStage(3)}
      />
    );
  } else if (stage === 3) {
    return (
      <ThirdStage
        email={email}
        otp={otp}
      />
    );
  } else {
    return null;
  }
};

UserForgotPassword.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(UserForgotPassword));
