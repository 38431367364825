/* eslint-disable max-len */

export default {
  Title: `Welcome to the First Step of Your Creative Journey! 🌟`,
  TitleSub: `Your Artistic Adventure Awaits!`,
  Body: `
<p>Hello, Visionary Artist,</p>
<p>We've received your submission and are thrilled to see that you're ready to share your creativity with the world. Your journey with us begins with a spark of your artistry, and we're eager to fan that spark into a flame.</p>
<p><b>What Happens Next?</b> Our team is now reviewing your submission with the same care and attention to detail that you put into your art. We're looking at every stroke, shade, and shape to ensure that our platform is the perfect stage for your masterpieces. Expect to hear from us soon!</p>
<p>Warmest regards, The PaintbruchMagic Team</p>
`,
};
