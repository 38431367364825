/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {SvgIcon} from '@mui/material';

const AssignedMode = (props) => {
  const propsSx = props.sx || {};

  return (
    <SvgIcon
      sx={{
        textAlign: 'center',
        verticalAlign: 'middle',
        ...propsSx,
      }}
      viewBox="0 0 40 40"
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path d="M 6.154 40 C 2.755 40 0 40 0 40 L 0 0 C 0 0 2.755 0 6.154 0 L 33.846 0 C 37.245 0 40 0 40 0 L 40 40 C 40 40 37.245 40 33.846 40 Z" fill="rgb(112, 90, 140)"/>
        <path d="M 27.692 11.852 C 26.843 11.852 26.154 11.189 26.154 10.37 C 26.154 9.552 26.843 8.889 27.692 8.889 C 28.542 8.889 29.231 9.552 29.231 10.37 C 29.231 11.189 28.542 11.852 27.692 11.852 Z M 27.692 14.815 C 30.241 14.815 32.308 12.825 32.308 10.37 C 32.308 7.916 30.241 5.926 27.692 5.926 C 25.143 5.926 23.077 7.916 23.077 10.37 C 23.077 12.825 25.143 14.815 27.692 14.815 Z M 12.308 7.407 C 8.909 7.407 6.154 10.061 6.154 13.333 L 6.154 16.296 L 9.231 16.296 L 9.231 13.333 C 9.231 11.697 10.608 10.37 12.308 10.37 L 16.923 10.37 L 16.923 7.407 Z M 27.692 34.074 C 31.091 34.074 33.846 31.421 33.846 28.148 L 33.846 25.185 L 30.769 25.185 L 30.769 28.148 C 30.769 29.785 29.392 31.111 27.692 31.111 L 23.077 31.111 L 23.077 34.074 Z M 13.846 22.222 C 13.846 21.404 13.157 20.741 12.308 20.741 C 11.458 20.741 10.769 21.404 10.769 22.222 C 10.769 23.04 11.458 23.704 12.308 23.704 C 13.157 23.704 13.846 23.04 13.846 22.222 Z M 16.923 22.222 C 16.923 24.677 14.857 26.667 12.308 26.667 C 9.759 26.667 7.692 24.677 7.692 22.222 C 7.692 19.768 9.759 17.778 12.308 17.778 C 14.857 17.778 16.923 19.768 16.923 22.222 Z M 27.692 19.259 C 25.993 19.259 24.615 20.586 24.615 22.222 L 21.538 22.222 C 21.538 18.949 24.294 16.296 27.692 16.296 C 31.091 16.296 33.846 18.949 33.846 22.222 L 30.769 22.222 C 30.769 20.586 29.392 19.259 27.692 19.259 Z M 9.231 34.074 C 9.231 32.438 10.608 31.111 12.308 31.111 C 14.007 31.111 15.385 32.438 15.385 34.074 L 18.462 34.074 C 18.462 30.801 15.706 28.148 12.308 28.148 C 8.909 28.148 6.154 30.801 6.154 34.074 Z" fill="rgb(255, 255, 255)"/>
      </svg>
    </SvgIcon>
  );
};
AssignedMode.propTypes = {
  sx: PropTypes.object,
};

export default React.memo(AssignedMode);
