import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, Divider, Box, screenSizeSmaller, Paper} from 'views/components';

import {useWindowFocus} from 'views/module';

import Header from './header/Header';
import Body from './header/Body';
import Feed from './feed/Feed';

import ContractSettings from './settings/ContractSettings';
import ContractSettingsDrawer from './settings/ContractSettingsDrawer';

import Feedback from './feedback/Feedback';

const OfferContract = (props) => {
  const [openSettingsDrawer, setOpenSettingsDrawer] = React.useState(false);
  const [settingsPanel, setSettingsPanel] = React.useState('CONTRACT');
  const [showFeedback, setShowFeedback] = React.useState(false);

  const showSettingsDrawer = screenSizeSmaller('md');

  const messageManager = props.messageManager;

  const isFocused = useWindowFocus();

  React.useEffect(() => {
    if (isFocused) {
      messageManager.refresh();
    }
  }, [isFocused]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      messageManager.refresh();
    }, isFocused ? 2000 : 60000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [isFocused]); // Empty dependency array means this effect runs once on mount


  const settings = () => {
    return (
      <ContractSettings
        {...props}
        panel={settingsPanel}
        setPanel={setSettingsPanel}
      />
    );
  };

  return (
    <Grid container spacing={1}>
      {
        showFeedback&&
        <Feedback
          {...props}
          open={showFeedback}
          onClose={() => setShowFeedback(false)}
        />
      }
      <Grid item md={9.5} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 1,
              }}
            >
              <Header
                {...props}
              />
              <Divider/>
              <Box
                sx={{
                  maxHeight: {md: '240px', xs: '120px'},
                  overflowX: 'auto',
                }}
              >
                <Body
                  {...props}
                />
              </Box>
            </Paper>
          </Grid>
          {
            showSettingsDrawer &&
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 1,
                }}
              >
                <ContractSettingsDrawer
                  settingsDrawerOpen={openSettingsDrawer}
                  setSettingsDrawerOpen={setOpenSettingsDrawer}
                >
                  {settings()}
                </ContractSettingsDrawer>
              </Paper>
            </Grid>
          }
          <Grid item xs={12} >
            <Divider/>
          </Grid>
          <Grid item xs={12} >
            <Paper
              sx={{
                p: 1,
              }}
            >
              <Feed
                {...props}
                showFeedbackForm={() => setShowFeedback(true)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {
        !showSettingsDrawer &&
        <Grid item md={2.5} >
          {settings()}
        </Grid>
      }
    </Grid>
  );
};
OfferContract.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  offerStatus: PropTypes.object.isRequired,
  offerContracts: PropTypes.array.isRequired,
  offerMessages: PropTypes.array.isRequired,
  messageManager: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(OfferContract));
