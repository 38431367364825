import React from 'react';
import PropTypes from 'prop-types';

import {Box, ImageHorizontalList} from 'views/components';

const DisplayPhoto = (props) => {
  const referenceFiles = props.referenceFiles;

  return (
    <Box
      sx={{
        width: '100%',
        height: '240px',
      }}
    >
      <ImageHorizontalList
        images={Object.values(referenceFiles)}
      />
    </Box>
  );
};

DisplayPhoto.propTypes = {
  referenceFiles: PropTypes.object.isRequired,
};

export default React.memo(DisplayPhoto);
