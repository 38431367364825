import backend, {ANALYTICS_API} from 'utils/backend/server/api_library';

async function sendWebsiteAnalytics(data) {
  return backend.post(`${ANALYTICS_API}/analytics/website/metrics`, data);
}
async function sendWebpageAnalytics(data) {
  return backend.post(`${ANALYTICS_API}/analytics/webpage/metrics`, data);
}

export default {
  sendWebsiteAnalytics,
  sendWebpageAnalytics,
};
