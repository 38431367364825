import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {CardContent, CardActionArea, Typography, Stack, Box} from 'views/components';

import {Translate, UserNameDisplay, Avatar, TimeDisplay} from 'views/module';

const Card = (props) => {
  const item = props.item;

  const titleExpression =
    item.categoryType && item.categoryType !== 'undefined' ?
      `market.Types.${item.categoryType}.Title` :
      `market.List.Card.Title.UnMarked`;

  return (
    <React.Fragment>
      <CardActionArea
        sx={{
          textAlign: 'left',
        }}
        to={`/offer/${item.offerRn}`}
      >
        <CardContent>
          <Typography variant="h5" component="div">
            <Translate expression={titleExpression}/>
          </Typography>
          <Typography variant="body2">
            {
              item.description?.blob > 500 ?
               text.substring(0, 500) + '...' :
               item.description?.blob
            }
          </Typography>
          <br />
          <Stack direction={'row'} justifyContent="space-between">
            <Box>
              <Stack direction={'row'}>
                <Avatar
                  userRn={item.requesterRn}
                  detail={item.requester}
                  sx={{width: 24, height: 24, fontSize: 12, mr: 1}}
                  iconsx={{fontSize: '20px'}}
                />
                <UserNameDisplay user={item.requester} />
              </Stack>
            </Box>
            <Box>
              <Stack direction={'row'}>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                  <Translate expression={`market.List.Card.Status.Title`} />
                  <Translate expression={`market.Status.${item.status}`} />
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack direction={'row'}>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                  <Translate expression={`market.List.Card.CreatedAt.Title`} />
                  <TimeDisplay
                    time={item.createdAt}
                  />
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </React.Fragment>
  );
};

Card.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Card));
