import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {getCurrentUser} from 'utils/auth/getSession';

const OAuth = (props) => {
  const userUpdate = props.userUpdate;

  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');

  React.useEffect(() => {
    const fetchData = async () => {
      if (code) {
        url.searchParams.delete('code');

        window.history.pushState({}, '', url.href);

        try {
          const tokenUrl = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`;
          const params = new URLSearchParams();
          params.append('grant_type', 'authorization_code');
          params.append('client_id', process.env.REACT_APP_CONGITO_CLIENT_ID);
          params.append('code', code);
          params.append('redirect_uri', `${process.env.REACT_APP_DOMAIN}/oauth`);
          params.append('scope', 'openid+email+profile');

          const response = await fetch(tokenUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params,
          });
          const data = await response.json();

          const uuid = crypto.randomUUID();

          const lastAuthId = `CognitoIdentityServiceProvider.${process.env.REACT_APP_CONGITO_CLIENT_ID}`;
          const id = `${lastAuthId}.${uuid}`;
          localStorage.setItem(`${lastAuthId}.LastAuthUser`, uuid);

          localStorage.setItem(`${id}.accessToken`, data.access_token);
          localStorage.setItem(`${id}.idToken`, data.id_token);
          localStorage.setItem(`${id}.refreshToken`, data.refresh_token);
          localStorage.setItem(`${id}.clockDrift`, -1);

          const user = await getCurrentUser();
          userUpdate(user);
        } catch (error) {
          console.error('error oauth', error);
        }
      }
    };

    fetchData();
  }, []);


  return null;
};

OAuth.propTypes = {
  userUpdate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(OAuth));
