import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import useNavigate from 'views/module/navigation/useNavigate';

import LoadingSkeleton from './LoadingSkeleton';
import AuthenticationDailog from 'views/scenes/auth/authentication/AuthenticationDailog';

const RequireAuthentication = (props) => {
  const user = props.user;
  const children = props.children;

  const navigate = useNavigate();

  const closeModel = () => {
    navigate('/');
  };

  if (user?.userRn) {
    return (
      <>
        {children}
      </>
    );
  } else {
    return (
      <>
        <LoadingSkeleton />
        <AuthenticationDailog closeModel={closeModel} />
      </>
    );
  }
};

RequireAuthentication.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(RequireAuthentication));
