import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Button, Box} from 'views/components';

import {Translate} from 'views/module';


const ChatText = (props) => {
  const message = props.message;
  const offerOrder = props.offerOrder;
  const contract = props.contract;
  const user = props.user;
  const completeContract = props.completeContract;
  const showFeedbackForm = props.showFeedbackForm;

  const contractUser = contract.user;
  const contractRn = contract.contractRn;
  const contractStatus = contract.status;

  const requester = offerOrder.requester;

  let whoMarkedItComplete = null;
  let button = null;
  if (message.requesterRn === requester.userRn) {
    whoMarkedItComplete = requester;
    if (contractStatus === 'COMPLETED' && user.userRn !== contractUser.userRn) {
      button = 'REVIEW';
    }
  } else if (message.requesterRn === contractUser.userRn) {
    whoMarkedItComplete = contractUser;

    if (contractStatus === 'OPEN' && user.userRn !== contractUser.userRn) {
      button = 'MARK_AS_COMPLETE';
    }
  }

  const onCompleteTheContract = () => {
    completeContract(contractRn, user.userRn);
  };

  return (
    <>
      <Box>
        <Translate
          expression='market.Contract.Feed.Correspondence.ContractComplete.Message'
          substitute={{
            user: `${whoMarkedItComplete.firstName} ${whoMarkedItComplete.lastName}`,
          }}
        />
      </Box>
      {
        (button === 'MARK_AS_COMPLETE') &&
        <Button
          onClick={onCompleteTheContract}
          sx={{
            mt: 1,
          }}
        >
          <Translate expression='market.Contract.Feed.Correspondence.ContractComplete.FinishContract' />
        </Button>
      }
      {
        (button === 'REVIEW') &&
        <Button
          onClick={showFeedbackForm}
          sx={{
            mt: 1,
          }}
        >
          <Translate expression='market.Contract.Feed.Correspondence.ContractComplete.Review' />
        </Button>
      }
    </>
  );
};

ChatText.propTypes = {
  message: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  offerOrder: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  completeContract: PropTypes.func.isRequired,
  showFeedbackForm: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatText));


