/* eslint-disable max-len */

export default {
  DesignCategory: {
    Title: `What do you need designed?`,
  },
  EngagementProcess: {
    Title: `Choose your creative collaboration style`,
    ASSIGNED: {
      Title: 'Expert Assignment',
      Body: `Sit back and relax; we've got this. We pair you with an artist who's not just talented but the perfect fit for your project's vibe and vision. It's like matchmaking for your masterpiece—no fuss, all finesse.`,
    },
    DIRECT_HIRE: {
      Title: 'Pick Your Picasso <br /> (Coming soon)',
      Body: `Step into the spotlight and take the reins. Our gallery of gifted artists is at your fingertips, waiting for you to find your ideal creative partner. Style, skill, spirit—it's your call.`,
    },
    CONTEST: {
      Title: 'Creative Contest <br /> (Coming soon)',
      Body: `Challenge our artists in a creative showdown where your vision is the prize. Watch as unique interpretations of your idea battle it out in a spectacle of creativity, and crown the champion.`,
    },
  },
  ProjectDetail: {
    Title: `Tell us about your project?`,
    Describe: `Describe your project`,
    UploadFiles: {
      Title: 'Upload Photos',
      DragAndDrop: 'Drag and drop an image file here',
      Browse: 'Browse',
      EditPhoto: {
        Title: 'Edit photo',
        TitleEdit: 'Title',
      },
      DeletePhoto: {
        Title: 'Delete photo',
        Explain: 'Are you sure you want to delete this photo?',
      },
    },
  },
  Payment: {
    Title: `Set Your Price, Attract the Best Talent`,
    FREE: {
      Title: 'Free',
      Body: `Opt for our complimentary service, and we'll pair you with an available artist. A great choice for diverse artistic collaborations.`,
    },
    DIRECT: {
      Title: 'Fee (Coming soon)',
      Body: 'Enhance your experience with a paid selection, increasing your likelihood of being matched with our top-tier artists for superior expertise.',
    },
  },
  Submit: {
    Title: `Submit your request`,
    Errors: {
      CATEGORY: `Please select a category for your project by returning to the "What do you need designed?" section.`,
      ENGAGEMENT_PROCESS: `Please specify your preferred style of creative collaboration.`,
      DESCRIPTION: `A project description is required. Please provide this information in the "Tell us about your project" section.`,
      REFERENDCE_FILES: `It looks like you haven't uploaded any reference pictures. Please add them in the "Tell us about your project" section to proceed.`,
      PAYMENT: `Please select a payment method to continue with your transaction.`,
    },
    Warning: `By clicking 'Submit,' I confirm that my request adheres to the <a href="/terms_and_conditions" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Terms of Service</a>
    and <a href="/privacy_policy" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. <br /> I certify that this request does not contain any explicit, inappropriate, or otherwise prohibited material.`,
  },
  Authentication: {
    Title1: `Tell us about your project?`,
    Title2: `Sign-in to continue`,
  },
  New: {
    Duplicate: {
      Title: 'Whoops! Looks like you\'re painting two pictures at once!',
      Body: 'Hold up, Picasso! You\'ve got an unfinished draft that hasn\'t been submitted yet. Want to add the finishing strokes to your existing draft, or start a fresh canvas?',
      ActionContinue: 'Continue Editing',
      ActionNew: 'Start New',
    },
  },
};
