export default {
  Info: {
    Title: 'Every pixel tells a story',
    Body: ' Sign up and start shaping dreams into digital realities.',
  },
  Signup: {
    GetStarted: 'Get Started',
    GetStarted: 'Get started',
    Register: 'Register now with <span style="color:#FC8309">no credit card required.</span> ',
    Form: {
      1: {
        Email: 'Email',
        Action: 'Get started',
        EmailAlreadyUsed: 'This email is already in use. ',
        ClickHereToLogin: 'Click here to log in',
      },
      2: {
        Info: `An email with a verification code has been sent to <span style="color:#FC8309">#{email}</span>. 
        Please check your inbox and enter the code below to proceed.`,
        Action: 'Next',
        Error: 'Error with the code',
      },
      3: {
        FirstName: 'First name',
        LastName: 'Last name',
        Action: 'Next',
      },
      4: {
        Agreement: `I agree with the 
          <a href="/terms_and_conditions" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          and <a href="/privacy_policy" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,

        Password: 'Password',
        RePassword: 'Re-enter Password',
        Action: 'Create account',
      },
      5: {
        Loading: `We're on it! Your account is being whipped up with care and precision. Just a moment more and you'll be all set to explore!`,
        SignedIn: `All systems are go! Your account is ready to rock and roll. Welcome aboard!`,
      },
      HaveAccount: 'Already have an account? ',
      Login: 'Log in',
    },
  },
};
