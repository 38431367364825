import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Typography} from 'views/components';

import {Translate} from 'views/module';

const ViewEngagementProcess = (props) => {
  const offerOrder = props.offerOrder;

  const engagementProcess = offerOrder.engagementProcess || '';


  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
        }}
      >
        <Translate expression={`market.Order.EngagementProcess.${engagementProcess}.Title`} />
      </Typography>
      <Typography>
        <Translate expression={`market.Order.EngagementProcess.${engagementProcess}.Body`} />
      </Typography>
    </Box>
  );
};

ViewEngagementProcess.propTypes = {
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ViewEngagementProcess));
