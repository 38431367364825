import {v4 as uuidv5} from 'uuid';
const _ = require('lodash');

const actionKeys = {
  createProgress: 'CreateProgress',
  setProgressAsComplete: 'SetProgressAsComplete',
  setProgressAsError: 'SetProgressAsError',
  closeMessage: 'CloseMessage',
  clearStatus: 'ClearStatus',
  submitErrorMessage: 'SubmitErrorMessage',
  suppressMessages: 'SuppressMessages',
};

const getStackTraceHash = () => {
  const err = new Error();
  return uuidv5(err.stack);
};

const createUUid = () => {
  return uuidv5();
};

const progressWrap = (func,
    {
      onSuccessMessage = null,
      onErrorMessage,
      showProgress = true,
      setStage,
      debugString = '',
    } = {},
) => async (dispatch) => {
  const uuid = createUUid();

  if (!debugString) {
    debugString = getStackTraceHash();
  }

  dispatch({
    type: actionKeys.createProgress,
    payload: {
      progressUuid: uuid,
      showProgress: showProgress,
      debugString: debugString,
    },
  });

  try {
    setStage && setStage('LOADING');
    await func();
    setProgressAsComplete(uuid, onSuccessMessage)(dispatch);
    setStage && setStage('COMPLETE');
  } catch (e) {
    let errorMessage = null;
    if (typeof onErrorMessage === 'string') {
      errorMessage = onErrorMessage;
    } else if (typeof onErrorMessage === 'function') {
      errorMessage = onErrorMessage(e); ;
    }
    if (!errorMessage) {
      errorMessage = 'Something went wrong';
    }
    setProgressAsError(uuid, e, errorMessage)(dispatch);
    setStage && setStage('ERROR');
  }
};

const setProgressAsComplete = (progressUuid, message) => async (dispatch) => {
  dispatch({
    type: actionKeys.setProgressAsComplete,
    payload: {
      progressUuid: progressUuid,
      show: !!message,
      message: message,
    },
  });
};

const setProgressAsError = (progressUuid, error, message) => async (dispatch) => {
  dispatch({
    type: actionKeys.setProgressAsError,
    payload: {
      progressUuid: progressUuid,
      error: error,
      show: !!message,
      message: message,
    },
  });
};

const closeMessage = (progressUuid) => async (dispatch) => {
  dispatch({
    type: actionKeys.closeMessage,
    payload: {
      progressUuid: progressUuid,
    },
  });
};

const clear = () => async (dispatch) => {
  dispatch({
    type: actionKeys.clearStatus,
  });
};

const submitErrorMessage = (message) => async (dispatch) => {
  const uuid = createUUid();
  dispatch({
    type: actionKeys.submitErrorMessage,
    payload: {
      progressUuid: uuid,
      message: message,
      debugString: getStackTraceHash(),
    },
  });
};

const suppressMessages = (supress) => async (dispatch) => {
  dispatch({
    type: actionKeys.suppressMessages,
    payload: {
      supress,
    },
  });
};


const reducer = (reduxState = {}, action) => {
  const reduxStateClone = _.cloneDeep(reduxState); // reduxState is the old state, creating a clone to manipulate
  if (!reduxStateClone.states) { // If states has not been set, then set it
    reduxStateClone.states = {};
  }
  const states = reduxStateClone.states; // Getting ref point to the states

  if (action.type === actionKeys.createProgress) {
    states[action.payload.progressUuid] = {
      progressUuid: action.payload.progressUuid,
      uuid: action.payload.progressUuid,
      status: action.payload.showProgress ? 'PROGRESS' : 'PROGRESS_WITHOUT_LOADER',
      inProgress: true,
      time: new Date().getTime(),
      startTime: new Date().getTime(),
      debugString: action.payload.debugString,
      currentPage: window.location.href,
    };
  } else if (action.type === actionKeys.setProgressAsComplete) {
    const oldState = states[action.payload.progressUuid];
    if (oldState) {
      states[action.payload.progressUuid] = {
        ...oldState,
        status: 'SUCCESS',
        inProgress: false,
        show: action.payload.show,
        message: action.payload.message,
        endTime: new Date().getTime(),
        time: new Date().getTime(),
      };
    }
  } else if (action.type === actionKeys.setProgressAsError) {
    const oldState = states[action.payload.progressUuid];
    if (oldState) {
      states[action.payload.progressUuid] = {
        ...oldState,
        status: 'FAILURE',
        inProgress: false,
        error: {
          message: action.payload.error.message,
          stack: action.payload.error.stack,
        },
        show: action.payload.show,
        message: action.payload.message,
        endTime: new Date().getTime(),
        time: new Date().getTime(),
      };
    }
  } else if (action.type === actionKeys.closeMessage) {
    const oldState = states[action.payload.progressUuid];
    states[action.payload.progressUuid] = {
      ...oldState,
      show: false,
    };
  } else if (action.type === actionKeys.submitErrorMessage) { // in the case where we set an error without creating a progress
    states[action.payload.progressUuid] = {
      progressUuid: action.payload.progressUuid,
      uuid: action.payload.progressUuid,
      message: action.payload.message,
      custom: true,
      status: 'FAILURE',
      show: true,
      startTime: new Date().getTime(),
      endTime: new Date().getTime(),
      debugString: action.payload.debugString,
    };
  } else if (action.type === actionKeys.clearStatus) {
    reduxStateClone.states = {};
  } else if (action.type === actionKeys.suppressMessages) {
    reduxStateClone.suppressMessages = action.payload.supress;
  }

  return reduxStateClone;
};


export default {
  actions: {
    progressWrap,
    closeMessage,
    clear,
    submitErrorMessage,
    suppressMessages,
  },
  reducer: reducer,
};
