import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid} from 'views/components';

import {CardTable} from 'views/module';

import Card from './Card';

import Backend from 'utils/backend';

const List = (props) => {
  const [data, setData] = React.useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState();
  const [stage, setStage] = React.useState('FILL');

  const progressWrap = props.statusProgressWrap;
  const filter = props.filter;
  const reload = props.reload;

  const isLoading = stage === 'LOADING';

  const loadData = () => {
    progressWrap(async () => {
      const filters = [];


      if (filter.filter.designType) {
        const value = [];
        for (const design of filter.filter.designType) {
          value.push(design);
        }
        if (value.length) {
          filters.push({
            key: 'categoryType',
            value,
            type: 'eq',
          });
        }
      }

      const sortBy = {
        sortKey: 'createdAt',
        sortDirection: 'DESC',
      };

      const result = await Backend.offer.query.byOpenGigs(filters, sortBy, lastEvaluatedKey);
      setData((e) => {
        return [
          ...e,
          ...result.list,
        ];
      });
      setLastEvaluatedKey(result.lastEvaluatedKey);
    }, {setStage, showProgress: false});
  };

  React.useEffect(() => {
    setData([]);
    setLastEvaluatedKey(null);
    loadData();
  }, [filter, reload]);


  return (
    <Grid container>
      <CardTable
        data={data}
        card={Card}
        isLoading={isLoading}
        loadMore={loadData}
        hasMore={!!lastEvaluatedKey}
      />
    </Grid>
  );
};
List.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  reload: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(List));
