import artist from './artist';
import auth from './auth/auth';
import onboarding from './auth/onboarding';
import error from './miscellaneous/error';
import snackbar from './navbar/snackbar';
import footer from './navbar/footer';
import navbar from './navbar/navbar';
import home from './home/home';
import market from './market';
import generic from './miscellaneous/generic';
import policy from './policy';

export default {
  artist,
  auth,
  onboarding,
  error,
  snackbar,
  footer,
  navbar,
  home,
  market,
  generic,
  policy,
};
