/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {SvgIcon} from '@mui/material';

const ContestMode = (props) => {
  const propsSx = props.sx || {};

  return (
    <SvgIcon
      sx={{
        textAlign: 'center',
        verticalAlign: 'middle',
        ...propsSx,
      }}
      viewBox="0 0 40 40"
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path xmlns="http://www.w3.org/2000/svg" d="M 6.071 40 C 2.916 40 0.357 40 0.357 40 L 0.357 0 C 0.357 0 2.916 0 6.071 0 L 33.929 0 C 37.084 0 39.643 0 39.643 0 L 39.643 40 C 39.643 40 37.084 40 33.929 40 Z" fill="rgb(112, 90, 140)"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M 14.643 26.667 C 14.643 26.667 24.643 28.148 28.929 32.593 L 30.357 32.593 C 31.146 32.593 31.786 31.929 31.786 31.111 L 31.786 22.129 C 33.018 21.8 33.929 20.64 33.929 19.259 C 33.929 17.879 33.018 16.719 31.786 16.39 L 31.786 7.407 C 31.786 6.589 31.146 5.926 30.357 5.926 L 28.929 5.926 C 24.643 10.37 14.643 11.852 14.643 11.852 L 8.929 11.852 C 7.351 11.852 6.071 13.178 6.071 14.815 L 6.071 23.704 C 6.071 25.34 7.351 26.667 8.929 26.667 L 10.357 26.667 L 11.786 34.074 L 14.643 34.074 Z M 17.5 14.313 C 18.476 14.096 19.682 13.795 20.985 13.398 C 23.382 12.667 26.429 11.515 28.929 9.74 L 28.929 28.778 C 26.429 27.004 23.382 25.852 20.985 25.12 C 19.682 24.723 18.476 24.423 17.5 24.206 Z M 8.929 14.815 L 14.643 14.815 L 14.643 23.704 L 8.929 23.704 Z" fill="rgb(255, 255, 255)"/>
      </svg>
    </SvgIcon>
  );
};
ContestMode.propTypes = {
  sx: PropTypes.object,
};

export default React.memo(ContestMode);
