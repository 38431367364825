import React from 'react';

import {RequireAuthentication} from 'views/module';
import {Helmet} from 'views/module';

import ArtistSignupScene from 'views/scenes/artist/signup/ArtistSignup';

const ArtistSignup = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Artist signup</title>
      </Helmet>
      <RequireAuthentication>
        <ArtistSignupScene {...props}/>
      </RequireAuthentication>
    </>
  );
};

ArtistSignup.propTypes = {
};

export default React.memo(ArtistSignup);
