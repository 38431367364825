import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Grid, LargeButtons} from 'views/components';

import {Translate, translate} from 'views/module';

const Payment = (props) => {
  const offerOrder = props.offerOrder;
  const nextStep = props.nextStep;

  const paymentType = offerOrder.payment?.type;

  const updateOrderPayment = props.updateOrderPayment;

  React.useEffect(() => {
    if (!paymentType) {
      updateType('FREE');
    }
  }, []);

  const updateType = (newType) => {
    updateOrderPayment({
      type: newType,
    });
    nextStep();
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <LargeButtons
            onClick={() => updateType('FREE')}
            title={<Translate expression='market.Order.Payment.FREE.Title' />}
            description={translate('market.Order.Payment.FREE.Body')}
            selected={paymentType === 'FREE'}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LargeButtons
            onClick={() => updateType('DIRECT')}
            title={<Translate expression='market.Order.Payment.DIRECT.Title' />}
            description={translate('market.Order.Payment.DIRECT.Body')}
            selected={paymentType === 'DIRECT'}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Payment.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  updateOrderPayment: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Payment));
