import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography, LinearProgress} from 'views/components';

import {Dialog, DialogTitle, DialogContent} from '@mui/material';

import axios from 'axios';
import {useDropzone} from 'react-dropzone';

const UploadFileToS3 = (props) => {
  const [isLoading, setIsloading] = React.useState(false);
  const [fileProgress, setFileProgress] = React.useState(false);

  const getSignedUrl = props.getSignedUrl;
  const openDialog = props.openDialog;

  const onDrop = async (files) => {
    setIsloading(true);
    setFileProgress(
        files
            .map((file, index) => ({
              order: index, // this records the order
              fileName: file.name,
              completed: false,
            }))
            .reduce((accu, file) => {
              return {
                ...accu,
                [file.fileName]: file,
              };
            }, {}),
    );
    try {
      for (const file of files) {
        const {signedUrl, onUploadComplete} = await getSignedUrl(file);

        // Set the options for the file upload
        const options = {
          headers: {
            'Content-Type': file.type,
          },
        };

        // Upload the file
        const result = await axios.put(signedUrl, file, options);

        if (result.status === 200) {
          // Construct the URL to access the file
          const fileUrl = signedUrl.split('?')[0]; // Removes the query parameters
          onUploadComplete && onUploadComplete(fileUrl);
          setFileProgress((e) => {
            return {
              ...e,
              [file.name]: {
                fileName: file.name,
                completed: true,
              },
            };
          });
        } else {
          console.error('Upload failed with status', result.status);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };
  const {getRootProps, getInputProps, open} = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
      'image/tiff': ['.tiff', '.tif'],
      'image/webp': ['.webp'],
      'image/heif': ['.heif', '.heic'],
      'image/svg+xml': ['.svg'],
      'image/x-icon': ['.ico'],
    },
    onDrop,
    noClick: true,
    noKeyboard: true,
  });


  React.useEffect(() => {
    if (openDialog) {
      open();
    }
  }, [openDialog]);

  return (
    <>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {props.children}
      </div>
      {
        isLoading &&
        <Dialog
          open={true}
          fullWidth={true}
        >
          <DialogTitle>File Upload Progress</DialogTitle>
          <DialogContent>
            {
              Object.values(fileProgress)
                  .sort((a, b) => a.order - b.order)
                  .map((e, index) => {
                    const fileName = e.fileName;
                    let displayFileName;

                    if (fileName.length > 20) {
                      displayFileName = `${fileName.slice(0, 15)}...${fileName.slice(-15)}`;
                    } else {
                      displayFileName = fileName;
                    }
                    const completed = e.completed;

                    return (
                      <Grid
                        container
                        key={index}
                        justifyContent={'center'}
                        alignItems={'center'}
                        alignContent={'center'}
                      >
                        <Grid item xs={6}>
                          <Typography variant="body1" sx={{flexGrow: 0}}>
                            {displayFileName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {
                            !completed ?
                            <LinearProgress
                              sx={{flexGrow: 1}}
                            />:
                            <LinearProgress
                              variant="determinate"
                              value={100}
                              sx={{flexGrow: 1}}
                            />
                          }
                        </Grid>
                      </Grid>
                    );
                  })
            }
          </DialogContent>
        </Dialog>

      }
    </>
  );
};

UploadFileToS3.propTypes = {
  getSignedUrl: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  children: PropTypes.any,
  openDialog: PropTypes.any,
};

export default UploadFileToS3;
