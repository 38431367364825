import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Paper, Typography} from 'views/components';
import {ImageHorizontalList} from 'views/components';

import ChatType from './chat_type';

const ShowOwnMessage = (props) => {
  const message = props.message;
  const showTime = props.showTime;

  const fileUrls = message.fileUrls || [];
  const createdAt = new Date(message.createdAt);

  const images = fileUrls.map((e) => {
    return {
      imageUrl: e,
    };
  });

  const time = `${createdAt.getHours().toString().padStart(2, '0')}:${createdAt.getMinutes().toString().padStart(2, '0')}`;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 1,
        ml: {lg: 16, md: 8, xs: 4},
      }}>
      {
        showTime &&
        <Box
          sx={{
            mr: 1,
          }}
        >
          <Typography
            variant="caption"
          >
            {time}
          </Typography>
        </Box>
      }
      <Paper
        sx={{
          padding: 1,
          backgroundColor: 'lightblue',
        }}
      >
        <Box>
          <ImageHorizontalList
            images={images}
            imageWidth='160px'
          />
        </Box>
        <ChatType
          {...props}
          message={message}
        />
      </Paper>
    </Box>
  );
};

ShowOwnMessage.propTypes = {
  message: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  showTime: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ShowOwnMessage));


