import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, Box} from 'views/components';

import {translate, FilterSettings} from 'views/module';

import FilterChipOption from './FilterChipOption';
import FilterOption from './FilterOption';

const Filter = (props) => {
  const filter = props.filter;
  const updateFilter = props.updateFilter;
  const onTabChange = props.onTabChange;
  const onClearFilter = props.onClearFilter;
  const smallerScreen = props.smallerScreen;

  const calculateFilterOptions = React.useCallback(() => {
    const filterOptions = {};

    const types = translate('market.Types');

    filterOptions.designType = {
      title: translate('market.List.Filter.RequestDesign'),
      rank: 1,
      options: {
        designType: {
          optionTitle: translate('market.List.Filter.RequestDesign'),
          list: Object.entries(types).map(([key, value], index) => {
            let value2 = [];
            if (filter?.filter?.designType?.includes(value.Key)) {
              value2 = filter.filter.designType;
            } else {
              value2 = [...filter.filter.designType, value.Key];
            }

            return {
              title: value.Title,
              rank: index,
              value: value2,
            };
          }),
        },
      },
    };


    return filterOptions;
  }, [filter, smallerScreen]);

  const filterOptionData = calculateFilterOptions();

  return (
    <Stack
      direction='row'
      alignItems={{md: 'center', xs: 'flex-start'}}
      flexWrap="wrap"
      sx={{
        width: {md: '150%', xs: '100%'},
      }}
    >
      <FilterOption
        updateFilter={updateFilter}
        onTabChange={onTabChange}
        filterOptionData={filterOptionData}
      />
      <FilterChipOption
        filter={filter}
        updateFilter={updateFilter}
        onTabChange={onTabChange}
        filterOptionData={filterOptionData}
        smallerScreen={smallerScreen}
      />
      <Box >
        <FilterSettings
          onClearFilter={onClearFilter}
        />
      </Box>
    </Stack>
  );
};

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  smallerScreen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
});


export default connect(mapStateToProps, dispatch)(React.memo(Filter));
