import {
  getUserPool,
  CognitoUser,
} from 'utils/auth/cognito';


const sendOTPPasswordForgot = async (username) => {
  const userPool = getUserPool();

  // Create a cognito user object
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, error) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        // successfully initiated reset password request
        resolve({
          status: 'SUCCESS',
          data,
        });
      },
      onFailure: (err) => {
        console.error(err);
        error(err);
      },
    });
  });
};

const resetForgotPassword = (username, code, newPassword) => {
  const userPool = getUserPool();

  // Create a cognito user object
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, error) => {
    cognitoUser.confirmPassword(
        code,
        newPassword,
        {
          onSuccess: (data) => {
            // successfully initiated reset password request
            resolve({
              status: 'SUCCESS',
              data,
            });
          },
          onFailure: (err) => {
            console.error(err);
            error(err);
          },
        });
  });
};

export {
  sendOTPPasswordForgot,
  resetForgotPassword,
};
