import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {RequireAuthentication, useParams} from 'views/module';
import {Helmet} from 'views/module';

import OfferScene from 'views/scenes/market/offer/Offer';

const Offer = (props) => {
  const params = useParams();

  const offerRn = params.offerRn;

  return (
    <>
      <Helmet>
        <title>StudioGrams: Offer</title>
      </Helmet>
      <RequireAuthentication>
        <OfferScene
          offerRn={offerRn}
        />
      </RequireAuthentication>
    </>
  );
};

Offer.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Offer));

