import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Grid, TextField, Button} from 'views/components';
import {Translate, translate} from 'views/module';

import {completeNewPassword} from 'utils/auth/login';

const UserAuthenticationResetPassword = (props) => {
  const [password, setPassword] = React.useState(''); ;
  const [rePassword, setRePassword] = React.useState('');

  const [isResetting, setIsResetting] = React.useState(false);

  const [numValid, setNumValid] = React.useState(0);

  const progressWrap = props.statusProgressWrap;
  const userData = props.userData;
  const onSignIn = props.onSignIn;

  React.useEffect(() => {
    const passwordValid = password.length >= 6 ? 1 : 0;
    const rePasswordValid = rePassword.length >= 6 ? 1 : 0;
    const passwordIsEqual = password === rePassword ? 1 : 0;
    setNumValid(passwordValid + rePasswordValid + passwordIsEqual);
  }, [password, rePassword]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (numValid !== 3) {
      return;
    }

    progressWrap(async () => {
      setIsResetting(true);
      try {
        await completeNewPassword(userData, password);
        if (onSignIn) {
          onSignIn();
        }
      } finally {
        setIsResetting(false);
      }
    }, {showProgress: false});
  };

  return (
    <form onSubmit={onSubmit} noValidate style={{width: '100%'}}>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <TextField
          type="password"
          value={password}
          onChange={setPassword}
          label={translate('auth.Signup.UserAuth.ResetPassword.Password')}
          fullWidth
          InputLabelProps={{shrink: true}}
        />
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <TextField
          type="password"
          value={rePassword}
          onChange={setRePassword}
          label={translate('auth.Signup.UserAuth.ResetPassword.RePassword')}
          fullWidth
          InputLabelProps={{shrink: true}}
        />
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          loading={isResetting}
          sx={{
            'minHeight': '48px',
            '&:disabled': {
              backgroundColor: '#FB8488',
            },
          }}
          disabled={!(numValid === 3)}
          onClick={onSubmit}
          type="submit"
        >
          <Typography
            variant='h5'
            sx={{
              color: 'color.white',
              fontWeight: 500,
              my: '4px',
            }}
          >
            <Translate expression='auth.Signup.UserAuth.ResetPassword.Action' />
          </Typography>
        </Button>
      </Grid>
    </form>
  );
};

UserAuthenticationResetPassword.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  onSignIn: PropTypes.func,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(UserAuthenticationResetPassword));
