

const signin = (provider, path) => {
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const domain = process.env.REACT_APP_DOMAIN;
  const clientId = process.env.REACT_APP_CONGITO_CLIENT_ID;
  const responseType = 'code';
  const scope = 'email+openid+profile';

  // Construct the redirect URI
  const redirectUri = `${domain}/oauth`;

  // URL encode the redirectUri
  const encodedRedirectUri = encodeURIComponent(redirectUri);

  // Construct the full authorization URL
  const authorizationUrl =
    `${cognitoDomain}/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${encodedRedirectUri}`;

  window.location.href = authorizationUrl;
};

export default signin;
