const actionKeys = {
  loadEnvironment: 'LoadEnvironment',
};

const loadData = (environment) => async (dispatch, state) => {
  dispatch({
    type: actionKeys.loadEnvironment,
    payload: {
      ...environment,
    },
  });
};


const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionKeys.loadEnvironment:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};


export default {
  actions: {
    loadData,
  },
  reducer: reducer,
};
