import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Box, List, ListItemButton} from 'views/components';

import _ from 'lodash';


const FilterOptions = (props) => {
  const {
    data,
    onClick,
    filterKey,
  } = props;

  if (_.isEmpty(data)) {
    return null;
  }

  const sortedData = Object.entries(data).sort((a, b, indexA, indexB) => {
    // If both items have a rank, compare them by rank
    if (a[1].rank !== undefined && b[1].rank !== undefined) {
      return a[1].rank - b[1].rank;
    }
    // If only one item has a rank, put it before the other item
    if (a[1].rank !== undefined) {
      return -1;
    }
    if (b[1].rank !== undefined) {
      return 1;
    }
    // If neither item has a rank, compare them by index
    return indexA - indexB;
  });

  return (
    <Box sx={{pointerEvents: 'auto', p: 1}}>
      {
        sortedData.map(([key, optionData]) => {
          const sortedOptionData = optionData.list.sort((a, b, indexA, indexB) => {
            // If both items have a rank, compare them by rank
            if (a.rank !== undefined && b.rank !== undefined) {
              return a.rank - b.rank;
            }
            // If only one item has a rank, put it before the other item
            if (a.rank !== undefined) {
              return -1;
            }
            if (b.rank !== undefined) {
              return 1;
            }
            // If neither item has a rank, compare them by index
            return indexA - indexB;
          });

          return (
            <Box key={key}>
              <Typography sx={{fontWeight: 'bold'}}>
                {optionData.optionTitle}
              </Typography>
              <List sx={{pb: 0}}>
                {
                  sortedOptionData.map((option, index) => {
                    return (
                      <ListItemButton key={index} onClick={() => onClick(filterKey, option)}>
                        {option.title}
                      </ListItemButton>
                    );
                  })
                }
              </List>
            </Box>
          );
        })
      }
    </Box>
  );
};
FilterOptions.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  filterKey: PropTypes.string,
};

export default React.memo(FilterOptions);
