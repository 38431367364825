import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {screenSizeSmaller} from 'views/components';

import NavbarBigScreen from './navbar_big_screen';
import NavbarSmallScreen from './navbar_small_screen';


const NavBar = (props) => {
  const smallScreen = screenSizeSmaller('md');

  if (smallScreen) {
    return (
      <NavbarSmallScreen
      />
    );
  } else {
    return (
      <NavbarBigScreen
      />
    );
  }
};


NavBar.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(NavBar));
