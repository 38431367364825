import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Stack, Typography, Button, CheckboxField} from 'views/components';

import {Translate} from 'views/module';

import {ErrorOutline} from 'views/icons';

const EditSubmit = (props) => {
  const [errors, setErrors] = React.useState([]);
  const [warningChecked, setWarningChecked] = React.useState(false);

  const offerOrder = props.offerOrder;
  const publish = props.publish;

  React.useEffect(() => {
    const localErros = [];
    if (!offerOrder.category?.type) {
      localErros.push('CATEGORY');
    }
    if (!offerOrder.engagementProcess) {
      localErros.push('ENGAGEMENT_PROCESS');
    }
    if (!offerOrder.description) {
      localErros.push('DESCRIPTION');
    }
    if (!Object.keys(offerOrder.referenceFiles || {}).length) {
      localErros.push('REFERENDCE_FILES');
    }
    if (!offerOrder.payment?.type) {
      localErros.push('PAYMENT');
    }

    setErrors(localErros);
  }, [offerOrder]);

  const onPublish = () => {
    publish();
  };

  return (
    <Box>
      <Stack spacing={2}>
        {
          errors.map((e, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: '100%',
                  mx: '16px',
                }}
              >
                <ErrorOutline color='error' />
                <Typography
                  color="error" variant="body2"
                  sx={{
                    ml: '4px',
                  }}
                >
                  <Translate expression={`market.Order.Submit.Errors.${e}`} />
                </Typography>
              </Box>
            );
          })
        }
        <CheckboxField
          label={<Translate expression={`market.Order.Submit.Warning`} />}
          onChange={setWarningChecked}
          checked={warningChecked}
        />
        <Button
          disabled={!(!errors.length && warningChecked)}
          onClick={onPublish}
        >
          <Translate expression='generic.Submit' />
        </Button>
      </Stack>
    </Box>
  );
};

EditSubmit.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  publish: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(EditSubmit));
