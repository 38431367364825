import React from 'react';
import PropTypes from 'prop-types';

import {ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import {Dialog, DialogContent, DialogActions} from '@mui/material';
import {IconButton} from '@mui/material';

import {Button} from '..';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

const ImageHorizontalList = (props) => {
  const [zoomedInImage, setZoomedInImage] = React.useState(null);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleOpen = (image) => {
    setZoomedInImage(image);
  };

  const handleClose = () => {
    setZoomedInImage(null);
  };

  const handleDownload = async () => {
    setIsDownloading(true); // Start download
    try {
      const response = await fetch(zoomedInImage.imageUrl);
      if (!response.ok) throw new Error('Network response was not ok.');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = zoomedInImage.fileName || 'downloaded-image';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
    }
    setIsDownloading(false); // End download
  };

  const images = props.images;
  const imageWidth = props.imageWidth || '240px';
  const removeImage = props.removeImage;

  if (!images?.length) {
    return null;
  }

  return (
    <>
      {
        zoomedInImage &&
        <Dialog open={true} onClose={handleClose}>
          <DialogContent>
            <img src={zoomedInImage.imageUrl} style={{maxWidth: '100%', maxHeight: '100%'}} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDownload} loading={isDownloading}>
              Download
            </Button>
          </DialogActions>
        </Dialog>
      }
      <ImageList
        sx={{
          width: '100%',
          height: '100%',
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'row',
          m: 0,
        }}
      >
        {
          images.map((item, index) => {
            const imageUrl = item.imageUrl;
            const fileName = item.fileName;

            return (
              <ImageListItem key={index}>
                <img
                  src={imageUrl}
                  alt={fileName}
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: imageWidth,
                  }}
                />
                <ImageListItemBar
                  title={fileName}
                  actionIcon={
                    <IconButton
                      sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                      onClick={(e) => {
                        handleOpen(item);
                      }}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  }
                />
                {
                  removeImage &&
                  <IconButton
                    sx={{
                      'position': 'absolute',
                      'top': 0,
                      'right': 0,
                      'color': 'white', // Change the color for better contrast
                      'backgroundColor': 'rgba(0, 0, 0, 0.6)', // Adding a semi-transparent black background
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker background on hover
                      },
                    }}
                    onClick={() => removeImage(index)}
                  >
                    <CloseIcon fontSize="small"/>
                  </IconButton>
                }
              </ImageListItem>
            );
          })
        }
      </ImageList>
    </>
  );
};

ImageHorizontalList.propTypes = {
  images: PropTypes.array.isRequired,
  imageWidth: PropTypes.any,
  removeImage: PropTypes.func,
};

export default React.memo(ImageHorizontalList);
