import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, Button, Typography} from 'views/components';

import {Translate} from 'views/module';

const Header = (props) => {
  return (
    <Grid container>
      <Grid item md={9} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
        <Typography
          variant={'h5'}
          sx={{textTransform: 'uppercase', fontWeight: 'bold'}}
          align='left'
        >
          <Translate expression='market.List.Types.OpenRequest.Title' />
        </Typography>
      </Grid>
      <Grid item md={3} xs={6} sx={{display: 'flex', margin: 'auto', justifyContent: 'flex-end'}}>
        <Button
          href='/offer/list/my_gigs'
        >
          <Translate expression='market.List.Types.OpenRequest.Action' />
        </Button>
      </Grid>
    </Grid>
  );
};
Header.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Header));
