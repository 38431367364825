import backend, {MARKET_API} from 'utils/backend/server/api_library';

import queryString from 'query-string';

const get = (offerRn, fetchParams, allowCacheResult) => {
  const queryParam = {
    fetchParams: JSON.stringify(fetchParams),
    allowCacheResult,
  };
  const query = queryString.stringify(queryParam, {skipNull: true, skipEmptyString: true});

  return backend.get(`${MARKET_API}/offer/${offerRn}?${query}`);
};

export default get;
