import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Container} from 'views/components';

import {useLocation, ErrorBoundary} from 'views/module';

import Status from 'views/module/status/Status';
import Navbar from 'views/module/appbar/navbar/Navbar';
import Footer from 'views/module/appbar/Footer';
import InternalServerError from './error/InternalServerError';

import Router from './Router';

const Site = (props) => {
  const location = useLocation();
  const user = props.user;

  const loaded = Boolean(user?.lastLoadTime);

  return (
    <ErrorBoundary FallbackComponent={InternalServerError}>
      <Container
        maxWidth={false}
        disableGutters sx={{'backgroundColor': 'background.main'}}
      >
        <Navbar />
        <Status showProgressBar={true} location={location}/>
        <Container
          fixed
          disableGutters
          maxWidth={'lg'}
          sx={{
            minHeight: '100vh',
            px: {lg: '8px', xs: '8px'},
            py: {lg: '16px', xs: '8px'},
          }}
        >
          {
            loaded &&
            <Router />
          }
        </Container>
        <Footer />
      </Container>
    </ErrorBoundary>
  );
};

Site.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(Site));


