import contract from './contract';
import feedback from './feedback';
import list from './list';
import order from './order';
import status from './status';
import types from './types';

export default {
  Contract: contract,
  Feedback: feedback,
  List: list,
  Order: order,
  Status: status,
  Types: types,
  Types: types,
};
