import React from 'react';
import PropTypes from 'prop-types';

import {Menu} from 'views/components';

const FilterMenuPopover = (props) => {
  const {
    anchorEl,
    anchorPositionTop,
    anchorPositionLeft,
    onClose,
    sx,
    display,
  } = props;

  return (
    <Menu
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: anchorPositionTop,
        left: anchorPositionLeft,
      }}
      MenuListProps={{disablePadding: true}}
      sx={sx}
      PaperProps={{
        sx: {
          maxHeight: '250px',
          minWidth: '175px',
          pointerEvents: 'auto',
        },
      }}
    >
      {display}
    </Menu>
  );
};
FilterMenuPopover.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  anchorPositionTop: PropTypes.number.isRequired,
  anchorPositionLeft: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  sx: PropTypes.object,
  display: PropTypes.node.isRequired,
};

export default React.memo(FilterMenuPopover);
