import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import OfferOrder from './order/OfferOrder';
import OfferContract from './contract/OfferContract';

import backend from 'utils/backend';

const Offer = (props) => {
  const [reload, setReload] = React.useState(0);

  const [offerOrder, setOfferOrder] = React.useState(null);
  const [offerStatus, setOfferStatus] = React.useState(null);
  const [offerContracts, setOfferContracts] = React.useState(null);
  const [offerMessages, setOfferMessages] = React.useState(null);

  const progressWrap = props.statusProgressWrap;
  const offerRn = props.offerRn;

  const status = offerStatus?.status;

  // Get the offer data
  React.useEffect(() => {
    fetchData();
  }, [offerRn, reload]);

  const fetchData = () => {
    progressWrap(async () => {
      const fetchParam = {
        order: true,
        status: true,
        contracts: true,
        messages: true,
        fetchRequesterData: true,
        fetchContractUserData: true,
      };
      const result = await backend.offer.get(offerRn, fetchParam, false);
      setOfferOrder(result.offerOrder);
      setOfferStatus(result.offerStatus);
      setOfferContracts(result.offerContracts);
      setOfferMessages(result.offerMessages);
    },
    {
      showProgress: reload === 0,
      debugString: 'fetchData',
    },
    );
  };

  // Update offer order
  const updateOfferOrder = async (callback) => {
    const originalOfferOrder = offerOrder;

    try {
      await callback(setOfferOrder);
    } catch (e) {
      setOfferOrder(originalOfferOrder);
    }
  };

  const updateOrderCategory = (category) => {
    progressWrap(async () => {
      await updateOfferOrder(async (setOfferOrder) => {
        setOfferOrder((e) => {
          return {
            ...e,
            category,
          };
        });

        await backend.offer.order.updateCategory(offerRn, category);
      });
    });
  };
  const updateOrderEngagementProcess = (engagementProcess) => {
    progressWrap(async () => {
      await updateOfferOrder(async (setOfferOrder) => {
        setOfferOrder((e) => {
          return {
            ...e,
            engagementProcess,
          };
        });

        await backend.offer.order.updateEngagementProcess(offerRn, engagementProcess);
      });
    });
  };
  const updateOrderPayment = (payment) => {
    progressWrap(async () => {
      await updateOfferOrder(async (setOfferOrder) => {
        setOfferOrder((e) => {
          return {
            ...e,
            payment,
          };
        });

        await backend.offer.order.updatePayment(offerRn, payment);
      });
    });
  };
  const updateOrderDescription = (description) => {
    progressWrap(async () => {
      await updateOfferOrder(async (setOfferOrder) => {
        setOfferOrder((e) => {
          return {
            ...e,
            description,
          };
        });

        await backend.offer.order.updateDescription(offerRn, description);
      });
    });
  };
  const addOrderFileWithSignedUrl = (file) => {
    return new Promise((resolve, reject) => {
      progressWrap(async () => {
        try {
          const fileName = file.name;
          const type = file.type;
          const result = await backend.offer.order.files.addFileWithSignedUrl(offerRn, fileName, type);

          const fileRn = result.fileRn;
          const fileData = result.fileData;

          const onUpload = async () => {
            await updateOfferOrder(async (setOfferOrder) => {
              setOfferOrder((e) => {
                return {
                  ...e,
                  referenceFiles: {
                    ...e.referenceFiles,
                    [fileRn]: fileData,
                  },
                };
              });
            });
          };

          resolve({
            signedUrl: result.signedUrl,
            onUploadComplete: onUpload,
          });
        } catch (error) {
          reject(error);
          throw error;
        }
      });
    });
  };
  const editOrderFile = (fileRn, title) => {
    return new Promise((resolve, reject) => {
      progressWrap(async () => {
        await updateOfferOrder(async (setOfferOrder) => {
          const result = await backend.offer.order.files.editFile(offerRn, fileRn, title);
          setOfferOrder(result.offerOrder);
          resolve(result);
        });
      });
    });
  };
  const deleteOrderFile = (fileRn) => {
    return new Promise((resolve, reject) => {
      progressWrap(async () => {
        await updateOfferOrder(async (setOfferOrder) => {
          const result = await backend.offer.order.files.deleteFile(offerRn, fileRn);
          setOfferOrder(result.offerOrder);
          resolve(result);
        });
      });
    });
  };
  const publish = () => {
    progressWrap(async () => {
      const result = await backend.offer.status.publish(offerRn);
      setOfferStatus(result.offerStatus);
    });
  };
  const applyForContract = (userRn) => {
    progressWrap(async () => {
      const result = await backend.offer.contract.applyForContract(offerRn, userRn);
      setOfferContracts((e) => {
        return [
          ...e,
          result.contract,
        ];
      });
    });
  };
  const completeContract = (contractRn, userRn) => {
    progressWrap(async () => {
      const result = await backend.offer.contract.completeContract(offerRn, contractRn, userRn);

      setOfferContracts(result.offerContracts);
      setOfferStatus(result.offerStatus);
      refreshMessage();
    });
  };
  const refreshMessage = () => {
    setReload((e) => e + 1);
  };
  const sendMessage = (contractRn, body, requesterRn, fileUrls) => {
    progressWrap(async () => {
      const result = await backend.offer.contractMessage.sendMessage(offerRn, contractRn, body, requesterRn, fileUrls);
      setOfferMessages((e) => {
        return [
          ...e,
          result.message,
        ];
      });
    });
  };
  const addMessageFile = (contractRn, file) => {
    return new Promise((resolve, reject) => {
      progressWrap(async () => {
        try {
          const type = file.type;
          const result = await backend.offer.contractMessage.addFile(offerRn, contractRn, type);

          resolve({
            signedUrl: result.signedUrl,
            fileUrl: result.fileUrl,
          });
        } catch (error) {
          reject(error);
          throw error;
        }
      });
    });
  };
  const giveFeedback = (question, answer) => {
    progressWrap(async () => {
      await backend.offer.feedback.giveFeedback(offerRn, question, answer);
    }, {showProgress: false});
  };

  const messageManager = {
    refresh: refreshMessage,
    send: sendMessage,
    addFile: addMessageFile,
  };

  if (status === 'DRAFT') {
    return (
      <OfferOrder
        offerOrder={offerOrder}
        offerStatus={offerStatus}
        updateOrderCategory={updateOrderCategory}
        updateOrderEngagementProcess={updateOrderEngagementProcess}
        updateOrderPayment={updateOrderPayment}
        updateOrderDescription={updateOrderDescription}
        addOrderFileWithSignedUrl={addOrderFileWithSignedUrl}
        editOrderFile={editOrderFile}
        deleteOrderFile={deleteOrderFile}
        publish={publish}
      />
    );
  } else if (status === 'PUBLISHED' || status === 'COMPLETED') {
    return (
      <OfferContract
        offerOrder={offerOrder}
        offerStatus={offerStatus}
        offerContracts={offerContracts}
        offerMessages={offerMessages}
        applyForContract={applyForContract}
        completeContract={completeContract}
        messageManager={messageManager}
        giveFeedback={giveFeedback}
      />
    );
  }
  return null;
};

Offer.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  offerRn: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Offer));

