import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import OnboardSignup from './onboard_signup/OnboardSignup'; ;

const Authentication = (props) => {
  return (
    <OnboardSignup
    />
  );
};

Authentication.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Authentication));
