const actionKeys = {
  setUser: 'SetUser',
  signout: 'Signout',
  reload: 'Reload',
};

const update = (user) => async (dispatch) => {
  dispatch({
    type: actionKeys.setUser,
    payload: {
      ...user,
    },
  });
};

const signout = () => async (dispatch) => {
  dispatch({
    type: actionKeys.signout,
  });
};

const reload = () => async (dispatch) => {
  dispatch({
    type: actionKeys.signout,
  });
};


const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionKeys.setUser:
      return {
        ...state,
        ...action.payload,
        lastLoadTime: new Date().toISOString(),
      };
    case actionKeys.reload:
    case actionKeys.signout:
      return {
      };
    default:
      return state;
  }
};


export default {
  actions: {
    update,
    signout,
    reload,
  },
  reducer: reducer,
};
