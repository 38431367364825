import backend, {MARKET_API} from 'utils/backend/server/api_library';

const createOffer = (offer) => {
  const body = {
    offer,
  };
  return backend.post(`${MARKET_API}/offer`, body);
};

export default createOffer;
