import React from 'react';
import PropTypes from 'prop-types';

import {TextField as MUTextField, InputAdornment} from '@mui/material';

const TextField = (props) => {
  const [isInvalid, setIsInvalid] = React.useState(false);

  const {
    value = '',
  } = {...props};

  const {
    validation,
    onChange,
    icon: Icon,
    onBlur = () => {},
    ...textFieldProps
  } = {...props};

  const onUpdate = (e) => {
    onChange(e.target.value);
    if (validation && validation.regex && validation.update) {
      const re = new RegExp(validation.regex);
      const isValid = re.test(e.target.value);
      validation.update(isValid);
      setIsInvalid(!isValid);
    }
  };

  const validate = () => {
    if (validation && validation.regex && validation.update) {
      const re = new RegExp(validation.regex);
      const isValid = re.test(value);
      validation.update(isValid);
      setIsInvalid(!isValid);
    }
  };


  let inputProps = {};
  if (Icon) {
    inputProps = {
      startAdornment: (
        <InputAdornment position="start">
          <Icon />
        </InputAdornment>
      ),
      ...(textFieldProps.InputProps || {}),
    };
  }

  return (
    <MUTextField
      variant={'outlined'}
      {...textFieldProps}
      InputProps={inputProps}
      onChange={onUpdate}
      onBlur={() => validate() | onBlur()}
      error={isInvalid}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.object,
  icon: PropTypes.object,
};

export default TextField;
