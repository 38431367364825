import create from './create';
import get from './get';
import query from './query';
import order from './update_order';
import status from './update_status';
import contract from './contract';
import contractMessage from './contract_message';
import feedback from './feedback';

export default {
  create,
  get,
  query,
  order,
  status,
  contract,
  contractMessage,
  feedback,
};
