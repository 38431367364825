import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {useNavigate} from 'views/module';

import OfferOrder from './order/OfferOrder';

import backend from 'utils/backend';

const GuestOffer = (props) => {
  const [offerOrder, setOfferOrder] = React.useState(() => {
    const savedOrder = localStorage.getItem('guestOfferOrder');
    return savedOrder ? JSON.parse(savedOrder) : {};
  });

  const user = props.user;

  const navigate = useNavigate();

  React.useEffect(() => {
    if (typeof rdt === 'function') {
      rdt('track', 'AddToCart', {
        'itemCount': 1,
      });
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('guestOfferOrder', JSON.stringify(offerOrder));
  }, [offerOrder]);

  const createNewOffer = async () => {
    const offer = {
      requesterRn: user.userRn,
    };
    const result = await backend.offer.create(offer);
    const offerRn = result.offerRn;

    if (offerOrder.category) {
      await backend.offer.order.updateCategory(offerRn, offerOrder.category);
    }

    await backend.offer.order.updateEngagementProcess(offerRn, 'ASSIGNED');

    navigate(`/offer/${offerRn}`);
  };

  React.useEffect(() => {
    if (user?.userRn) {
      createNewOffer();
    }
  }, [user]);

  const updateOrderCategory = (category) => {
    setOfferOrder((e) => {
      return {
        ...e,
        category,
      };
    });
  };
  const updateOrderEngagementProcess = (engagementProcess) => {
    setOfferOrder((e) => {
      return {
        ...e,
        engagementProcess,
      };
    });
  };
  const updateOrderPayment = (payment) => {
    setOfferOrder((e) => {
      return {
        ...e,
        payment,
      };
    });
  };
  const updateOrderDescription = (description) => {
    setOfferOrder((e) => {
      return {
        ...e,
        description,
      };
    });
  };
  const uploadOrderFile = (file, preview) => {
    console.log(file);
    const uuid = crypto.randomUUID();
    setOfferOrder((e) => {
      return {
        ...e,
        referenceFiles: {
          ...e.referenceFiles,
          [uuid]: {
            fileRn: uuid,
            fileName: file.name,
            preview: preview,
          },
        },
      };
    });
  };
  const editOrderFile = (fileRn, title) => {
  };
  const deleteOrderFile = (fileRn) => {
  };

  return (
    <OfferOrder
      offerOrder={offerOrder}
      updateOrderCategory={updateOrderCategory}
      updateOrderEngagementProcess={updateOrderEngagementProcess}
      updateOrderPayment={updateOrderPayment}
      updateOrderDescription={updateOrderDescription}
      uploadOrderFile={uploadOrderFile}
      editOrderFile={editOrderFile}
      deleteOrderFile={deleteOrderFile}
    />
  );
};

GuestOffer.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(GuestOffer));

