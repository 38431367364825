import artist from './server/artist';
import identity from './server/identity';
import offer from './server/offer';
import analytics from './server/analytics';

export default {
  artist,
  identity,
  offer,
  analytics,
};
