import * as React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography} from 'views/components';
import {IconButton, Avatar, Tooltip, Menu, MenuItem, MenuItemLink} from 'views/components';

import {Translate} from 'views/module';

const Profile = (props) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const user = props.user;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    < >
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} >
          <Avatar alt="Remy Sharp" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem >
          <Typography textAlign="center">
            {user.firstName} {user.lastName}
          </Typography>
        </MenuItem>
        <MenuItemLink onClick={handleCloseUserMenu} href={'/signout'}>
          <Typography textAlign="center">
            <Translate expression="generic.SignOut" />
          </Typography>
        </MenuItemLink>
      </Menu>
    </>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(Profile));

