import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {StickyTooltip} from 'views/components';

import MUAvatar from '@mui/material/Avatar';

import {userColours} from 'css/UserColours';

import Backend from 'utils/backend';

import UserNameDisplay from './UserNameDisplay';

const Avatar = (props) => {
  const [user, setUser] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  const userRn = props.userRn;
  const userDetail = props.detail;
  const sx = props.sx;

  React.useEffect(() => {
    if (userDetail?.firstName && userDetail?.lastName) {
      setUser(userDetail);
      setFetching(false);
    } else if (userRn) {
      const fetchUser = async () => {
        setFetching(true);
        try {
          const response = await Backend.identity.get(userRn);
          if (response.email) {
            setUser(response);
          } else {
            setUser(null);
          }
        } catch (e) {
          console.error(e);
        }
        setFetching(false);
      };

      fetchUser();
    }
  }, [userRn, userDetail]);

  if (!user || fetching) return null;

  const firstName = user.firstName;
  const lastName = user.lastName;
  const initial = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  const color = userColours(userRn);

  return (
    <StickyTooltip
      title={<UserNameDisplay user={user}/>}
    >
      <MUAvatar sx={sx} style={{backgroundColor: color}}>{initial}</MUAvatar>
    </StickyTooltip>
  );
};
Avatar.propTypes = {
  userRn: PropTypes.string,
  detail: PropTypes.object,
  sx: PropTypes.object,
  iconsx: PropTypes.object,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Avatar));
