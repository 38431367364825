import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Button, Grid, Typography} from 'views/components';
import {Dialog, DialogTitle, DialogContent} from 'views/components';

import {Translate} from 'views/module';

import SignUp from './onboard_signup/OnboardSignup';
import Authentication from './authentication/Authentication';

const EditSubmit = (props) => {
  const [open, setOpen] = React.useState(false);
  const [activeDialog, setActiveDialog] = React.useState(null);

  const handleOpen = (dialogType) => {
    setActiveDialog(dialogType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={6}
        >
          <Button
            fullWidth
            onClick={() => handleOpen('signup')}
            sx={{minHeight: '100%'}}
          >
            <Typography
              variant='h6'
            >
              <Translate expression={'navbar.Authentication.Login'} />
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6}
        >
          <Button
            fullWidth
            onClick={() => handleOpen('login')}
            sx={{minHeight: '100%'}}
          >
            <Typography
              variant='h6'
            >
              <Translate expression={'navbar.Authentication.NewAccount'} />
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {
        open &&
        <Dialog onClose={handleClose}>
          <DialogTitle>{activeDialog === 'login' ? 'Sign Up' : 'Log In'}</DialogTitle>
          <DialogContent>
            {activeDialog === 'login' ? <SignUp /> : <Authentication/> }
          </DialogContent>
        </Dialog>
      }
    </Box>
  );
};

EditSubmit.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(EditSubmit));
