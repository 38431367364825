const actionKeys = {
  setArtist: 'setArtist',
};

const update = (userRn, status) => async (dispatch) => {
  dispatch({
    type: actionKeys.setArtist,
    payload: {
      userRn,
      status,
    },
  });
};


const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionKeys.setArtist:
      return {
        ...state,
        ...action.payload,
        lastLoadTime: new Date().toISOString(),
      };
    default:
      return state;
  }
};


export default {
  actions: {
    update,
  },
  reducer: reducer,
};
