import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Stack, TextField, Button, Link} from 'views/components';
import {Translate, translate} from 'views/module';

import Backend from 'utils/backend';

const Email = (props) => {
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const email = props.request.email || '';
  const setRequest = props.setRequest;
  const nextStep = props.nextStep;
  const progressWrap = props.statusProgressWrap;

  const setEmail = (input) => {
    setRequest((e) => {
      return {
        ...e,
        email: input,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!valid) {
      return;
    }

    progressWrap(async () => {
      setLoading(true);
      try {
        await Backend.identity.onboarding.registerEmail(email);
        nextStep();
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    {
      onErrorMessage: (error) => {
        if (error.message === 'email_already_used') {
          return (
            <>
              <Translate expression='onboarding.Signup.Form.1.EmailAlreadyUsed'/>
              <Link
                href={'/auth'}
              >
                <Translate expression='onboarding.Signup.Form.1.ClickHereToLogin'/>
              </Link>
            </>
          );
        }
        return null;
      },
      showProgress: false,
    });
  };

  return (
    <form onSubmit={onSubmit} >
      <Stack spacing={2}>
        <TextField
          name='email'
          autoComplete='email'
          type='email'
          value={email}
          onChange={setEmail}
          label={translate('onboarding.Signup.Form.1.Email')}
          fullWidth
          InputLabelProps={{shrink: true}}
          validation={{
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            update: setValid,
          }}
        />
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          sx={{
            'minHeight': '48px',
            '&:disabled': {
              backgroundColor: '#FB8488',
            },
          }}
          disabled={!valid}
          onClick={onSubmit}
          loading={loading}
        >
          <Typography
            variant='h5'
            sx={{
              color: 'color.white',
              fontWeight: 500,
              my: '4px',
            }}
          >
            <Translate expression='onboarding.Signup.Form.1.Action' />
          </Typography>
        </Button>
      </Stack>
    </form>
  );
};

Email.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  statusProgressWrap: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Email));
