import React from 'react';

import {Helmet} from 'views/module';

import Login from 'views/scenes/auth/authentication/Authentication';

const Authentication = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Authentication</title>
      </Helmet>
      <Login {...props}/>
    </>
  );
};

Authentication.propTypes = {
};

export default React.memo(Authentication);
