import React from 'react';
import PropTypes from 'prop-types';

import {Menu, MenuItem} from '@mui/material';
import {Link} from 'react-router-dom';

const MenuItemLink = (props) => {
  const {href, ...restOfProps} = props;

  return (
    <MenuItem component={Link} to={href} {...restOfProps} />
  );
};
MenuItemLink.propTypes = {
  href: PropTypes.string.isRequired,
};

const MenuMemo = React.memo(Menu);
const MenuItemMemo = React.memo(MenuItem);
const MenuItemLinkMemo = React.memo(MenuItemLink);

export {
  MenuMemo as Menu,
  MenuItemMemo as MenuItem,
  MenuItemLinkMemo as MenuItemLink,
};
