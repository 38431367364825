/* eslint-disable max-len */

export default {
  Title: 'Our terms of service',
  Body: `
  <div>
  <div>
    <h1>Welcome to StudioGrams</h1>
    <p>Welcome to StudioGrams, your go-to destination for creativity and inspiration. By using our website, you're engaging with our services and agreeing to our Terms of Service. This includes all information, tools, and services available to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices mentioned here.</p>
    <h2>Use of Our Site</h2>
    <p>Accessing our site or purchasing from us means you accept our Terms of Service. These terms apply to all site users, including browsers, vendors, customers, and content contributors. Please review these terms carefully before using our site. If you disagree with any part of the terms, you should not access or use our website.</p>
    <h2>Updates to Terms and Services</h2>
    <p>We regularly update and add new features to our store, which will also be subject to these terms. We may modify these Terms of Service at any time, so please review them frequently. Continued use of our site after changes means you accept those changes.</p>
    <h3>Online Terms</h3>
    <p>By using our services, you confirm you're at least the age of majority in your jurisdiction and allow any of your minor dependents to use this site under your supervision.</p>
    <h3>General Conditions</h3>
    <p>We reserve the right to refuse service to anyone at any time for any reason. Your content may be transferred unencrypted over various networks. We do not permit the reproduction, duplication, or resale of any part of our service without explicit permission.</p>
    <h3>Information Accuracy</h3>
    <p>We strive for accuracy but cannot guarantee that all site information is current or complete. Relying solely on our site's material is at your own risk.</p>
    <h3>Service and Price Modifications</h3>
    <p>Our product prices can change without notice. We also reserve the right to modify or discontinue the Service at any time.</p>
    <h3>Products or Services</h3>
    <p>Some products or services may be available exclusively online and are subject to our Return Policy. We cannot guarantee that your computer monitor's display will accurately reflect the color of our products.</p>
    <h3>Billing and Account Information</h3>
    <p>We reserve the right to refuse or cancel orders at our discretion. You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store.</p>
    <h3>Optional Tools</h3>
    <p>We may offer access to third-party tools over which we have no control. Use of these tools is entirely at your own risk.</p>
    <h3>Third-Party Links</h3>
    <p>Our service may include materials from third parties. We are not responsible for their content or accuracy. Any purchase made through third-party websites is at your own risk.</p>
    <h3>User Comments and Feedback</h3>
    <p>We may use your comments and feedback without restriction. We are not obligated to maintain any comments in confidence, pay for comments, or respond to any comments.</p>
    <h3>Personal Information</h3>
    <p>Your submission of personal information through our store is governed by our Privacy Policy.</p>
    <h3>Prohibited Uses</h3>
    <p>You are prohibited from using the site or its content for unlawful purposes, to solicit others to perform unlawful acts, or to violate any laws in your jurisdiction.</p>
    <h3>Disclaimer of Warranties; Limitation of Liability</h3>
    <p>We do not guarantee that your use of our service will be uninterrupted, timely, secure, or error-free. Our liability is limited to the maximum extent permitted by law.</p>
    <h3>Indemnification</h3>
    <p>You agree to indemnify and hold harmless StudioGrams and our affiliates from any claim or demand, including reasonable attorneys’ fees.</p>
    <h3>Severability</h3>
    <p>If any provision of these Terms is held to be unlawful, void, or unenforceable, that provision shall be deemed severable and shall not affect the validity and enforceability of remaining provisions.</p>
    <h3>Termination</h3>
    <p>These Terms are effective unless terminated by either you or us. You can terminate these Terms at any time by notifying us that you no longer wish to use our Services.</p>
    <h3>Entire Agreement</h3>
    <p>These Terms constitute the entire agreement between you and us and govern your use of the Service.</p>
    <h3>Governing Law</h3>
    <p>These Terms are governed by the laws of British Columbia and Canada.</p>
    <h3>Changes to Terms of Service</h3>
    <p>We reserve the right to update these Terms at any time. Your continued use of our website after changes means you accept those changes.</p>
    <h3>Contact Information</h3>
    <p>For questions about these Terms, please contact us at <a href="mailto:info@StudioGrams.dev">info@StudioGrams.dev</a>.</p>
  </div>
</div>
  `,

};
