
export default {
  Card: {
    Status: {
      Title: 'Status: ',
    },
    CreatedAt: {
      Title: 'Created on: ',
    },
    Title: {
      UnMarked: 'Unmarked',
    },
  },
  Types: {
    MyContract: {
      Title: 'My contracts',
      Action: 'Start a new contract',
    },
    MyGig: {
      Title: 'My gigs',
      Action: 'View open requests',
    },
    OpenRequest: {
      Title: 'Open requests',
      Action: 'View my gigs',
    },
  },
  Filter: {
    RequestDesign: 'Request design',
  },
};
