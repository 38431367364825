import React from 'react';

import {useLocation, matchPath} from 'react-router-dom';

import Bowser from 'bowser';

import api from 'utils/backend/';

const BROWSER = Bowser.getParser(window.navigator.userAgent);

const WebpageAnalyticsListener = () => {
  const location = useLocation();

  // List of app routes that include parameters
  const routesWithParams = [
    '/offer/:offerRn',
  ];

  React.useEffect(() => {
    // Define an async function inside the useEffect
    const fetchData = async () => {
      try {
        const matchedRoute = routesWithParams.find((routePath) => matchPath(routePath, location.pathname));

        let path = null;
        if (matchedRoute) {
          path = matchedRoute;
        } else {
          path = location.pathname;
        }

        const system = {
          browser: `${BROWSER.getBrowserName(true)} ${BROWSER.getBrowserVersion()}`,
          platform: BROWSER.getPlatformType(true),
          operatingSystem: `${BROWSER.getOSName(true)} ${BROWSER.getOSVersion()}`,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        };

        const data = {
          system: system,
          webpageData: {
            path,
          },
        };

        // call API
        await api.analytics.sendWebpageAnalytics(data);
      } catch (e) {
        // Handle error
        console.error(e);
      }
    };

    fetchData();
  }, [location]);


  return null;
};

export default WebpageAnalyticsListener;
