import React from 'react';

import {useLocation} from 'react-router-dom'; // Import useLocation hook from React Router

import ReactGA from 'react-ga4';

const GoogleAnalytics = () => {
  const googleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS;

  const location = useLocation();

  React.useEffect(() => {
    if (googleAnalyticsKey) {
      ReactGA.initialize('G-EHYXZ1JY8Y');
    }
  }, []);

  React.useEffect(() => {
    if (googleAnalyticsKey) {
      ReactGA.send({hitType: 'pageview', page: location.pathname + location.search});
    }
  }, [location]);

  return null;
};

export default React.memo(GoogleAnalytics);
