import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from 'views/components';

import {Translate} from 'views/module';

import Contract from './contract/Contract';

function ContractSettings(props) {
  const {
    panel,
    setPanel,
  } = props;

  const offerContracts = props.offerContracts;

  const handleChange = (panel) => (event, isExpanded) => {
    setPanel(isExpanded ? panel : '');
  };

  if (!offerContracts.length) {
    return null;
  }

  return (
    <Accordion
      expanded={panel === 'CONTRACT'}
      onChange={handleChange('CONTRACT')}
      disableGutters
    >
      <AccordionSummary sx={{
        'borderBottom': '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Typography sx={{textTransform: 'uppercase', fontWeight: 500, color: 'black'}}>
          <Translate expression='market.Contract.Settings.Contract.Title' />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{
        py: '4px',
        overflow: 'auto',
      }}>
        <Contract
          {...props}
          offerContract={offerContracts[0]}
        />
      </AccordionDetails>
    </Accordion>
  );
}
ContractSettings.propTypes = {
  panel: PropTypes.string.isRequired,
  setPanel: PropTypes.func.isRequired,
  offerContracts: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ContractSettings));
