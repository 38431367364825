/* eslint-disable max-len */
export default {
  Welcome: {
    Title: `Imagine. Enhance. Impress.`,
    TitleSub: `Your photos deserve the best.`,
    Body: `
Connect with top Photoshop experts and transform your vision into reality. Right here. Right now.
    `,
    CallToAction: {
      WhatDoYouWant: `What do you need designed?`,
      Action: `Get started`,
      Popular: {
        Title: `Popular: `,
        Business: `Business`,
        Creative: `Creative`,
        Restortation: `Restortation`,
        Enhancement: `Enhancement`,
      },
    },
  },
  Explain: {
    Title: `Get in Touch With an Expert in Seconds`,
    Body: `We offer multiple amazing ways to work with our community of professional designers.`,
    ASSIGNED: {
      Title: 'Expert Assignment',
      Body: `Sit back and relax; we've got this. We pair you with an artist who's not just talented but the perfect fit for your project's vibe and vision. It's like matchmaking for your masterpiece—no fuss, all finesse.`,
    },
    DIRECT_HIRE: {
      Title: 'Pick Your Picasso <br /> (Coming soon)',
      Body: `Step into the spotlight and take the reins. Our gallery of gifted artists is at your fingertips, waiting for you to find your ideal creative partner. Style, skill, spirit—it's your call.`,
    },
    CONTEST: {
      Title: 'Creative Contest <br /> (Coming soon)',
      Body: `Challenge our artists in a creative showdown where your vision is the prize. Watch as unique interpretations of your idea battle it out in a spectacle of creativity, and crown the champion.`,
    },
    Action: `Kickstart Your Project Today - Seize the Artistic Moment!`,
  },
};
