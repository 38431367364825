import React from 'react';

import {Helmet} from 'views/module';

import SignUpScene from 'views/scenes/auth/sign_up/SignUp';

const SignUp = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Sign up</title>
      </Helmet>
      <SignUpScene {...props}/>
    </>
  );
};

SignUp.propTypes = {
};

export default React.memo(SignUp);
