import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Paper, Typography} from 'views/components';
import {ImageHorizontalList} from 'views/components';

import {Avatar} from 'views/module';

import ChatType from './chat_type';

const ShowOtherMessage = (props) => {
  const message = props.message;
  const contract = props.contract;
  const offerOrder = props.offerOrder;
  const showTime = props.showTime;

  const requesterRn = message.requesterRn;
  const fileUrls = message.fileUrls || [];
  const createdAt = new Date(message.createdAt);

  let user = null;

  if (requesterRn === contract.user.userRn) {
    user = contract.user;
  } else if (requesterRn === offerOrder.requester.userRn) {
    user = offerOrder.requester;
  }


  const images = fileUrls.map((e) => {
    return {
      imageUrl: e,
    };
  });

  const time = `${createdAt.getHours().toString().padStart(2, '0')}:${createdAt.getMinutes().toString().padStart(2, '0')}`;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 1,
        mr: {lg: 16, md: 8, xs: 4},
      }}>
      <Avatar
        userRn={requesterRn}
        detail={user}
        sx={{
          mr: 1,
        }}
      />
      <Paper
        sx={{
          padding: 1,
          backgroundColor: 'lightgrey',
        }}
      >
        <Box>
          <ImageHorizontalList
            images={images}
            imageWidth='160px'
          />
        </Box>
        <ChatType
          {...props}
          message={message}
        />
      </Paper>
      {
        showTime &&
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography
            variant="caption"
          >
            {time}
          </Typography>
        </Box>
      }
    </Box>
  );
};

ShowOtherMessage.propTypes = {
  message: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  offerOrder: PropTypes.object.isRequired,
  showTime: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ShowOtherMessage));


