import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, Box, Stack} from 'views/components';
import {Typography, Button, ImageCarousel} from 'views/components';

import {Translate} from 'views/module';
import {useNavigate} from 'views/module';

const Home = (props) => {
  const navigate = useNavigate();

  const user = props.user;

  const quickAction = () => {
    if (user?.userRn) {
      navigate(`/offer/new`);
    } else {
      navigate(`/offer/new/guest`);
    }
  };

  return (
    <Grid
      container
      spacing={{md: 8, xs: 1}}
    >
      <Grid item md={6} xs={12}
        sx={{
          my: {md: '32px'},
        }}
      >
        <Stack spacing={2}
          sx={{
            pt: {md: '32px', xs: '16px'},
            pb: {md: '60px', xs: '16px'},
          }}
        >
          <Box>
            <Typography
              variant='h2'
              sx={{
                background: 'linear-gradient(270deg, rgb(122, 190, 250) 0%, rgb(120, 134, 240) 100%)',
                backgroundClip: 'text',
                color: 'transparent',
                fontWeight: 700,
                fontSize: {md: '2.5rem', xs: '2rem'},
              }}
            >
              <Translate expression='home.Welcome.Title' />
            </Typography>
            <Typography
              variant='h6'
              sx={{
                my: 1,
              }}
            >
              <Translate expression='home.Welcome.TitleSub' />
            </Typography>
            <Typography variant='h5'
              sx={{
                mt: 2,
              }}
            >
              <Translate expression='home.Welcome.Body' />
            </Typography>
          </Box>
          <Box>
            <Grid container
              spacing={1}
              sx={{
              }}
            >
              <Grid item md={6} xs={12} >
                <Button
                  fullWidth
                  color={'secondary'}
                  sx={{
                    height: '95%',
                    mt: '1px',
                    borderRadius: '40px',
                    py: 1,
                  }}
                  onClick={() => quickAction()}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 'medium',
                    }}
                  >
                    <Translate expression='home.Welcome.CallToAction.Action' />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid item md={6} sx={{display: {md: 'block', xs: 'none'}}}>
        <ImageCarousel
          images={
            [
              './images/cat.png',
              './images/friends.jpg',
              './images/grass.jpg',
              './images/love.jpg',
              './images/house.jpg',
            ]
          }
        />
      </Grid>
    </Grid>
  );
};

Home.propTypes = {
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(Home));

