import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Grid, Typography} from 'views/components';

import {Translate} from 'views/module';

const Header = (props) => {
  const offerOrder = props.offerOrder;

  const category = offerOrder.category || {};
  const type = category.type;

  return (
    <Grid container>
      <Grid item md={9} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
        <Typography
          variant={'h5'}
          sx={{textTransform: 'uppercase', fontWeight: 'bold'}}
          align='left'
        >
          <Translate expression={`market.Types.${type}.Title`}/>
        </Typography>
      </Grid>
    </Grid>
  );
};
Header.propTypes = {
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Header));
