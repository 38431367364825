import React from 'react';
// import PropTypes from 'prop-types';

import {Box} from 'views/components';

import Welcome from './scenes/Welcome';
import Explain from './scenes/Explain';

const Home = (props) => {
  return (
    <Box
      sx={{
        my: '40px',
      }}
    >
      <Box
        sx={{
          mt: {mx: '40px', xs: '8px'},
          mb: {mx: '80px', xs: '8px'},
        }}
      >
        <Welcome />
      </Box>
      <Box
        sx={{
          my: '40px',
        }}
      >
        <Explain />
      </Box>
    </Box>
  );
};

Home.propTypes = {
};

export default Home;
