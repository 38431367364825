import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {RequireAuthentication} from 'views/module';
import {Helmet} from 'views/module';

import MyRequestScene from 'views/scenes/market/list/my_request/MyRequest';

const MyRequest = (props) => {
  const [reload, setReload] = React.useState(0);

  return (
    <>
      <Helmet>
        <title>StudioGrams: My requests</title>
      </Helmet>
      <RequireAuthentication>
        <MyRequestScene
          reload={reload}
          setReload={setReload}
        />
      </RequireAuthentication>
    </>
  );
};

MyRequest.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(MyRequest));

