import * as React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography} from 'views/components';
import {IconButton, Menu, MenuItemLink} from 'views/components';

import {Translate} from 'views/module';

import {Menu as MenuIcon} from 'views/icons';

const Category = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const artist = props.artist;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: 'block',
        }}
      >
        <MenuItemLink
          href={'/offer/list/my_requests'}
          onClick={handleCloseNavMenu}
        >
          <Typography textAlign="center">
            <Translate expression={'navbar.Category.MyRequests'} />
          </Typography>
        </MenuItemLink>

        {
          artist?.status === 'ACTIVE' &&
          <MenuItemLink
            href={'/offer/list/my_gigs'}
            onClick={handleCloseNavMenu}
          >
            <Typography textAlign="center">
              <Translate expression={'navbar.Category.MyGigs'} />
            </Typography>
          </MenuItemLink>
        }
        {
          artist?.status !== 'ACTIVE' &&
          <MenuItemLink
            href={'/artist/signup'}
            onClick={handleCloseNavMenu}
          >
            <Typography textAlign="center">
              <Translate expression={'navbar.Category.BecomeArtist'} />
            </Typography>
          </MenuItemLink>
        }
      </Menu>
    </>
  );
};
Category.propTypes = {
  artist: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  artist: state.artist,
});

export default connect(mapStateToProps, dispatch)(React.memo(Category));
