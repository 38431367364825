import React from 'react';
import PropTypes from 'prop-types';

import {Stack, Card, Box, Button} from 'views/components';
import {CircularProgress} from 'views/components';
import {Alert} from '@mui/material';

import {Translate} from '../Translate';

const CardTable = (props) => {
  const {
    data,
    card: CardRender,
    isLoading,
    loadMore,
    hasMore,
  } = props;

  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
      }}
    >
      {
        data.map((e, index) => {
          return (
            <Card
              key={index}
              sx={{
                width: '100%',
              }}
            >
              <CardRender
                item={e}
              />
            </Card>
          );
        })
      }
      {
        !isLoading && !data.length &&
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="info" sx={{mt: 2}}>
            <Translate expression='generic.NoResult'/>
          </Alert>
        </Box>
      }
      {
        isLoading &&
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      }
      {
        hasMore &&
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant='contained'
            onClick={loadMore}
            loading={isLoading}
          >
            <Translate expression='generic.LoadMore'/>
          </Button>
        </Box>
      }
    </Stack>
  );
};
CardTable.propTypes = {
  data: PropTypes.array.isRequired,
  card: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default React.memo(CardTable);
