import React from 'react';
import PropTypes from 'prop-types';
import MuiDivider from '@mui/material/Divider';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Divider = (props) => {
  const {orientation} = props;
  const theme = useTheme();

  const breakpointOrder = ['xl', 'lg', 'md', 'sm', 'xs'];

  // Define breakpoints outside of the render method
  const breakpoints = {
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  let dividerOrientation = 'horizontal'; // default orientation

  if (typeof orientation === 'string') {
    dividerOrientation = orientation;
  } else if (typeof orientation === 'object') {
    // Determine the screen size and pick the appropriate orientation
    for (const bp of breakpointOrder) {
      if (breakpoints[bp] && orientation[bp]) {
        dividerOrientation = orientation[bp];
        break;
      }
    }
  }

  return (
    <MuiDivider {...props} orientation={dividerOrientation} />
  );
};

Divider.propTypes = {
  orientation: PropTypes.oneOfType([
    PropTypes.oneOf(['vertical', 'horizontal']),
    PropTypes.objectOf(PropTypes.oneOf(['vertical', 'horizontal'])),
  ]),
};

export default Divider;
