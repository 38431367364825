import React from 'react';
import PropTypes from 'prop-types'; ;

import {Grid, Typography, Paper, Container, screenSizeSmaller} from 'views/components';

import {translate} from 'views/module';
import {Helmet} from 'views/module';

function InternalServerError(props) {
  const [showErrorLog, setShowErrorLog] = React.useState(false);

  const smallSize = screenSizeSmaller('sm');

  const error = props.error;

  // on 'e' toggle error log
  React.useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === 'e') {
        setShowErrorLog((prev) => !prev);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  if (showErrorLog) {
    return (
      <Container maxWidth={false} disableGutters sx={{'backgroundColor': 'background.main'}}>
        <Container
          className="App"
          disableGutters
          maxWidth={false}
          sx={{maxWidth: '1440px'}}
        >
          <Paper
            elevation={0}
            sx={
              {
                'padding': smallSize ? '4px' : '16px',
                'minHeight': smallSize ? '100vh' : '95vh',
                'backgroundColor': 'background.main',
              }
            }
          >
            <Paper sx={{mt: {xs: '20px', sm: '40px'}, mx: {xs: '16px', sm: 0}, py: '24px', px: {xs: '16px', sm: 0}}}>
              <Grid container spacing={2} sx={{p: 1}}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {translate('error.ErrorMessage')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                      <Typography>
                        {error.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {translate('error.StackTrace')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                      <Typography component='div'>
                        <pre style={{fontFamily: 'inherit'}}>
                          {error.stack}
                        </pre>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Container >
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>StudioGrams: Internal Server Error</title>
      </Helmet>
      <Container maxWidth={false} disableGutters sx={{'backgroundColor': 'background.main'}}>
        <Container
          className="App"
          disableGutters
          maxWidth={false}
          sx={{maxWidth: '1440px'}}
        >
          <Paper
            elevation={0}
            sx={
              {
                'padding': smallSize ? '4px' : '16px',
                'minHeight': smallSize ? '100vh' : '95vh',
                'backgroundColor': 'background.main',
              }
            }
          >
            <Paper sx={{mt: {xs: '20px', sm: '40px'}, mx: {xs: '16px', sm: 0}, py: '24px', px: {xs: '16px', sm: 0}}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                  <Typography sx={{
                    fontSize: '64px',
                    fontWeight: 900,
                    color: 'primary.main',
                  }}>
                    {translate('error.OopsSomethingWentWrong')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent='center' textAlign='left'>
                    <Grid item>
                      <Typography sx={{
                        textAlign: 'center',
                        fontSize: '32px',
                      }}>
                        {translate('error.HereAreSteps')}
                      </Typography>
                      <ol>
                        <li key={0}>
                          <Typography>
                            {translate('error.RefreshThePage')}
                          </Typography>
                        </li>
                        <li key={1}>
                          <Typography>
                            <a href='/signout'>{translate('error.SignOut')}</a> {translate('error.AndSignInAgain')}
                          </Typography>
                        </li>
                        <li key={2}>
                          <Typography>
                            {translate('error.PleaseComeBackLater')}
                          </Typography>
                        </li>
                        <li key={3}>
                          <Typography>
                            {translate('error.PleaseContact')} <a href={`mailto:info@studiograms.com`}>info@studiograms.com</a>
                          </Typography>
                        </li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Container >
      </Container>
    </>
  );
}
InternalServerError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default React.memo(InternalServerError);
