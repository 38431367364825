import React from 'react';
import PropTypes from 'prop-types';
import MButton from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import {Link} from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';

const Button = (props) => {
  const input = {...props};
  if (input.loading) {
    return (
      <LoadingButton
        loading
        variant={props.variant || 'contained'}
        fullWidth={input.fullWidth}
        loadingPosition="start"
        startIcon={<SendIcon />}
      >
        {input.label || input.children}
      </LoadingButton>
    );
  } else {
    delete input.loading;
    if (input.href) {
      return (
        <MButton
          {...input}
          ref={input.anchorref}
          component={Link}
          color={input.color || 'primary'}
          onClick={input.onClick}
          disabled={input.disabled}
          variant={props.variant || 'contained'}
          fullWidth={input.fullWidth}
          to={input.href}
          sx={props.sx}
        >
          {input.label || input.children}
        </MButton>
      );
    } else {
      return (
        <MButton
          {...input}
          ref={input.anchorref}
          color={input.color || 'primary'}
          onClick={input.onClick}
          disabled={input.disabled}
          variant={props.variant || 'contained'}
          fullWidth={input.fullWidth}
          sx={props.sx}
        >
          {input.label || input.children}
        </MButton>
      );
    }
  }
};

Button.propTypes = {
  anchorref: PropTypes.object,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

export {
  Button,
  ButtonGroup,
};
