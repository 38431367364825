
const hashCode = function(s) {
  const num = s.split('')
      .reduce((a, b) => {
        a=((a<<5)-a)+b.charCodeAt(0);
        return a&a;
      }, 0);
  return Math.abs(num);
};

const userColours = (userRn) => {
  return colours[hashCode(userRn) % colours.length];
};

export {
  userColours,
};

const colours = [
  '#B36847',
  '#B38847',
  '#B3A847',
  '#9DB347',
  '#7DB347',
  '#5DB347',
  '#47B352',
  '#47B372',
  '#47B392',
  '#47B3B3',
  '#4792B3',
  '#4772B3',
  '#4752B3',
  '#5D47B3',
  '#7D47B3',
  '#9D47B3',
  '#B347A8',
  '#B34788',
  '#B34768',
  '#B34747',
];
