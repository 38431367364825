import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function Image({src, alt, sx}) {
  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        maxWidth: '100%',
        height: 'auto',
        ...sx,
      }}
    />
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  sx: PropTypes.object,
};

Image.defaultProps = {
  sx: {},
};

export default Image;
