import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, Box} from 'views/components';

import DesignCategory from './scenes/design_category/DesignCategory';
import EngagementProcess from './scenes/engagement_process/EngagementProcess';
import ProjectDetail from './scenes/project_detail/ProjectDetail';
import Payment from './scenes/payment/Payment';
import Submit from './scenes/submit/Submit';

const OfferOrder = (props) => {
  const [step, setStep] = React.useState(0);

  const offerOrder = props.offerOrder;

  const steps = [
    DesignCategory,
    EngagementProcess,
    ProjectDetail,
    Payment,
    Submit,
  ];

  React.useEffect(() => {
    if (offerOrder?.category?.type) {
      setStep(2);
    }
  }, []);

  return (
    <Stack
      spacing={4}
      sx={{
        m: {lg: 5, xs: 1},
      }}
    >
      {
        steps
            .map((Scene, index) => {
              return (
                <Box key={index}>
                  <Scene
                    {...props}
                    view={index === step ? 'EDIT' : 'VIEW'}
                    nextStep={() => setStep((e) => e + 1)}
                    goTo={() => setStep(index) }
                    currentStep={step}
                    index={index}
                  />
                </Box>
              );
            })
      }
    </Stack>
  );
};

OfferOrder.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  updateOrderCategory: PropTypes.func.isRequired,
  updateOrderEngagementProcess: PropTypes.func.isRequired,
  updateOrderPayment: PropTypes.func.isRequired,
  updateOrderDescription: PropTypes.func.isRequired,
  addOrderFileWithSignedUrl: PropTypes.func.isRequired,
  editOrderFile: PropTypes.func.isRequired,
  deleteOrderFile: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, dispatch)(React.memo(OfferOrder));
