import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

// Define a variable to store the singleton instance
let userPoolInstance = null;

const poolData = {
  UserPoolId: process.env.REACT_APP_CONGITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CONGITO_CLIENT_ID,
};

// Define a function to get the singleton instance
const getUserPool = () => {
  // If the instance does not exist, create it using the pool data
  if (userPoolInstance === null) {
    // Import the CognitoUserPool class

    // Define the user pool and the user pool client id
    // Create a user pool object
    userPoolInstance = new CognitoUserPool(poolData);
  }
  // Return the instance
  return userPoolInstance;
};

export {
  getUserPool,
  CognitoUser,
  AuthenticationDetails,
};
