import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Stack, TextField, Button} from 'views/components';
import {Translate, translate} from 'views/module';


const Name = (props) => {
  const [firstNameValid, setFirstNameValid] = React.useState(false);
  const [lastNameValid, setLastNameValid] = React.useState(false);

  const request = props.request;
  const setRequest = props.setRequest;
  const nextStep = props.nextStep;

  const firstName = request.user.firstName || '';
  const lastName = request.user.lastName|| '';

  const setFirstName = (input) => {
    setRequest((e) => {
      return {
        ...e,
        user: {
          ...e.user,
          firstName: input,
        },
      };
    });
  };
  const setLastName = (input) => {
    setRequest((e) => {
      return {
        ...e,
        user: {
          ...e.user,
          lastName: input,
        },
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!(firstNameValid && lastNameValid)) {
      return;
    }

    nextStep();
  };

  return (
    <form onSubmit={onSubmit} >
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            name='fname'
            value={firstName}
            onChange={setFirstName}
            label={translate('onboarding.Signup.Form.3.FirstName')}
            fullWidth
            InputLabelProps={{shrink: true}}
            validation={{
              regex: /^[\w ]{1,50}$/,
              update: setFirstNameValid,
            }}
            autoFocus
          />
          <TextField
            name='lname'
            value={lastName}
            onChange={setLastName}
            label={translate('onboarding.Signup.Form.3.LastName')}
            fullWidth
            InputLabelProps={{shrink: true}}
            validation={{
              regex: /^[\w ]{1,50}$/,
              update: setLastNameValid,
            }}
          />
        </Stack>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          sx={{
            'minHeight': '48px',
            '&:disabled': {
              backgroundColor: '#FB8488',
            },
          }}
          disabled={!(firstNameValid && lastNameValid)}
          onClick={onSubmit}
          type="submit"
        >
          <Typography
            variant='h5'
            sx={{
              color: 'color.white',
              fontWeight: 500,
              my: '4px',
            }}
          >
            <Translate expression='onboarding.Signup.Form.3.Action' />
          </Typography>
        </Button>
      </Stack>
    </form>
  );
};
Name.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};


export default Name;
