import * as React from 'react';
// import PropTypes from 'prop-types';

import {Box, Typography, Container} from 'views/components';
import {AppBar, Toolbar, Link} from 'views/components';

import {Translate} from 'views/module';

import {StudioGrams} from 'views/icons';

import Profile from './Profile';
import Category from './Category';

const Navbar = (props) => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl" disableGutters
        sx={{
          px: 10,
        }}
      >
        <Toolbar disableGutters variant="dense">
          <Link
            href="/"
          >
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'left',
                display: 'flex',
                flex: 1,
              }}
            >
              <StudioGrams
                sx={{
                  width: '32px',
                  height: '32px',
                }}
              />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  textDecoration: 'none',
                  color: 'color.white',
                }}
              >
                <Translate expression='navbar.Company' />
              </Typography>
            </Box>
          </Link>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'right',
              display: 'flex',
              flex: 1,
            }}
          >
            <Category />
            <Profile />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Navbar.propTypes = {
};

export default React.memo(Navbar);
