import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Grid, LargeButtons} from 'views/components';

import {Translate, translate} from 'views/module';

const EngagementProcess = (props) => {
  const offerOrder = props.offerOrder;
  const nextStep = props.nextStep;

  const engagementProcess = offerOrder.engagementProcess;

  const updateOrderEngagementProcess = props.updateOrderEngagementProcess;

  React.useEffect(() => {
    if (!engagementProcess) {
      updateType('ASSIGNED');
    }
  }, []);

  const updateType = (newEngagementProcess) => {
    updateOrderEngagementProcess(newEngagementProcess);
    nextStep();
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <LargeButtons
            onClick={() => updateType('ASSIGNED')}
            title={<Translate expression='market.Order.EngagementProcess.ASSIGNED.Title' />}
            description={translate('market.Order.EngagementProcess.ASSIGNED.Body')}
            selected={engagementProcess === 'ASSIGNED'}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LargeButtons
            onClick={() => updateType('DIRECT_HIRE')}
            title={<Translate expression='market.Order.EngagementProcess.DIRECT_HIRE.Title' />}
            description={translate('market.Order.EngagementProcess.DIRECT_HIRE.Body')}
            selected={engagementProcess === 'DIRECT_HIRE'}
            disabled={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LargeButtons
            onClick={() => updateType('CONTEST')}
            title={<Translate expression='market.Order.EngagementProcess.CONTEST.Title' />}
            description={translate('market.Order.EngagementProcess.CONTEST.Body')}
            selected={engagementProcess === 'CONTEST'}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

EngagementProcess.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  updateOrderEngagementProcess: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(EngagementProcess));
