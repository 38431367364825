import lang from 'lang';

const deepRead = (data, expression) => {
  const exp = expression.split('.');

  let retVal;
  do {
    retVal = (retVal || data)[exp.shift()];
  } while (retVal && exp.length);

  return retVal;
};

const translate = (locale, expression, substitute) => {
  let langPack = lang[locale];
  if (!langPack) {
    langPack = lang['en'];
  }

  let result = deepRead(langPack, expression);
  if (result === null || result === undefined) {
    return 'Missing translation';
  }

  if (substitute && typeof substitute === 'object') {
    Object.keys(substitute).forEach((key) => {
      result = result.replace(new RegExp(`\\#\{${key}\}`, 'g'), substitute[key]);
    });
  }

  return result;
};

export default translate;
