import React from 'react';

import {BrowserRouter} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';

import {mainTheme} from './css/theme';

import Site from './pages/Site';
import {store, Provider} from 'utils/state_manager';

import DataSync from 'views/module/data_sync';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <DataSync />
            <Site />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
