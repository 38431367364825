import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Stack, Typography, IconButton} from 'views/components';

import {UploadFileToS3, Translate} from 'views/module';

import {Refresh as RefreshIcon} from 'views/icons';

import ChatWindow from './chat/ChatWindow';
import ChatNewMessage from './chat/ChatNewMessage';


const Correspondence = (props) => {
  const [newMessage, setNewMessage] = React.useState('');
  const [fileUrls, setFileUrls] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(0);
  const [messageToDisplay, setMessageToDisplay] = React.useState([]);

  const offerMessages = props.offerMessages;
  const messageManager = props.messageManager;
  const contract = props.contract;
  const user = props.user;

  const contractRn = contract.contractRn;
  const userRn = user.userRn;

  React.useEffect(() => {
    setMessageToDisplay(
        offerMessages
            .filter((e) => e.contractRn === contractRn)
            .sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateA - dateB;
            }),
    );
  }, [offerMessages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const body = {
        blob: newMessage.trim(),
        editorType: 'TEXT',
      };
      messageManager.send(contractRn, body, userRn, fileUrls);
      setNewMessage('');
      setFileUrls([]);
    }
  };

  const getSignedUrl = async (file) => {
    const result = await messageManager.addFile(contractRn, file);

    const onUpload = () => {
      setFileUrls((e) => [...e, result.fileUrl]);
    };

    return {
      signedUrl: result.signedUrl,
      onUploadComplete: onUpload,
    };
  };

  const removeFileUrls = async (index) => {
    setFileUrls((e) => {
      e.splice(index, 1);
      return [...e];
    });
  };

  const openUploadDialog = () => {
    setOpenDialog((e) => e + 1);
  };
  React.useEffect((e) => {
    setOpenDialog(0);
  }, [openDialog]);

  const refreshMessage = () => {
    messageManager.refresh();
  };

  return (
    <UploadFileToS3
      getSignedUrl={getSignedUrl}
      openDialog={openDialog}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Typography
            variant='h6'
          >
            <Translate expression='market.Contract.Feed.Correspondence.Title'/>
          </Typography>
          <IconButton onClick={refreshMessage}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <ChatWindow
          {...props}
          messages={messageToDisplay}
          contract={contract}
        />
        <ChatNewMessage
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          fileUrls={fileUrls}
          setFileUrls={setFileUrls}
          handleSendMessage={handleSendMessage}
          openUploadDialog={openUploadDialog}
          removeFileUrls={removeFileUrls}
        />
      </Box>
    </UploadFileToS3>
  );
};

Correspondence.propTypes = {
  offerMessages: PropTypes.array.isRequired,
  messageManager: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(Correspondence));

