import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Grid, LargeButtons} from 'views/components';

import {translate} from 'views/module';


const EditDesignCategory = (props) => {
  const [localCustomTitle, setLocalCustomTitle] = React.useState(null);

  const offerOrder = props.offerOrder;
  const nextStep = props.nextStep;

  const category = offerOrder.category || {};
  const updateOrderCategory = props.updateOrderCategory;

  const type = category.type;
  const customTitle = category.customTitle;

  React.useEffect(() => {
    setLocalCustomTitle(customTitle || '');
  }, [customTitle]);

  const updateType = (type) => {
    updateOrderCategory(
        {
          ...category,
          type,
        },
    );
    nextStep();
  };

  const buttonsData = Object.values(translate('market.Types'));

  if (localCustomTitle === null) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={{lg: 4, xs: 2}}>
        {
          buttonsData.map(({Key, Title, Description}, index) => (
            <Grid item md={4} xs={12} key={index}
            >
              <LargeButtons
                onClick={() => updateType(Key)}
                title={Title}
                description={Description}
                selected={type === Key}
              />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
};

EditDesignCategory.propTypes = {
  offerOrder: PropTypes.object.isRequired,
  updateOrderCategory: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(EditDesignCategory));
