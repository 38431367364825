import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import ChatText from './ChatText';
import ChatContractComplete from './ChatContractComplete';

const ChatType = (props) => {
  const message = props.message;

  const body = message.body;

  if (body.editorType === 'TEXT') {
    return (
      <ChatText
        {...props}
      />
    );
  } else if (body.editorType === 'CONTRACT_COMPLETE') {
    return (
      <ChatContractComplete
        {...props}
      />
    );
  }

  return null;
};

ChatType.propTypes = {
  message: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatType));


