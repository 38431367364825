export default {
  OopsSomethingWentWrong: 'Oops! Something went wrong',
  TryAgainLater: 'Try again later',
  HereAreSteps: 'Here are some steps you can take that might help:',
  RefreshThePage: 'Refresh the page',
  SignOut: 'Sign out',
  AndSignInAgain: 'and sign back in again',
  PleaseComeBackLater: 'Come back at a later time; a fix may be on the way.',
  PleaseContact: 'If this issue persists, contact',
  ErrorMessage: 'Error message',
  StackTrace: 'Stack trace',
  PageDoesNotExist: 'Uh oh! This page does not exist',
  CouldNotFindPage: 'Sorry, we could not find the page you are looking for.',
  ReturnToHomePage: 'Return to homepage',
};
