import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Grid, TextField, Button, Link} from 'views/components';
import {Translate, translate} from 'views/module';

import federatedSignIn from 'utils/auth/federated_sign_in';
import {login} from 'utils/auth/login';

const UserAuthenticationLogin = (props) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const [numValid, setNumValid] = React.useState(0);

  const progressWrap = props.statusProgressWrap;
  const identityProvider = [
    'GOOGLE',
  ];
  const resetPassword = props.resetPassword;
  const statusClear = props.statusClear;
  const userReload = props.userReload;
  const onSignIn = props.onSignIn;

  React.useEffect(() => {
    const usernameValid = email.length >= 3 ? 1 : 0;
    const passwordValid = password.length >= 3 ? 1 : 0;
    setNumValid(usernameValid + passwordValid);
  }, [email, password]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (numValid !== 2) {
      return;
    }

    progressWrap(async () => {
      setIsLoggingIn(true);
      try {
        const e = await login(email, password);
        if (e.status === 'NEW_PASSWORD_REQUIRED') {
          resetPassword(e);
        } else {
          userReload();
        }
        statusClear();
        if (onSignIn) {
          onSignIn();
        }
      } finally {
        setIsLoggingIn(false);
      }
    }, {
      onErrorMessage: () => {
        return translate('auth.LoginFail');
      },
    });
  };

  const handleFederatedSignin = (e, identityProviderName) => {
    setIsLoggingIn(true);
    federatedSignIn(identityProviderName);
  };

  return (
    <form onSubmit={onSubmit} noValidate style={{width: '100%'}}>
      <Grid container
        justifyContent="center"
        sx={{
          margin: 'auto',
        }}
      >
        <Grid item xs={12} sx={{pb: '16px'}}>
          <TextField
            id={'email'}
            type={'email'}
            value={email}
            onChange={setEmail}
            label={translate('auth.Signup.UserAuth.Email')}
            fullWidth
            InputLabelProps={{shrink: true}}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <TextField
            type="password"
            value={password}
            onChange={setPassword}
            label={translate('auth.Signup.UserAuth.Password')}
            fullWidth
            InputLabelProps={{shrink: true}}
          />
        </Grid>
        <Grid item xs={12} sx={{pb: '16px'}}>
          <Button
            variant='contained'
            fullWidth
            color='secondary'
            loading={isLoggingIn}
            sx={{
              'minHeight': '48px',
              '&:disabled': {
                backgroundColor: '#FB8488',
              },
            }}
            disabled={!(numValid === 2)}
            onClick={onSubmit}
            type="submit"
          >
            <Typography
              variant='h5'
              sx={{
                color: 'color.white',
                fontWeight: 500,
                my: '4px',
              }}
            >
              <Translate expression='auth.Signup.UserAuth.Login' />
            </Typography>
          </Button>
        </Grid>
        {
          identityProvider.map((value, index) => {
            return (
              <Grid item key={value} xs={12} sx={{pb: '16px'}}>
                <Button
                  variant='outlined'
                  fullWidth
                  color='secondary'
                  sx={{
                    'minHeight': '48px',
                    '&:disabled': {
                      backgroundColor: '#FEC184',
                    },
                  }}
                  loading={isLoggingIn}
                  onClick={(e) => handleFederatedSignin(e, value)}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 500,
                      my: '4px',
                    }}
                  >
                    {`${translate('auth.Signup.UserAuth.LoginWith')} ${value}`}
                  </Typography>
                </Button>
              </Grid>
            );
          })
        }
        <Grid item xs={12} sx={{pb: {md: '16px', xs: '16px'}}}>
          <Typography
            variant='body2'
            sx={{
              textAlign: 'center',
            }}
          >
            <Translate expression='auth.Signup.UserAuth.NewAccount.Create' />
            <Link href={'/signup'}>
              <Translate expression='auth.Signup.UserAuth.NewAccount.GetStarted' />
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{pb: {md: '16px', xs: '16px'}}}>
          <Typography
            variant='body2'
            sx={{
              textAlign: 'center',
            }}
          >
            <Translate expression='auth.Signup.UserAuth.ForgotPassword.Create' />
            <Link href={'/forgot_password'}>
              <Translate expression='auth.Signup.UserAuth.ForgotPassword.GetStarted' />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

UserAuthenticationLogin.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  statusClear: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  userReload: PropTypes.func.isRequired,
  onSignIn: PropTypes.func,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(UserAuthenticationLogin));
