import React from 'react';
import PropTypes from 'prop-types';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';

const StyledDrawer = (props) => {
  const swipeable = props.swipeable;

  const DrawerType = swipeable ? SwipeableDrawer : Drawer;

  let drawerData = {};
  if (swipeable) {
    drawerData = {
      onOpen: props.onOpen,
    };
  }

  const defaultPaperStyle = {
    sx: {
      boxSizing: 'border-box',
      borderRadius: '4px',
      maxWidth: {
        xs: '80vw',
        sm: '456px',
      },
      padding: 5,
    },
  };

  return (
    <DrawerType
      data-testid='styledDrawer'
      {...drawerData}
      PaperProps={props.PaperProps || defaultPaperStyle}
      anchor={props.anchor || 'right'}
      open={props.open}
      onClose={props.onClose}
      sx={props.sx}
      variant={props.variant}
    >
      {props.children}
    </DrawerType>
  );
};
StyledDrawer.propTypes = {
  PaperProps: PropTypes.object,
  anchor: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.object,
  variant: PropTypes.string,
  swipeable: PropTypes.bool,
};

export {StyledDrawer as Drawer};
