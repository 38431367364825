import * as React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Button} from 'views/components';

import {Translate} from 'views/module';

const Category = (props) => {
  const artist = props.artist;

  return (
    <Box sx={{display: 'flex', mx: 2}}>
      <Button
        sx={{
          color: 'white',
          display: 'block',
          mx: 0.5,
        }}
        variant='text'
        href={'/offer/list/my_requests'}
      >
        <Translate expression={'navbar.Category.MyRequests'} />
      </Button>
      {
        artist?.status === 'ACTIVE' &&
        <Button
          sx={{
            color: 'white',
            display: 'block',
            mx: 0.5,
          }}
          variant='text'
          href={'/offer/list/my_gigs'}
        >
          <Translate expression={'navbar.Category.MyGigs'} />
        </Button>
      }
      {
        artist?.status !== 'ACTIVE' &&
        <Button
          sx={{
            color: 'white',
            display: 'block',
            mx: 0.5,
          }}
          variant='text'
          href={'/artist/signup'}
        >
          <Translate expression={'navbar.Category.BecomeArtist'} />
        </Button>
      }
    </Box>
  );
};
Category.propTypes = {
  artist: PropTypes.object,
};
const mapStateToProps = (state) => ({
  artist: state.artist,
});

export default connect(mapStateToProps, dispatch)(React.memo(Category));
