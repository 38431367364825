import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Stack, Typography} from '@mui/material';
import {IconButton} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import moment from 'moment';
const {translate} = require('../../Translate');

const MessageToast = (props) => {
  const [messageToDisplay, setMessageToDisplay] = React.useState(0);

  const toastMessages = props.toastMessages;
  const handleClose = props.handleClose;

  React.useCallback((e) => {
    setMessageToDisplay(0);
  }, [toastMessages]);

  const multiple = toastMessages.length > 1;
  const messageObj = toastMessages[messageToDisplay];

  if (!messageObj) {
    return null;
  }

  const message = messageObj.message;
  const endTime = messageObj.endTime;
  const success = messageObj.status === 'SUCCESS' ? true : false;

  const Icon = success ? CheckCircleOutlineIcon : ErrorIcon;

  const color = success ? 'success.dark' : 'error.dark';

  let timeDiff = translate('snackbar.Now');
  if (moment().diff(moment(endTime), 'seconds') > 44 ) {
    timeDiff = moment(endTime).fromNow();
  }

  const listMessage = `${messageToDisplay + 1} ${translate('snackbar.Of')} ${toastMessages.length}`;

  return (
    <Stack
      sx={{
        width: '380px',
        minHeight: multiple ? '80px' : '50px',
        backgroundColor: 'color.white',
        borderRadius: '4px',
        borderLeft: `solid 8px`,
        borderColor: color,
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          height: multiple ? '60%' : '100%',
          my: 'auto',
        }}
      >
        <Icon
          sx={{
            my: 'auto',
            mx: '16px',
            color: color,
          }}
        />
        <Typography
          sx={{
            my: 'auto',
            mr: 'auto',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '1.0rem',
          }}
        >
          {message}
        </Typography>
        <IconButton

          sx={{
            ml: 'auto',
            my: 'auto',
            mr: '16px',
            color: 'grey.700',
          }}
          onClick={handleClose}>
          <CloseIcon
          />
        </IconButton>
      </Stack>
      {
        multiple &&
        <Stack
          direction={'row'}
          sx={{
            height: multiple ? '40%' : '100%',
          }}
        >
          <Typography
            sx={{
              my: 'auto',
              ml: '60px',
              color: 'grey.600',
            }}
          >
            {timeDiff}
          </Typography>
          <Typography
            sx={{
              my: 'auto',
              ml: 'auto',
              mr: '20px',
              color: 'color.black',
            }}
          >
            {listMessage}
            <IconButton
              size={'small'}
              sx={{
                p: '0px',
              }}
              onClick={() => setMessageToDisplay((e) => Math.max(e - 1, 0))}>
              <NavigateBeforeIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  my: 'auto',
                }}
              />
            </IconButton>
            <IconButton
              size={'small'}
              sx={{
                p: '0px',
              }}
              onClick={() => setMessageToDisplay((e) => Math.min(e + 1, toastMessages.length - 1))}>
              <NavigateNextIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  my: 'auto',
                }}
              />
            </IconButton>

          </Typography>
        </Stack>
      }
    </Stack>
  );
};
MessageToast.propTypes = {
  handleClose: PropTypes.func.isRequired,
  toastMessages: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(MessageToast));
