import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {RequireAuthentication} from 'views/module';
import {Helmet} from 'views/module';

import MyGigScene from 'views/scenes/market/list/my_gig/MyGig';

const MyGig = (props) => {
  const [reload, setReload] = React.useState(0);

  return (
    <>
      <Helmet>
        <title>StudioGrams: My gigs</title>
      </Helmet>
      <RequireAuthentication>
        <MyGigScene
          reload={reload}
          setReload={setReload}
        />
      </RequireAuthentication>
    </>
  );
};

MyGig.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(MyGig));

