import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Grid} from 'views/components';
import {Translate, useNavigate} from 'views/module';

import UserAuthenticationLogin from './scenes/UserAuthenticationLogin';
import UserAuthenticationResetPassword from './scenes/UserAuthenticationResetPassword';

const Authentication = (props) => {
  const [stage, setStage] = React.useState('LOGIN');
  const [userData, setUserData] = React.useState(null);

  const navigate = useNavigate();

  let display = null;
  let title = null;
  let body = null;

  if (stage === 'LOGIN') {
    display = (
      <UserAuthenticationLogin
        resetPassword={(e) => {
          setUserData(e);
          setStage('RESET');
        }}
        onSignIn={() => {
          navigate('/');
        }}
      />
    );

    title = (
      <Translate expression='auth.Signup.Welcome' />
    );

    body = (
      <Translate expression='auth.Signup.Signin' />
    );
  } else if (stage === 'RESET') {
    display = (
      <UserAuthenticationResetPassword
        userData={userData}
        onSignIn={() => {
          navigate('/');
        }}
      />
    );

    title = (
      <Translate expression='auth.Signup.UserAuth.ResetPassword.Title' />
    );

    body = (
      <Translate expression='auth.Signup.UserAuth.ResetPassword.Body' />
    );
  }


  return (
    <Grid container
      justifyContent="center"
      sx={{
        p: {md: '80px', xs: '16px'},
        width: {lg: '640px'},
        px: {md: '8px', xs: '16px'},
        margin: 'auto',
      }}
    >
      <Grid item xs={12} >
        <Typography
          variant='h3'
          sx={{
            mb: '4px',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: {md: '24px', xs: '16px'}}}>
        <Typography
          variant='body1'
        >
          {body}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{pb: '16px'}}>
        <hr style={{border: '1px solid #E5E5E5'}}/>
      </Grid>
      <Grid item xs={12}>
        {display}
      </Grid>
    </Grid>
  );
};

Authentication.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Authentication));
