import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import ArtistSync from './ArtistSync';
import UserSync from './UserSync';
import OAuth from './OAuth';
import StatusAnalyticsListener from './StatusAnalyticsListener';
import GoogleAnalytics from './GoogleAnalytics';
import WebpageAnalyticsListener from './WebpageAnalyticsListener';
import RedditPixel from './RedditPixel';

const DataSync = (props) => {
  return (
    <>
      <ArtistSync />
      <UserSync />
      <OAuth />
      <StatusAnalyticsListener />
      <GoogleAnalytics />
      <WebpageAnalyticsListener/>
      <RedditPixel />
    </>
  );
};

DataSync.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(DataSync);
