import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Dialog, DialogTitle, DialogContent, DialogActions} from 'views/components';
import {Button, Typography} from 'views/components';

import {Translate} from 'views/module';
import {useNavigate, useQuery} from 'views/module';

import backend from 'utils/backend';

const NewOffer = (props) => {
  const [showDuplicate, setShowDuplicate] = React.useState(false);
  const [duplicateRn, setDuplicateRn] = React.useState(false);

  const progressWrap = props.statusProgressWrap;
  const user = props.user;

  const navigate = useNavigate();
  const query = useQuery();

  const category = query?.category;

  const createNewOffer = async () => {
    const offer = {
      requesterRn: user.userRn,
    };
    const result = await backend.offer.create(offer);
    const offerRn = result.offerRn;

    if (category) {
      const categoryParams = {
        type: category,
      };
      await backend.offer.order.updateCategory(offerRn, categoryParams);
    }

    return offerRn;
  };

  React.useEffect(() => {
    progressWrap(async () => {
      const filters = [{type: 'eq', key: 'status', value: 'DRAFT'}];
      if (category) {
        filters.push({type: 'eq', key: 'categoryType', value: category});
      }
      const sortBy = {sortKey: 'createdAt', sortDirection: 'DESC'};
      const userOffers = await backend.offer.query.byRequester(user.userRn, filters, sortBy, null);

      if (userOffers.list.length) {
        setShowDuplicate(true);
        setDuplicateRn(userOffers.list[0].offerRn);
      } else {
        const offerRn = await createNewOffer();

        navigate(`/offer/${offerRn}`);
      }
    });
  }, []);

  const onNew = async () => {
    const offerRn = await createNewOffer();

    navigate(`/offer/${offerRn}`);
  };

  const onContinue = () => {
    navigate(`/offer/${duplicateRn}`);
  };

  if (showDuplicate) {
    return (
      <Dialog >
        <DialogTitle>
          <Translate expression='market.Order.New.Duplicate.Title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate expression='market.Order.New.Duplicate.Body' />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onNew}>
            <Translate expression='market.Order.New.Duplicate.ActionNew' />
          </Button>
          <Button color='primary' onClick={onContinue}>
            <Translate expression='market.Order.New.Duplicate.ActionContinue' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

NewOffer.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(NewOffer));

