import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import backend from 'utils/backend';

const ArtistSettings = (props) => {
  const artist = props.artist;
  const user = props.user;
  const artistUpdate = props.artistUpdate;

  React.useEffect(() => {
    const checkArtist = async () => {
      let status = 'NOT_ARTIST';
      try {
        const result = await backend.artist.get(user.userRn);
        status = result.status;
      } catch (error) {
        console.error('Error fetching current artist:', error);
      }
      artistUpdate(user.userRn, status);
    };

    if (user?.userRn && !artist.lastLoadTime) {
      checkArtist();
    }
  }, [user, artist]);

  return null;
};

ArtistSettings.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  artistUpdate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  artist: state.artist,
  user: state.user,
});

export default connect(mapStateToProps, dispatch)(React.memo(ArtistSettings));
