import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Box, Stack, TextField, Button} from 'views/components';
import {Translate} from 'views/module';

import Backend from 'utils/backend';

const CodeInput = (props) => {
  const [code, setCode] = React.useState(new Array(6).fill(''));
  const inputRefs = React.useRef([]);

  const onChange = props.onChange;

  React.useEffect(() => {
    onChange(code.join(''));
  }, [code]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').replace(/\s/g, '').slice(0, 6).split('');
    if (pasteData.length > 0) {
      setCode(pasteData.concat(new Array(6 - pasteData.length).fill('')));
      inputRefs.current[pasteData.length - 1].focus();
    }
  };


  const handleChange = (element, index) => {
    if (element.length > 1) {
      const newCode = [...element.value.slice(0, 6)].concat(new Array(6 - element.length).fill(''));
      setCode(newCode);
      const nextIndex = Math.min(index + element.value.length, 5);
      inputRefs.current[nextIndex].focus();
      return;
    }

    const newCode = [...code];
    newCode[index] = element;
    setCode(newCode);

    if (element.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && code[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
      {code.map((digit, index) => (
        <TextField
          key={index}
          inputRef={(ref) => inputRefs.current[index] = ref}
          type="text"
          variant="outlined"
          inputProps={{maxLength: 1, style: {textAlign: 'center', padding: '10px'}}}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={handlePaste}
          sx={{flex: 1, m: 0.5}}
        />
      ))}
    </Box>
  );
};
CodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const EmailCode = (props) => {
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const request = props.request;
  const setRequest = props.setRequest;
  const nextStep = props.nextStep;
  const progressWrap = props.statusProgressWrap;

  const code = request.code;
  const email = request.email;

  const setCode = (input) => {
    setRequest((e) => {
      return {
        ...e,
        code: input,
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!(valid)) {
      return;
    }

    progressWrap(async () => {
      setLoading(true);
      try {
        await Backend.identity.onboarding.registerEmailCode(email, code);
        nextStep();
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setLoading(false);
      }
    }, {
      onErrorMessage: (error) => {
        if (error.message === 'resource_not_found') {
          return (
            <>
              <Translate expression='onboarding.Signup.Form.2.Error'/>
            </>
          );
        }
        return null;
      },
      showProgress: false,
    });
  };

  React.useEffect(() => {
    setValid(code?.length === 6);
  }, [code]);

  return (
    <form onSubmit={onSubmit} >
      <Stack spacing={2}>
        <Typography
          variant='body1'
        >
          <Translate
            expression='onboarding.Signup.Form.2.Info'
            substitute={{email}}
          />
        </Typography>
        <Stack direction="row" spacing={2}>
          <CodeInput
            onChange={setCode}
          />
        </Stack>
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          sx={{
            'minHeight': '48px',
            '&:disabled': {
              backgroundColor: '#FB8488',
            },
          }}
          disabled={!(valid)}
          onClick={onSubmit}
          type="submit"
          loading={loading}
        >
          <Typography
            variant='h5'
            sx={{
              color: 'color.white',
              fontWeight: 500,
              my: '4px',
            }}
          >
            <Translate expression='onboarding.Signup.Form.2.Action' />
          </Typography>
        </Button>
      </Stack>
    </form>
  );
};
EmailCode.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  statusProgressWrap: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(EmailCode));
