import React from 'react';
import PropTypes from 'prop-types';

import {Dialog, DialogTitle, DialogContent, DialogActions} from 'views/components';
import {Button, TextField, Box} from 'views/components';

import {Translate} from 'views/module';

const EditPhoto = (props) => {
  const [fileNameEdit, setFileNameEdit] = React.useState('');

  const {
    closeDialog,
    onAction,
    fileName,
  } = props;

  React.useEffect(() => {
    setFileNameEdit(fileName);
  }, [fileName]);

  const onSubmit = () => {
    onAction(fileNameEdit);
  };

  return (
    <Dialog onClose={closeDialog}>
      <DialogTitle onClose={closeDialog}>
        <Translate expression='market.Order.ProjectDetail.UploadFiles.EditPhoto.Title' />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            p: 1,
          }}
        >
          <TextField
            value={fileNameEdit}
            onChange={setFileNameEdit}
            label={<Translate expression='market.Order.ProjectDetail.UploadFiles.EditPhoto.TitleEdit' />}
            fullWidth
            InputLabelProps={{shrink: true}}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={closeDialog}>
          <Translate expression='generic.Return' />
        </Button>
        <Button
          onClick={onSubmit}
          color='primary'
        >
          <Translate expression='generic.Edit' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
EditPhoto.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default React.memo(EditPhoto);
