import React from 'react';
// import PropTypes from 'prop-types';

import {Box, Typography} from 'views/components';

import {Translate} from 'views/module';

const WaitingForContract = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(/images/coffee.png)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover', // or 'contain' depending on your need
          backgroundRepeat: 'no-repeat',
          width: '250px',
          height: '250px',
        }}
      />
      <Typography
        variant='h4'
      >
        <Translate expression='market.Contract.Feed.NoOpenContract.ASSIGNED.Title' />
      </Typography>
      <Typography>
        <Translate expression='market.Contract.Feed.NoOpenContract.ASSIGNED.Body' />
      </Typography>
    </Box>
  );
};

WaitingForContract.propTypes = {
};
export default WaitingForContract;
