import backend, {MARKET_API} from 'utils/backend/server/api_library';

const applyForContract = (offerRn, userRn) => {
  const body = {
    userRn,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/contract`, body);
};
const completeContract = (offerRn, contractRn, userRn) => {
  const body = {
    userRn,
  };
  return backend.post(`${MARKET_API}/offer/${offerRn}/contract/${contractRn}/complete`, body);
};

export default {
  applyForContract,
  completeContract,
};
