/* eslint-disable max-len */

export default {
  Title: 'Our Privacy Policy',
  Body: `
  <div>
  <h1>Welcome to StudioGrams</h1>
  <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from <a href="https://StudioGrams.dev/" target="_blank">https://StudioGrams.dev/</a>, the StudioGrams Android and iOS app, or our Software-as-a-Service Information Technology (Our "Service").</p>

  <h2>PERSONAL INFORMATION WE COLLECT</h2>
  <p>When you visit our service, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse our service, we collect information about the individual web pages or products that you view, what websites or search terms referred you to our service, and information about how you interact with our service. We refer to this automatically-collected information as "Device Information."</p>

  <p>We collect Device Information using the following technologies:</p>
  <ol>
    <li>
      <p>"Log files" track actions occurring on our service and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
    </li>
    <li>
      <p>"Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse our service.</p>
    </li>
  </ol>

  <p>Additionally, when you make a purchase or attempt to make a purchase through our service, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number). We refer to this information as "Order Information."</p>

  <p>When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device Information and Order Information.</p>

  <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
  <p>We use the Order Information that we collect generally to fulfill any orders placed through our service (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
    <ul>
      <li>Communicate with you</li>
      <li>Screen our orders for potential risk or fraud</li>
      <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services</li>
    </ul>
  </p>

  <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with our service, and to assess the success of our marketing and advertising campaigns).</p>

  <h2>SHARING YOUR PERSONAL INFORMATION</h2>
  <p>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful request for information we receive, or to otherwise protect our rights.</p>

  <h2>DO NOT TRACK</h2>
  <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

  <h2>YOUR RIGHTS</h2>
  <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. Additionally, if you are a European resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example, if you make an order through our service), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

  <h2>DATA RETENTION</h2>
  <p>When you place an order through our service, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

  <h2>CHANGES</h2>
  <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

  <h2>CONTACT US</h2>
  <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:info@StudioGrams.dev">info@StudioGrams.dev</a></p>
</div>
  `,

};
