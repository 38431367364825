import React from 'react';

import {Helmet} from 'views/module';

import SignOutScene from 'views/scenes/auth/sign_out/SignOut';

const SignOut = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Sign out</title>
      </Helmet>
      <SignOutScene {...props}/>
    </>
  );
};

SignOut.propTypes = {
};

export default React.memo(SignOut);
