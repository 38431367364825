import React from 'react';
import PropTypes from 'prop-types';

import {Link as RouterLink} from 'react-router-dom';
import {Link} from '@mui/material';

const CustomLink = (props) => {
  if (props.href) {
    return (
      <Link
        to={props.href}
        sx={props.sx}
        onClick={props.onClick}
        component={RouterLink} /* Use router link with styling from material ui link */
        underline={props.underline}
      >
        {props.labelText || props.children}
      </Link>
    );
  } else {
    return (
      <Link
        component="button"
        variant="body2"
        sx={props.sx}
        onClick={props.onClick}
        underline={props.underline}
      >
        {props.labelText || props.children}
      </Link>
    );
  }
};

CustomLink.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  href: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.string,
};


export default React.memo(CustomLink);
