import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, TextField, IconButton} from 'views/components';
import {ImageHorizontalList} from 'views/components';

import {Send as SendIcon, AttachFile as AttachFileIcon} from 'views/icons';

import {translate, Translate} from 'views/module';


const ChatNewMessage = (props) => {
  const boxRef = React.useRef(null);
  const [uploadImageLocation, setUploadImageLocation] = React.useState(null);

  const newMessage = props.newMessage;
  const setNewMessage = props.setNewMessage;
  const fileUrls = props.fileUrls;
  const handleSendMessage = props.handleSendMessage;
  const openUploadDialog = props.openUploadDialog;
  const removeFileUrls = props.removeFileUrls;

  const updateFloatingBoxPosition = () => {
    if (boxRef.current) {
      const rect = boxRef.current.getBoundingClientRect();
      setUploadImageLocation({
        top: rect.top + window.scrollY - 144, // Adjust as needed
        left: rect.left + window.scrollX, // Adjust as needed
        width: rect.width, // Adjust as needed
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateFloatingBoxPosition);

    updateFloatingBoxPosition();

    return () => {
      window.removeEventListener('resize', updateFloatingBoxPosition);
    };
  }, [fileUrls]);

  const images = fileUrls.map((e) => {
    return {
      imageUrl: e,
    };
  });

  return (
    <Box
      sx={{
      }}
    >
      {
        (fileUrls?.length > 0 && uploadImageLocation) &&
        <Box
          sx={{
            position: 'absolute',
            ...uploadImageLocation,
            maxHeight: 144,
            height: 144,
            backgroundColor: 'rgba(228, 229, 235, 0.9)',
            zIndex: 1000,
            p: '8px',
          }}
        >
          <Translate expression='market.Contract.Feed.Correspondence.Message.AttachedImages' />
          <Box
            sx={{
              maxHeight: 100,
              height: 100,
            }}
          >
            <ImageHorizontalList
              images={images}
              imageWidth='100px'
              removeImage={removeFileUrls}
            />
          </Box>
        </Box>
      }
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder={translate('market.Contract.Feed.Correspondence.Message.MessagePlaceholder')}
            value={newMessage}
            onChange={setNewMessage}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <IconButton onClick={handleSendMessage}>
            <SendIcon />
          </IconButton>
          <IconButton onClick={openUploadDialog}>
            <AttachFileIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ChatNewMessage.propTypes = {
  newMessage: PropTypes.string.isRequired,
  setNewMessage: PropTypes.func.isRequired,
  fileUrls: PropTypes.array.isRequired,
  setFileUrls: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  openUploadDialog: PropTypes.func.isRequired,
  removeFileUrls: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatNewMessage));

