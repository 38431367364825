import {createTheme} from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#FC8301',
      light: '#FCF2E8',
      dark: '#E57701',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F9575C',
      light: '#FB8488',
      dark: '#DE080F',
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
    },
    warning: {
      main: '#FFB400',
      light: '#ff9800',
      dark: '#e65100',
    },
    info: {
      main: '#2196F3',
      light: '#64B5F6',
      dark: '#1976D2',
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C',
    },
    background: {
      default: '#FCFDFF',
    },
    grey: {
      900: '#1C1C1F',
      800: '#2F3033',
      700: '#6D6E73',
      600: '#84858A',
      500: '#B3B4BA',
      400: '#D0D1D6',
      300: '#E4E5EB',
      200: '#F0F2F7',
      100: '#FAFBFC',
    },
    color: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 2200,
    },
  },
});
