import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Typography, Stack, CircularProgress} from 'views/components';
import {Translate} from 'views/module';
import {useNavigate} from 'views/module';

import {login} from 'utils/auth/login';

const Loggin = (props) => {
  const [loginAttempts, setLoginAttempts] = React.useState(1);
  const [signedIn, setSignedIn] = React.useState(false);

  const navigate = useNavigate();

  const request = props.request;
  const progressWrap = props.statusProgressWrap;
  const userReload = props.userReload;

  const email = request.email;
  const password = request.password;

  React.useEffect(() => {
    const attemptLogin = (attempt) => {
      progressWrap(async () => {
        try {
          await login(email, password);
          setSignedIn(true);

          setTimeout(() => {
            userReload();
            navigate('/');
          }, 1000);
        } catch (e) {
          console.error(e);
          setLoginAttempts((e) => e + 1);
        }
      }, {showProgress: false});
    };

    // Set a timeout for the next attempt if it's not the first attempt
    if (loginAttempts <= 3) {
      const delay = loginAttempts * 5 * 1000; // 5, 10, 15 seconds
      const timeoutId = setTimeout(attemptLogin, delay);

      // Cleanup function to clear the timeout
      return () => clearTimeout(timeoutId);
    }
  }, [loginAttempts]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress
        />
        {
          !signedIn &&
          <Typography
            variant='body1'
            sx={{
              px: '16px',
              pt: '8px',
              lineHeight: '1rem',
              textAlign: 'center',
            }}
          >
            <Translate expression='onboarding.Signup.Form.5.Loading' />
          </Typography>
        }
        {
          signedIn &&
          <Translate expression='onboarding.Signup.Form.5.SignedIn' />
        }
      </Stack>
    </>
  );
};
Loggin.propTypes = {
  request: PropTypes.object.isRequired,
  setRequest: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  statusProgressWrap: PropTypes.func.isRequired,
  userReload: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Loggin));
