import React from 'react';
import PropTypes from 'prop-types';

import {Box} from 'views/components';

import {FilterChip} from 'views/module';
import {translate} from 'views/module';

const FilterChipOption = (props) => {
  const filter = props.filter;
  const onTabChange = props.onTabChange;
  const updateFilter = props.updateFilter;
  const filterOptionData = props.filterOptionData;
  const smallerScreen = props.smallerScreen;

  const calculateFilterChips = React.useCallback(() => {
    const chipFilterObj = {};

    let rank = 1;

    if (filter?.filter?.designType) {
      for (const design of filter.filter.designType) {
        chipFilterObj[design] = {
          title: translate(`market.Types.${design}.Title`),
          rank: rank++,
        };
      }
    }

    return chipFilterObj;
  }, [filter, smallerScreen]);

  const handleClick = (filterKey, item) => {
    if (filterKey === 'table') {
      onTabChange(item.value);
    } else {
      updateFilter(filterKey, item.value);
    }
  };

  const handleDelete = (filterKey) => {
    updateFilter(filterKey, null);
  };

  const designTypeDelete = (filterKey) => {
    const newDesignList = filter.filter.designType.filter((design) => design != filterKey);
    updateFilter('designType', newDesignList);
  };

  const chipFilterData = calculateFilterChips();

  const sortedChips = Object.entries(chipFilterData).sort((a, b) => {
    // If both items have a rank, compare them by rank
    return a[1].rank - b[1].rank;
  });

  return (
    <>
      {
        sortedChips.map(([filterKey, filterData]) => {
          let dataKey;
          let deleteFunction;
          if (filter?.filter?.designType.includes(filterKey)) {
            dataKey = 'designType';
            deleteFunction = designTypeDelete;
          } else {
            dataKey = filterKey;
            deleteFunction = handleDelete;
          }

          return (
            <Box sx={{p: 0.5}} key={filterKey}>
              <FilterChip
                filterKey={dataKey}
                label={filterData}
                data={filterOptionData[dataKey]?.options}
                onClick={handleClick}
                onDelete={() => deleteFunction(filterKey)}
                isDeletable={filterKey === 'table'}
              />
            </Box>
          );
        })
      }
    </>
  );
};

FilterChipOption.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  filterOptionData: PropTypes.object.isRequired,
  smallerScreen: PropTypes.bool.isRequired,
};


export default React.memo(FilterChipOption);
