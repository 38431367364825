import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {LinearProgress} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    zIndex: 10000,
    height: 5,
    top: 0,
    width: '100%',
  },
}));

const ProgressBar = (props) => {
  const classes = style();

  const states = props.status?.states || {};

  const progress = Object.values(states).filter((e) => e.status === 'PROGRESS');

  const isThereProgress = progress.length > 0;

  if (isThereProgress) {
    return (
      <LinearProgress color="secondary" className={classes.root} />
    );
  } else {
    return (
      <div className={classes.root}/>
    );
  }
};
ProgressBar.propTypes = {
  status: PropTypes.object,
};
const mapStateToProps = (state) => ({
  status: state.status,
});

export default connect(mapStateToProps, dispatch)(React.memo(ProgressBar));
