export default {
  Info: {
    Title: 'Unleash Your Imagination',
    Body: 'Welcome back to where creativity meets expertise.',
  },
  Signup: {
    Welcome: 'Welcome back.',
    Signin: 'Sign In to access your account',
    UserAuth: {
      Email: 'Email',
      Password: 'Password',
      Login: 'Login',
      LoginWith: 'Sign In With',
      NewAccount: {
        Create: 'Looking to create a new account? ',
        GetStarted: 'Get started',
      },
      ForgotPassword: {
        Create: 'Forgot password? ',
        GetStarted: 'Click here',
      },
      ResetPassword: {
        Title: 'Create new password',
        Body: 'We\'ll ask for this password whenever you sign-in',
        Password: 'New Password',
        RePassword: 'Re-enter Password',
        Action: 'Save change and sign-in',
      },
    },
    UserforgotPassword: {
      FirstStage: {
        Title: 'Forgot password?',
        Body: 'Enter the email address associated with your account.',
        Email: 'Email',
        Action: 'Continue',
      },
      SecondStage: {
        Title: 'Verification required',
        Body: 'To continue, complete this verification step. We\'ve sent a One Time Password (OTP) to your email. Please enter it below.',
        OTP: 'OTP',
        Action: 'Continue',
      },
      ThirdStage: {
        Title: 'Create new password',
        Body: 'We\'ll ask for this password whenever you sign-in',
        Password: 'New Password',
        RePassword: 'Re-enter Password',
        Action: 'Save change and sign-in',
      },
      GoBack: {
        GoBack: 'Go back',
        LoginScreen: 'to the login screen',
      },
    },
  },
  LoginFail: 'Sorry, we failed to sign you in, please try again',
  Form: {
    FirstName: {
      Title: 'First Name',
      Example: 'First Name',
      FormFeedback: 'Name must be between 1-15 letters',
    },
    LastName: {
      Title: 'Last Name',
      Example: 'Last Name',
      FormFeedback: 'Name must be between 1-15 letters',
    },
    Email: {
      Title: 'Email',
      Example: 'example@example.com',
      FormFeedback: 'Please input a correct email',
    },
  },
  OAuth: {
    Title: 'Signing you in',
  },
};
