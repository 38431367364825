import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Button, Grid, Paper, Typography, Divider} from 'views/components';

import {Translate, UploadFileToS3} from 'views/module';

import {CloudUpload} from 'views/icons';

import PhotoBox from './PhotoBox';

const UploadPhotos = (props) => {
  const [openDialog, setOpenDialog] = React.useState(0);

  const referenceFiles = props.referenceFiles || {};
  const addOrderFileWithSignedUrl = props.addOrderFileWithSignedUrl;
  const editOrderFile = props.editOrderFile;
  const deleteOrderFile = props.deleteOrderFile;


  const openUploadDialog = () => {
    setOpenDialog((e) => e + 1);
  };
  React.useEffect((e) => {
    setOpenDialog(0);
  }, [openDialog]);

  return (
    <Box
      sx={{
        border: 1, // Sets the border width
        borderColor: 'grey.700',
        p: 1,
      }}
    >
      <Typography variant="h6" sx={{pl: 2, pb: 1}}>
        <Translate expression='market.Order.ProjectDetail.UploadFiles.Title' />
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              height: {md: '400px', xs: '200px'},
              maxHeight: {md: '400px', xs: '200px'},
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              p: 1,
            }}
          >
            {
              Object.values(referenceFiles).map((file) => {
                const fileRn = file.fileRn;
                const imageUrl = file.imageUrl;
                const fileName = file.fileName;

                return (
                  <React.Fragment key={fileRn}>
                    <PhotoBox
                      fileRn={fileRn}
                      fileName={fileName}
                      imageUrl={imageUrl}
                      deleteOrderFile={deleteOrderFile}
                      editOrderFile={editOrderFile}
                    />
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                  </React.Fragment>
                );
              })
            }
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              maxHeight: '500px',
              overflow: 'auto',
            }}
          >
            <UploadFileToS3
              getSignedUrl={addOrderFileWithSignedUrl}
              openDialog={openDialog}
            >
              <Paper
                sx={{
                  width: '100%',
                  height: {md: '400px', xs: '200px'},
                  backgroundColor: 'grey.200',
                  borderStyle: 'dotted',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: 2,
                }}
              >
                <CloudUpload fontSize="large" />
                <Typography>
                  <Translate expression='market.Order.ProjectDetail.UploadFiles.DragAndDrop' />
                </Typography>
                <Button onClick={openUploadDialog}>
                  <Translate expression='market.Order.ProjectDetail.UploadFiles.Browse' />
                </Button>
              </Paper>
            </UploadFileToS3>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

UploadPhotos.propTypes = {
  referenceFiles: PropTypes.object,
  addOrderFileWithSignedUrl: PropTypes.func.isRequired,
  editOrderFile: PropTypes.func.isRequired,
  deleteOrderFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(UploadPhotos));
