import React from 'react';
import PropTypes from 'prop-types';

import {Link as RouterLink} from 'react-router-dom';

import {Card, CardActionArea as CardActionAreaMui, CardActions, CardContent, CardHeader, CardMedia} from '@mui/material';

const CardActionArea = (props) => {
  return (
    <CardActionAreaMui
      {...props}
      component={RouterLink}
    />
  );
};

CardActionArea.propTypes = {
  children: PropTypes.node,
};

export {
  Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia,
};
