import React from 'react';
import PropTypes from 'prop-types';

import {Button} from 'views/components';
import {Translate} from '../Translate';

const TextLimit = ({text, maxLength}) => {
  const [isTruncated, setIsTruncated] = React.useState(true);

  const toggleTruncation = () => {
    setIsTruncated(!isTruncated);
  };

  const renderText = () => {
    if (isTruncated) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    }
    return text;
  };

  if (!text) {
    return text;
  }

  return (
    <div>
      {renderText()}
      {
        text.length > maxLength && (
          <Button variant="text"
            sx={{
              py: 0,
            }}
            onClick={toggleTruncation}
          >
            {
              isTruncated ?
              <Translate expression='generic.ShowMore' /> :
              <Translate expression='generic.ShowLess' />
            }
          </Button>
        )
      }
    </div>
  );
};

TextLimit.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};
export default TextLimit;
