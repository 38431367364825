export default {
  Company: 'StudioGrams',
  Resource: {
    Resource: 'Resource',
    Pricing: 'Pricing',
    Blog: 'Blog',
  },
  AboutUs: {
    AboutUs: 'About us',
    Who: 'Who we are',
    Work: 'Work with us',
  },
  Final: {
    Copyright: `Copyright © ${new Date().getFullYear()} StudioGrams. All rights reserved.`,
    Policy: 'Privacy Policy',
    Term: 'Website Terms of Use',
  },
};
