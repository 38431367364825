import React from 'react';

import {Helmet} from 'views/module';

import ForgotPassword from 'views/scenes/auth/forgot_password/ForgotPassword';

const Authentication = (props) => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Forgot password</title>
      </Helmet>
      <ForgotPassword {...props}/>
    </>
  );
};

Authentication.propTypes = {
};

export default React.memo(Authentication);
