import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import UserForgotPassword from './scenes/UserForgotPassword';

const Authentication = (props) => {
  return (
    <UserForgotPassword
    />
  );
};

Authentication.propTypes = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(Authentication));
