import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Typography, Button} from 'views/components';
import {Translate} from 'views/module';

import backend from 'utils/backend';

const ArtistSignup = (props) => {
  const [isSetting, setIsSetting] = React.useState('COMPLETE');

  const progressWrap = props.statusProgressWrap;
  const artistUpdate = props.artistUpdate;
  const user = props.user;
  const artist = props.artist;

  const apply = () => {
    progressWrap(async () => {
      await backend.artist.register(user.userRn);
      artistUpdate(user.userRn, 'REGISTER_REQUEST');
    }, {setStage: setIsSetting});
  };

  if (artist.status === 'REGISTER_REQUEST') {
    return (
      <Box
        sx={{
          my: 2,
        }}
      >
        <Typography variant='h4'>
          <Translate expression='artist.RegistrationRequest.Title' />
        </Typography>
        <Typography variant='h6'
          sx={{
            fontWeight: 400,
          }}
        >
          <Translate expression='artist.RegistrationRequest.TitleSub' />
        </Typography>
        <br />
        <Typography >
          <Translate expression='artist.RegistrationRequest.Body' />
        </Typography>
      </Box>
    );
  } else if (artist.status === 'NOT_ARTIST') {
    return (
      <Box
        sx={{
          my: 2,
        }}
      >
        <Typography variant='h4'>
          <Translate expression='artist.Signup.Title' />
        </Typography>
        <Typography variant='h6'
          sx={{
            fontWeight: 400,
          }}
        >
          <Translate expression='artist.Signup.TitleSub' />
        </Typography>
        <br />
        <Typography >
          <Translate expression='artist.Signup.Body' />
        </Typography>
        <br />
        <Box
          sx={{
            display: 'flex', // Use flexbox
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
          }}
        >
          <Button
            onClick={apply}
            sx={{
            }}
            disabled={isSetting !== 'COMPLETE'}
            loading={isSetting !== 'COMPLETE'}
          >
            <Typography>
              <Translate expression='artist.Signup.Action' />
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};
ArtistSignup.propTypes = {
  statusProgressWrap: PropTypes.func.isRequired,
  artistUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  artist: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
  artist: state.artist,
});

export default connect(mapStateToProps, dispatch)(React.memo(ArtistSignup));

