import React from 'react';
import PropTypes from 'prop-types';

import {Tabs, Tab} from 'views/components';

const tabSx = {
  'textTransform': 'none',
  'fontSize': '16px',
  'fontWeight': 400,
  'color': 'black',
  'display': 'inline',
  'textAlign': 'left',
  'flexDirection': 'row',
  '&:hover': {
    backgroundColor: 'background.highlighted',
  },
  '&.Mui-selected': {
    fontWeight: 'bold',
  },
};

const FilterTabs = (props) => {
  const tabValue = props.tabValue;
  const tabOptions = props.tabOptions;
  const onTabChange = props.onTabChange;

  const onChange = (event, value) => {
    onTabChange(value);
  };

  return (
    <Tabs
      orientation={'vertical'}
      value={tabValue.value}
      onChange={onChange}
      sx={{minWidth: '155px', maxWidth: '175px'}}
    >
      {
        Object.values(tabOptions).map((e, index)=> {
          return (
            <Tab
              key={index}
              label={e.label}
              value={e.value}
              sx={tabSx}
            />
          );
        })
      }
    </Tabs>
  );
};
FilterTabs.propTypes = {
  tabValue: PropTypes.object.isRequired,
  tabOptions: PropTypes.object.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default React.memo(FilterTabs);
