import React from 'react';

import {Box} from 'views/components';

import {Translate} from 'views/module';
import {Helmet} from 'views/module';

const TermAndCondition = () => {
  return (
    <>
      <Helmet>
        <title>StudioGrams: Terms and conditgions</title>
      </Helmet>
      <Box>
        <h1>
          <Translate expression='policy.TermCondition.Title'/>
        </h1>
        <Translate expression='policy.TermCondition.Body'/>
      </Box>
    </>
  );
};

export default TermAndCondition;
