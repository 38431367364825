import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Typography, Button} from '@mui/material';
import {Refresh} from '@mui/icons-material';

import {translate} from 'views/module';

/**
   * Refresh widget that contains a button to refresh a component and some text showing last updated time
   * @param {*} props
   * @param {Number} props.numRefresh state variable to update when clicking refresh
   * @param {Function} props.setNumRefresh function to update the state when clicking refresh
   * @return {ReactJSXElement}
   */
const RefreshWidget = (props) => {
  const numRefresh = props.numRefresh;
  const setNumRefresh = props.setNumRefresh;
  const justifyContent = props.justifyContent || 'start';

  const onRefresh = () => {
    setNumRefresh(numRefresh + 1);
  };

  return (
    <Grid container justifyContent={justifyContent} sx={{wordBreak: 'break-word', textAlign: 'left'}}>
      <Grid item order={{xs: 1, md: justifyContent === 'start' ? 1 : 2}} sx={{my: 'auto'}}>
        <Button variant='outlined' onClick={onRefresh} >
          <Refresh />
          <Typography sx={{textTransform: 'uppercase', fontWeight: 500, pl: 1, color: 'black'}}>
            {translate('generic.Refresh')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
RefreshWidget.propTypes = {
  numRefresh: PropTypes.number.isRequired,
  setNumRefresh: PropTypes.func.isRequired,
  justifyContent: PropTypes.string,
};

export {
  RefreshWidget,
};
