import backend, {IDENTITY_API} from 'utils/backend/server/api_library';

const registerEmail = (email) => {
  const body = {
    email,
  };
  return backend.post(`${IDENTITY_API}/user/register/first`, body);
};

const registerEmailCode = (email, code) => {
  const body = {
    email,
    code,
  };
  return backend.post(`${IDENTITY_API}/user/register/second`, body);
};


const registerComplete = (email, code, user, password) => {
  const body = {
    email,
    code,
    user,
    password,
  };
  return backend.post(`${IDENTITY_API}/user/register/third`, body);
};

export default {
  registerEmail,
  registerEmailCode,
  registerComplete,
};
