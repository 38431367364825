import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, getPageHeight} from 'views/components';

import ChatBubble from './chat_bubble/ChatBubble';

const ChatWindow = (props) => {
  const [boxHeight, setBoxHeight] = React.useState(400);

  const bottomRef = React.useRef(null);
  const boxRef = React.useRef(null);

  const pageHeight = getPageHeight();

  const messages = props.messages;
  const contract = props.contract;

  React.useEffect(() => {
    const delay = () => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    };
    const timeoutId = setTimeout(delay, 100);
    return () => clearTimeout(timeoutId); // Cleanup
  }, [messages]);

  React.useEffect(() => {
    if (boxRef) {
      const rect = boxRef.current.getBoundingClientRect();
      setBoxHeight(pageHeight - rect.top - 88);
    }
  }, [pageHeight]);

  return (
    <Box
      ref={boxRef}
      sx={{
        overflowY: 'scroll',
        height: `${boxHeight}px`,
        padding: 2,
        scrollbarWidth: 'thin',
      }}
    >
      {
        messages.map((message, index) => {
          const previousMessage = index !== 0 ? messages[index - 1] : null;

          const createdAt = new Date(message.createdAt);
          const previousMessageCreatedAt = previousMessage ? new Date(previousMessage.createdAt) : null;

          const currentDate = createdAt.toDateString();
          const previousDate = previousMessageCreatedAt ? previousMessageCreatedAt.toDateString() : null;

          const showDate = (index === 0 || currentDate !== previousDate);

          const messageUser = message.requesterRn;
          const previousMessageUser = previousMessage?.requesterRn;


          let showTime = false;
          if (messageUser !== previousMessageUser) {
            showTime = true;
          } else if (showDate) {
            showTime = true;
          } else if (previousMessageCreatedAt) {
            // Calculate the difference in time between the current message and the previous message
            const timeDifference = createdAt - previousMessageCreatedAt;
            // If the difference is more than 1 minute, show time
            if (timeDifference > 60000) {
              showTime = true;
            }
          } else {
            // If there is no previous message, show time
            showTime = true;
          }

          return (
            <React.Fragment key={index}>
              {
                showDate &&
                <Box
                  sx={{
                    display: 'flex', justifyContent: 'center',
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: 'center',
                      backgroundColor: 'lightgrey',
                      display: 'inline-block', // Makes the box size according to its content
                      padding: '8px', // Add some space around the text
                      borderRadius: '14px', // Rounded corners
                    }}
                  >
                    {currentDate}
                  </Box>

                </Box>
              }
              <ChatBubble
                {...props}
                showTime={showTime}
                message={message}
                contract={contract}
              />
            </React.Fragment>
          );
        })
      }
      <div ref={bottomRef} />
    </Box>
  );
};

ChatWindow.propTypes = {
  messages: PropTypes.array.isRequired,
  contract: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ChatWindow));


