import * as React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckboxField = (props) => {
  const label = props.label;
  const checked = props.checked;
  const onChange = props.onChange;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control= {
        <Checkbox
          checked={checked}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

CheckboxField.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.any.isRequired,
};

export default CheckboxField;
