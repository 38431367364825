import React from 'react';
import PropTypes from 'prop-types';

const UserNameDisplay = (props) => {
  const user = props.user;

  let displayTitle = null;
  if (user) {
    displayTitle = `${user.firstName} ${user.lastName}`;
  }

  return displayTitle;
};
UserNameDisplay.propTypes = {
  user: PropTypes.object.isRequired,
};

export default React.memo(UserNameDisplay);
