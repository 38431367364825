import React from 'react';
import PropTypes from 'prop-types';

import {Box, MobileStepper, Button} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';

function SwipeablePictureFrame(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [autoPlay, setAutoPlay] = React.useState(null);

  const stepperRef = React.useRef(null);

  const images = props.images;
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setAutoPlay(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setAutoPlay(false);
  };

  React.useEffect(() => {
    setAutoPlay(true);
  }, []);

  React.useEffect(() => {
    if (autoPlay !== null) {
      if (autoPlay) {
        const interval = setInterval(() => {
          setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
        }, 4000); // Change image every 10 seconds
        return () => clearInterval(interval);
      } else {
        // Reset it after 60 seconds
        const timer = setTimeout(() => {
          setAutoPlay(true);
        }, 60000); // 60 seconds delay
        return () => clearTimeout(timer); // Cleanup
      }
    }
  }, [autoPlay]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          height: `calc(100% - ${stepperRef?.current?.clientHeight || 48}px)`,
        }}
      >
        {
          images.map((img, index) => (
            <Box
              key={index}
              sx={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                left: `${125 * (index - activeStep)}%`,
                width: '100%',
                height: '100%',
                transition: 'left 1s ease-in-out',
              }}
            />
          ))
        }
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        ref={stepperRef}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
}

SwipeablePictureFrame.propTypes = {
  images: PropTypes.array,
};

export default SwipeablePictureFrame;
