import React from 'react';
import PropTypes from 'prop-types';

import t from 'utils/translate';

const translate = (expression, substitute) => {
  return t('en', expression, substitute);
};

const Translate = ({expression, substitute}) => {
  const translatedString = translate(expression, substitute);

  return (
    <span
      dangerouslySetInnerHTML={{__html: translatedString}}
    />
  );
};
Translate.propTypes = {
  expression: PropTypes.string,
  substitute: PropTypes.object,
};

const Currency = (props) => {
  const formatCurrency = (amount, currency = 'USD', locale = 'en-US') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(amount);
  };

  return <div>{formatCurrency(props.amount)}</div>;
};
Currency.propTypes = {
  amount: PropTypes.number,
};

export {
  Translate,
  translate,
  Currency,
};
