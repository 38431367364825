import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Typography, Button} from 'views/components';

import {Translate} from 'views/module';

const StartContract = (props) => {
  const applyForContract = props.applyForContract;
  const artist = props.artist;

  const apply = () => {
    applyForContract(artist.userRn);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(/images/coffee.png)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover', // or 'contain' depending on your need
          backgroundRepeat: 'no-repeat',
          width: '250px',
          height: '250px',
        }}
      />
      <Typography
        variant='h5'
      >
        <Translate expression='market.Contract.Feed.AcceptContract.ASSIGNED.Title' />
      </Typography>
      <Button
        onClick={apply}
      >
        <Typography>
          <Translate expression='market.Contract.Feed.AcceptContract.ASSIGNED.Body' />
        </Typography>
      </Button>
    </Box>
  );
};
StartContract.propTypes = {
  applyForContract: PropTypes.func.isRequired,
  artist: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  artist: state.artist,
});

export default connect(mapStateToProps, dispatch)(React.memo(StartContract));

