import React from 'react';
// import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {RequireAuthentication} from 'views/module';
import {Helmet} from 'views/module';

import OpenRequestScene from 'views/scenes/market/list/open_request/OpenRequest';

const OpenRequest = (props) => {
  const [reload, setReload] = React.useState(0);

  return (
    <>
      <Helmet>
        <title>StudioGrams: Open request</title>
      </Helmet>
      <RequireAuthentication>
        <OpenRequestScene
          reload={reload}
          setReload={setReload}
        />
      </RequireAuthentication>
    </>
  );
};

OpenRequest.propTypes = {
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(OpenRequest));

