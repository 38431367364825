import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Dialog, DialogContent, DialogTitle} from 'views/components';
import {Grid, Typography} from 'views/components';

import {Translate} from 'views/module';

import UserAuthenticationLogin from './scenes/UserAuthenticationLogin';
import UserAuthenticationResetPassword from './scenes/UserAuthenticationResetPassword';

const AuthenticationDailog = (props) => {
  const [stage, setStage] = React.useState('LOGIN');
  const [userData, setUserData] = React.useState(null);

  let display = null;
  let title = null;
  let body = null;

  if (stage === 'LOGIN') {
    display = (
      <UserAuthenticationLogin
        resetPassword={(e) => {
          setUserData(e);
          setStage('RESET');
        }}
      />
    );

    title = (
      <Translate expression='auth.Signup.Welcome' />
    );

    body = (
      <Translate expression='auth.Signup.Signin' />
    );
  } else if (stage === 'RESET') {
    display = (
      <UserAuthenticationResetPassword
        userData={userData}
      />
    );

    title = (
      <Translate expression='auth.Signup.UserAuth.ResetPassword.Title' />
    );

    body = (
      <Translate expression='auth.Signup.UserAuth.ResetPassword.Body' />
    );
  }

  const closeModel = props.closeModel;

  return (
    <Dialog
      onClose={closeModel}
    >
      <DialogTitle onClose={closeModel}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container
          justifyContent="center"
          sx={{
            margin: 'auto',
          }}
        >
          <Grid item xs={12} >
            <Typography
              variant='body1'
            >
              {body}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{pb: '16px'}}>
            <hr style={{border: '1px solid #E5E5E5'}}/>
          </Grid>
          <Grid item xs={12}>
            {display}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  );
};

AuthenticationDailog.propTypes = {
  authOverlayStatus: PropTypes.string,
  closeModel: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authOverlayStatus: state.authOverlay?.status,
});

export default connect(mapStateToProps, dispatch)(React.memo(AuthenticationDailog));
