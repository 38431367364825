import {
  getUserPool,
} from 'utils/auth/cognito';

const signOut = () => {
  const userPool = getUserPool();
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    // Sign out the user
    cognitoUser.signOut();
  } else {
    console.error('No user is currently signed in.');
  }
};

export {
  signOut,
};
