import backend, {MARKET_API} from 'utils/backend/server/api_library';

import queryString from 'query-string';

const byRequester = (requesterRn, filters, sort, lastEvaluatedKey) => {
  const queryParam = {
    requesterRn,
    lastEvaluatedKey: lastEvaluatedKey,
  };
  if (filters) {
    queryParam.filters = JSON.stringify(filters);
  }
  if (sort) {
    queryParam.sort = JSON.stringify(sort);
  }

  const query = queryString.stringify(queryParam, {skipNull: true, skipEmptyString: true});
  const url = `${MARKET_API}/offer/query/by_requester?${query}`;
  return backend.get(url);
};

const byMyGigs = (requesterRn, filters, sort, lastEvaluatedKey) => {
  const queryParam = {
    requesterRn,
    lastEvaluatedKey: lastEvaluatedKey,
  };
  if (filters) {
    queryParam.filters = JSON.stringify(filters);
  }
  if (sort) {
    queryParam.sort = JSON.stringify(sort);
  }

  const query = queryString.stringify(queryParam, {skipNull: true, skipEmptyString: true});
  const url = `${MARKET_API}/offer/query/my_contract?${query}`;
  return backend.get(url);
};

const byOpenGigs = (filters, sort, lastEvaluatedKey) => {
  const queryParam = {
    lastEvaluatedKey: lastEvaluatedKey,
  };
  if (filters) {
    queryParam.filters = JSON.stringify(filters);
  }
  if (sort) {
    queryParam.sort = JSON.stringify(sort);
  }

  const query = queryString.stringify(queryParam, {skipNull: true, skipEmptyString: true});
  const url = `${MARKET_API}/offer/query/open_work?${query}`;
  return backend.get(url);
};

export default {
  byRequester,
  byMyGigs,
  byOpenGigs,
};
