import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog as DialogMui,
  DialogActions as DialogActionsMui,
  DialogContent as DialogContentMui,
  DialogTitle as DialogTitleMui,
} from '@mui/material';
import {Box, IconButton} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function Dialog(props) {
  const [clicked, setClicked] = React.useState(false);

  const onClose = () => {
    if (!clicked && props.onClose()) {
      props.onClose();
    }
  };

  return (
    <DialogMui
      {...props}
      fullWidth={true}
      open={true}
      onClose={onClose}
      sx={{
        'root': {
          p: {md: '8px', xs: '4px'},
        },
      }}
    >
      <Box onClick={() => setClicked(true)}>
        {props.children}
      </Box>
    </DialogMui>
  );
}
Dialog.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

function DialogTitle(props) {
  const onClose = props.onClose;
  return (
    <DialogTitleMui
      fontWeight='bold'
      {...props}
    >
      {props.children}
      {
        onClose &&
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      }
    </DialogTitleMui>
  );
}
DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export {
  Dialog,
  DialogActionsMui as DialogActions,
  DialogContentMui as DialogContent,
  DialogTitle,
};
