import React from 'react';
import PropTypes from 'prop-types';

import {dispatch, connect} from 'utils/state_manager';

import {Box, Typography} from 'views/components';

import {Translate} from 'views/module';

const ViewPayment = (props) => {
  const offerOrder = props.offerOrder;

  const paymentType = offerOrder.payment?.type || '';

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
        }}
      >
        <Translate expression={`market.Order.Payment.${paymentType}.Title`} />
      </Typography>
      <Typography>
        <Translate expression={`market.Order.Payment.${paymentType}.Body`} />
      </Typography>
    </Box>
  );
};

ViewPayment.propTypes = {
  offerOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, dispatch)(React.memo(ViewPayment));
