import React from 'react';
import PropTypes from 'prop-types';

import {ErrorBoundary as Reb} from 'react-error-boundary';
import {withErrorBoundary as web} from 'react-error-boundary';

const ErrorBoundary = (props) => {
  const children = props.children;
  const FallbackComponent = props.FallbackComponent || (<div />);

  const onError = (error, info) => {
    console.error('An error has occured', error);
  };

  return (
    <Reb FallbackComponent={FallbackComponent} onError={onError} >
      {children}
    </Reb>
  );
};
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  FallbackComponent: PropTypes.any,
};
const ErrorBoundaryMemo = React.memo(ErrorBoundary);

const withErrorBoundary = (component) => {
  return web(component, {
    FallbackComponent: <div />,
    onError(error, info) {
      console.error('An error has occured', error);
    },
  });
};


export {
  ErrorBoundaryMemo as ErrorBoundary,
  withErrorBoundary,
};
